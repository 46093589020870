import {
  DOCUMENTS_REQUEST_GET,
  DOCUMENT_REQUEST_GET,
} from "src/actions/documents";

const defaultState = {
  documents: [],
  currentDocument: null,
};

const documents = (state = defaultState, action: any) => {
  switch (action.type) {
    case DOCUMENTS_REQUEST_GET:
      if (action.response && action.response.data) {
        return {
          ...state,
          documents: action.response.data || [],
        };
      }
      return state;

    case DOCUMENT_REQUEST_GET:
      if (action.response && action.response.data) {
        return {
          ...state,
          currentDocument: action.response.data,
        };
      }

      return state;

    default:
      return state;
  }
};

export default documents;
