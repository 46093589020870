import * as Yup from "yup";

import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Table, TableSkeleton } from "src/components/shared/table";
import { UserRow, UserRowProps } from "./elements/UserRow";
import { updateUser, userInviteResend, userOrgRemove } from "src/actions/user";

import { Formik } from "formik";
import { InviteUserOrgForm } from "./forms";
import { Pager } from "src/components/shared/pager";
import { RootState } from "src/reducers";
import { SearchInput } from "src/components/shared/search-input";
import baseStyles from "./AdminPortal.module.scss";
import { getOrganizationUsers } from "src/actions/organization";
import { phoneNumber } from "src/helpers/validation";
import styles from "./AdminOrganization.module.scss";
import { useAxios } from "src/hooks/useAxios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { redirect } from "src/actions/common";
import { Routes } from "src/helpers/routes";
import { IAxiosReturn } from "src/interfaces/axios";

export interface InitialUserValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  id?: string;
  accessLevel: string[];
  jobTitle: string;
  blocked: boolean;
}

export function AdminOrganization() {
  const dispatch = useDispatch();
  const [dispatchAxios, { loading, error: usersError }] = useAxios();
  const [
    dispatchUserUpdate,
    { loading: userUpdateLoading, success: userUpdateSuccess },
  ] = useAxios();
  const [dispatchUserOrgRemove] = useAxios();
  const [dispatchUserResendInvite] = useAxios();
  const { orgId } = useParams<{ orgId: string }>();
  // Sort/order/search related state.
  const [currentSort, setCurrentSort] = useState("name");
  const [currentDirection, setCurrentDirection] = useState("ASC");
  const { currentUser } = useSelector((state: RootState) => state.user);
  const {
    total,
    pages,
    currentPage,
    searchInput,
    orderBy,
    direction,
    organization,
    users,
  } = useSelector((root: RootState) => root.organizationUsers);
  // Form-related state.
  const [currentFormRow, setCurrentFormRow] = useState("");
  const [initialValues, setInitialValues] = useState<InitialUserValues>({
    firstName: "",
    lastName: "",
    jobTitle: "",
    phone: "",
    email: "",
    role: "",
    id: "",
    accessLevel: [],
    blocked: false,
  });

  const schema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    jobTitle: Yup.string().optional(),
    phone: Yup.string().matches(phoneNumber, "Phone is not valid"),
    email: Yup.string().email("Must be a valid email").required("Required"),
    role: Yup.string().required("Required"),
    //accessLevel: Yup.array().min(1, "Must select at least one access level"),
  });

  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    fetchOrganizationUsers(orgId, e.target.value, orderBy, direction, 1);
  }

  function handleSort(column: string) {
    const newDirection = direction === "ASC" ? "DESC" : "ASC";
    setCurrentSort(column);
    setCurrentDirection(newDirection);
    fetchOrganizationUsers(
      orgId,
      searchInput,
      column,
      newDirection,
      currentPage
    );
  }

  function fetchOrganizationUsers(
    id: string,
    name: string,
    order: string,
    sort: string,
    page: number
  ) {
    try {
      dispatchAxios(getOrganizationUsers(id, name, order, sort, page));
    } catch (e) {}
  }

  function handleFormOpen(user: any) {
    setCurrentFormRow(user.id);
    setInitialValues(user);
  }

  function handleUserOrgRemove(user: any) {
    dispatchUserOrgRemove(userOrgRemove(user.id, orgId));
    handleRefreshOrgUsers();
  }

  function handleUserResendInvite(user: any) {
    return dispatchUserResendInvite(userInviteResend({ id: user.id })).then(
      (status: IAxiosReturn) => {
        // TODO: Implement nicer notification.
        if (status.success) {
          window.alert("Invitation sent.");
        } else {
          window.alert("Invitation failed, please try again.");
        }
        // Return anything when we're finished.
        return true;
      }
    );
  }

  function handleSubmit(values: any) {
    // if user being updated is current user, remove role field from request params
    let updatedAccessLevelArr = [...values.accessLevel];
    if (values.accessLevel.includes("Corporate")) {
      updatedAccessLevelArr.push("Employee Clinic");
    } else {
      updatedAccessLevelArr = values.accessLevel.filter(
        (item: string) => item !== "Employee Clinic"
      );
    }

    dispatchUserUpdate(
      updateUser(values.id, {
        ...values,
        accessLevel: updatedAccessLevelArr,
        role: values.id !== currentUser.id ? values.role : undefined,
      })
    );
  }

  // Handler to pass into child components to trigger a refresh.
  function handleRefreshOrgUsers() {
    fetchOrganizationUsers(orgId, "", orderBy, direction, 1);
  }

  // Load all organization users once on render.
  useEffect(() => {
    fetchOrganizationUsers(orgId, searchInput, orderBy, direction, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load all organization users once on render.
  useEffect(() => {
    if (!userUpdateLoading && userUpdateSuccess) {
      setCurrentFormRow("");
      fetchOrganizationUsers(
        orgId,
        searchInput,
        orderBy,
        direction,
        currentPage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdateLoading, userUpdateSuccess]);

  useEffect(() => {
    if (usersError && usersError.response.status === 403) {
      dispatch(redirect(Routes.root));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersError]);

  return (
    <>
      <div className={baseStyles.root}>
        <header className={baseStyles.headerColumn}>
          <InviteUserOrgForm
            organizationName={organization.name || ""}
            phone={organization.phone || ""}
            address={organization.address || ""}
            onSuccess={handleRefreshOrgUsers}
          />
        </header>

        <div className={baseStyles.contentWrapper}>
          <div className={baseStyles.tableIntro}>
            <h2 className={baseStyles.headingSmall}>Organization Members</h2>
            <SearchInput onChange={handleSearch} />
          </div>
          {total > 0 && (
            <div
              className={baseStyles.tableWrapper + " " + styles.tableWrapper}
            >
              <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  values,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {!loading ? (
                      <Table
                        headers={[
                          {
                            name: "Employee Name",
                            direction:
                              currentSort === "name"
                                ? currentDirection
                                : "DESC",
                            active: currentSort === "name",
                            onClick: () => handleSort("name"),
                          },
                          {
                            name: "Title",
                            direction:
                              currentSort === "jobTitle"
                                ? currentDirection
                                : "DESC",
                            active: currentSort === "jobTitle",
                            onClick: () => handleSort("jobTitle"),
                          },
                          {
                            name: "Permissions",
                          },
                          {
                            name: "Phone Number",
                            direction:
                              currentSort === "phone"
                                ? currentDirection
                                : "DESC",
                            active: currentSort === "phone",
                            onClick: () => handleSort("phone"),
                          },
                          {
                            name: "Email Address",
                            direction:
                              currentSort === "email"
                                ? currentDirection
                                : "DESC",
                            active: currentSort === "email",
                            onClick: () => handleSort("email"),
                          },
                          {
                            name: "Status",
                            direction:
                              currentSort === "blocked"
                                ? currentDirection
                                : "DESC",
                            active: currentSort === "blocked",
                            onClick: () => handleSort("blocked"),
                          },
                          { name: "Edit", srOnly: true },
                        ]}
                      >
                        {users.map((user: UserRowProps, index: number) => (
                          <UserRow
                            {...user}
                            currentUser={currentUser}
                            key={`user-row-${index}`}
                            isForm={currentFormRow === user.id}
                            onClick={() => handleFormOpen(user)}
                            onRemove={() => handleUserOrgRemove(user)}
                            onResendInvite={() => handleUserResendInvite(user)}
                            errors={errors}
                            loading={userUpdateLoading}
                            handleChange={handleChange}
                            values={values}
                            touched={touched}
                            handleBlur={handleBlur}
                          />
                        ))}
                      </Table>
                    ) : (
                      <TableSkeleton />
                    )}
                  </form>
                )}
              </Formik>
              <Pager
                total={total}
                pages={pages}
                currentPage={currentPage}
                getPage={(page: number) => {
                  return (e: MouseEvent) => {
                    e.preventDefault();
                    fetchOrganizationUsers(
                      orgId,
                      searchInput,
                      orderBy,
                      direction,
                      page
                    );
                  };
                }}
              />
            </div>
          )}
          {total === 0 && (
            <h1 className={baseStyles.noResults}>
              {!loading ? "No users found." : <TableSkeleton />}
            </h1>
          )}
        </div>
      </div>
    </>
  );
}
