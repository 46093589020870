import { Button } from "src/components/shared/button";
import { IEncounter } from "src/interfaces/encounter";
import { LayoutReferralPdf } from "src/components/layouts/layout-referral-pdf/LayoutReferralPdf";
import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

interface Props extends IEncounter {
  results: any[];
}

export function Referrals(props: Props) {
  const {
    results,
    patientFirstName,
    patientLastName,
    locationAddress,
    locationCity,
    patientPhoneNumber,
    locationState,
    locationZipCode,
    locationPhoneNumber,
    patientMrn,
    patientSSN,
    patientSex,
    patientBirthDate,
  } = props;

  return (
    <div className="mb-10">
      {results?.map((result, index) => {
        const ReferralDocument = () => {
          return <LayoutReferralPdf {...props} referral={result} />;
        };

        return (
          <div
            className="grid grid-flow-row bg-white  rounded-md shadow-md mb-12 border border-gray-200"
            key={`referral-${result.ExternalReferralNumber}`}
          >
            <div className="mt-4 p-4 ">
              <div className="flex justify-between">
                <div>
                  <p>{locationAddress}</p>
                  <p>{`${locationCity}, ${locationState}`}</p>
                  <p>{locationZipCode}</p>
                  <p>Phone: {`${locationPhoneNumber}`}</p>
                </div>
                <div>
                  <p className="py-1">
                    <span className="font-bold text-gray-600">Date:&nbsp;</span>
                    {result.AuthorizationStartDate}
                  </p>
                </div>
              </div>

              <div className="grid grid-flow-row divide-y-2">
                <div>
                  <h3 className="text-xl font-semibold pt-4">
                    {result?.Procedures?.length
                      ? result?.Procedures[0].Procedure
                      : ""}
                  </h3>

                  <h3 className="text-xl font-semibold pt-4">Patient:</h3>

                  <div className="flex justify-between pb-2">
                    <div>
                      <p className="py-1">
                        <span className="font-bold text-gray-600">
                          Legal Name:&nbsp;
                        </span>
                        {`${patientFirstName} ${patientLastName}`}
                      </p>
                      <p className="py-1">
                        <span className="font-bold text-gray-600">
                          Address:&nbsp;
                        </span>
                        {`${result.PatientAddress[0].Street}, ${result.PatientAddress[0].State.Abbreviation} ${result.PatientAddress[0].Zip}`}
                      </p>
                      <p className="py-1">
                        <span className="font-bold text-gray-600">
                          Phone:&nbsp;
                        </span>
                        {patientPhoneNumber}
                      </p>
                    </div>
                    <div>
                      <p className="py-1">
                        <span className="font-bold text-gray-600">
                          MRN:&nbsp;
                        </span>
                        {patientMrn}
                      </p>
                      <p className="py-1">
                        <span className="font-bold text-gray-600">
                          DOB:&nbsp;
                        </span>
                        {moment(patientBirthDate).format("MM/DD/YYYY")}
                      </p>
                      <p className="py-1">
                        <span className="font-bold text-gray-600">
                          SSN:&nbsp;
                        </span>
                        {patientSSN}
                      </p>
                      <p className="py-1">
                        <span className="font-bold text-gray-600">
                          Sex:&nbsp;
                        </span>
                        {patientSex}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <h3 className="text-xl font-semibold">
                    Referring Provider Information
                  </h3>
                  <div className="flex justify-between">
                    <div>
                      <p className="py-1">
                        <span className="font-bold text-gray-600">
                          Name:&nbsp;
                        </span>
                        {result.ReferredByProviderName}
                      </p>
                    </div>
                    {/* <div>
                      <p className="py-1">
                        <span className="font-bold text-gray-600">
                          Phone:&nbsp;
                        </span>
                        {"%Data%"}
                      </p>
                    </div> */}
                    {/* <div>
                      <p className="py-1">
                        <span className="font-bold text-gray-600">
                          Fax:&nbsp;
                        </span>
                        {"%Data%"}
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="py-2">
                  <h3 className="text-xl font-semibold">
                    Referral Information
                  </h3>
                  <div className="flex justify-between">
                    <div>
                      {/* <div>
                        <p className="py-1">
                          <span className="font-bold text-gray-600">
                            # Visits:&nbsp;
                          </span>
                          {"%DATA%"}
                        </p>
                      </div> */}
                      {/* <div>
                        <p className="py-1">
                          <span className="font-bold text-gray-600">
                            Urgency:&nbsp;
                          </span>
                          {"%Data%"}
                        </p>
                      </div> */}
                      <div>
                        <p className="py-1">
                          <span className="font-bold text-gray-600">
                            Start Date:&nbsp;
                          </span>
                          {result.AuthorizationStartDate}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p className="py-1">
                          <span className="font-bold text-gray-600">
                            Referral Type:&nbsp;
                          </span>
                          {result.Type}
                        </p>
                      </div>
                      {/* <div>
                        <p className="py-1">
                          <span className="font-bold text-gray-600">
                            Referral Reason:&nbsp;
                          </span>
                          {"%Data%"}
                        </p>
                      </div> */}
                      <div>
                        <p className="py-1">
                          <span className="font-bold text-gray-600">
                            End Date:&nbsp;
                          </span>
                          {result.AuthorizationEndDate}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <div className="flex justify-between">
                    <div>
                      <h3 className="text-xl whitespace-normal">
                        <span className={"font-semibold"}>
                          Diagnosis:&nbsp;
                        </span>
                        {`${result.Diagnoses[0].Description} ${result.Diagnoses[0].Diagnosis}`}
                      </h3>
                      <div className="pt-4">
                        {/* <p className="py-1">
                          <span className="font-bold text-gray-600">
                            Refer to Dept:&nbsp;
                          </span>
                          {"%Data"}
                        </p> */}
                        <p className="py-1">
                          <span className="font-bold text-gray-600">
                            Refer to Provider:&nbsp;
                          </span>
                          {result.ReferredToProviderName}
                        </p>
                        <p className="py-1">
                          <span className="font-bold text-gray-600">
                            Electronically Signed By:&nbsp;
                          </span>
                          {result.ReferredByProviderName}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-end">
                      <Button
                        type="button"
                        icon="download"
                        primary
                        onClick={async () => {
                          const doc = <ReferralDocument />;
                          const asPdf = pdf(doc); // {} is important, throws without an argument
                          asPdf.updateContainer(doc);
                          const blob = await asPdf.toBlob();
                          saveAs(blob, `${result.ExternalReferralNumber}.pdf`);
                        }}
                      >
                        Download PDF
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
