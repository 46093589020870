import * as Yup from "yup";

export const passwordValidation = (
  minGroups: number = 4,
  consecutiveCharacters: number = 3
) => {
  return Yup.string()
    .required("Password is required")
    .test("length", "Must be exactly 8 or more characters", val =>
      val && val.length >= 8 ? true : false
    )
    .test("length", "Must be less than 32 characters", val =>
      val && val.length < 32 ? true : false
    )
    .test(
      "consecutive",
      `Password must not contain ${consecutiveCharacters} consecutive characters.`,
      val => {
        const regex = new RegExp("(.)\\1{" + (consecutiveCharacters - 1) + "}");
        return val && regex.test(val) ? false : true;
      }
    )
    .test(
      "complexity",
      `The password must contain ${minGroups < 4 ? minGroups + ' or more' : 'all'} of the following groups of characters: lowercase letters, uppercase letters, numbers, and/or special characters.`,
      val => {
        if (!val) {
          return false;
        }

        let groups: number = 0;
        // Lowercase.
        if (/[a-z]/.test(val)) {
          groups++;
        }
        // Uppercase.
        if (/[A-Z]/.test(val)) {
          groups++;
        }
        // Numbers.
        if (/\d+/.test(val)) {
          groups++;
        }
        // Special characters.
        if (/[^a-zA-Z0-9]/.test(val)) {
          groups++;
        }

        return groups >= minGroups;
      }
    );
};

export const passwordConfirmValidation = (fieldName: string) => {
  return Yup.string().oneOf(
    [Yup.ref(fieldName), null],
    "Passwords do not match"
  );
};
