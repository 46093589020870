import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";
import React from "react";
import styles from "./DatePickerInput.module.scss";

interface IField {
  name: string;
  value: Date | null | undefined;
}

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  field: IField;
  form: any;
  wrapperClassname: React.HTMLAttributes<HTMLDivElement>["className"];
  label: React.ReactNode;
  labelHidden: boolean;
  excludeDates?: Array<Date>;
  includeDates?: Array<Date>;
  handleDateChange: Function;
}

export function DatePickerInput(props: IProps) {
  const {
    field,
    form,
    wrapperClassname,
    label,
    labelHidden,
    includeDates,
    handleDateChange,
    id,
    disabled,
    placeholder,
  } = props;

  const { name, value } = field;
  const { setFieldValue } = form;
  const meta = form.getFieldMeta(name);

  let labelClasses = "text-sm leading-5 font-medium text-gray-700";
  if (labelHidden) {
    labelClasses += " sr-only";
  }

  let inputClass =
    "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-300";
  if (meta && meta.touched && meta.error) {
    inputClass =
      "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md disabled:bg-gray-300";
  }

  function handleOnChange(value: Date) {
    handleDateChange();
    setFieldValue(name!, value);
  }

  return (
    <div className={wrapperClassname + " " + styles.root}>
      <label htmlFor={id} className={labelClasses}>
        {label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <DatePicker
          calendarClassName={styles.calendar}
          wrapperClassName={styles.wrapper}
          dayClassName={(date: Date) => styles.day}
          className={inputClass}
          disabled={disabled}
          onChange={handleOnChange}
          selected={value}
          showDisabledMonthNavigation
          includeDates={includeDates}
          placeholderText={placeholder}
        />
      </div>
      {meta && meta.touched && meta.error && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
}

DatePickerInput.defaultProps = {
  disabled: false,
  wrapperClassname: "",
  label: "",
  labelHidden: false,
};
