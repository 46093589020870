import { IAnalytics } from "src/interfaces/analytics";
import { IconStat } from "src/components/shared/stat";
import { Link } from "react-router-dom";

interface Props {
  analytics: {
    newEmployees: IAnalytics;
  };
  loading: boolean;
}
export function EmployeeAnalytics(props: Props) {
  const { analytics, loading } = props;
  const change = analytics.newEmployees.monthChange;

  return (
    <>
      <div>
        <IconStat
          heading={"New Employees this Month"}
          stat={analytics.newEmployees.monthTotal}
          change={parseFloat(`${change}`)}
          loading={loading}
        />
      </div>
      <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6 rounded-lg rounded-t-none">
        <div className="text-sm">
          <Link
            to={"/"}
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            View all<span className="sr-only">Employee Stats</span>
          </Link>
        </div>
      </div>
    </>
  );
}
