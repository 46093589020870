import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/reducers';
import { useAxios } from 'src/hooks/useAxios';
import { getCurrentUser } from 'src/actions/user';

export function useCurrentUser(hydrate: boolean = false) {
  const [dispatchAxios, { loading }] = useAxios();
  const { currentUser } = useSelector((root: RootState) => root.user);

  useEffect(() => {
    if (hydrate && !loading) {
      dispatchAxios(getCurrentUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [currentUser, loading]
}
