import { MouseEvent, useEffect, useState } from "react";
import {
  OrganizationRow,
  OrganizationRowProps,
} from "./elements/OrganizationRow";
import { Table, TableSkeleton } from "src/components/shared/table";

import { Pager } from "src/components/shared/pager";
import { RootState } from "src/reducers";
import { SearchInput } from "src/components/shared/search-input";
import { Skeleton } from "src/components/shared/skeleton";
import { getOrganizations } from "src/actions/organization";
import styles from "./AdminPortal.module.scss";
import { useAxios } from "src/hooks/useAxios";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { useSelector } from "react-redux";

export function AdminPortal() {
  const [dispatchAxios, { loading }] = useAxios();
  // Sort/order/search state variables.
  const [currentSort, setCurrentSort] = useState("name");
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [currentUser] = useCurrentUser();
  const [currentDirection, setCurrentDirection] = useState("ASC");
  const {
    total,
    pages,
    currentPage,
    searchInput,
    orderBy,
    direction,
    organizations,
  } = useSelector((root: RootState) => root.organization);

  function handleSearch(query: string) {
    fetchOrganizations(query, orderBy, direction, 1);
  }

  // Add delay to debounce searchand prevent requests on every keystroke
  useEffect(() => {
    const timeoutId = setTimeout(() => handleSearch(currentSearchQuery), 750);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchQuery]);

  function handleSort(column: string) {
    const newDirection = direction === "ASC" ? "DESC" : "ASC";
    setCurrentSort(column);
    setCurrentDirection(newDirection);
    fetchOrganizations(searchInput, column, newDirection, 1);
  }

  function fetchOrganizations(
    name: string,
    order: string,
    sort: string,
    page: number
  ) {
    dispatchAxios(getOrganizations(name, order, sort, page));
  }

  // Load all organizations once on render.
  useEffect(() => {
    fetchOrganizations(searchInput, orderBy, direction, currentPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <PageLoader loading={loading} /> */}
      <div className={styles.root}>
        <header className={styles.header}>
          <h1 className={`${styles.headingLarge} col-span-12 lg:col-span-9`}>
            {currentUser.fullName ? (
              <>Welcome, {currentUser.fullName}</>
            ) : (
              <Skeleton />
            )}
          </h1>
        </header>
        <div className={styles.contentWrapper}>
          <div className={styles.tableIntro}>
            <h2 className={styles.headingSmall}>Organization List</h2>
            <SearchInput
              onChange={e => setCurrentSearchQuery(e.target.value)}
            />
          </div>
          <div className={styles.tableWrapper}>
            {total > 0 && !loading && (
              <div>
                <Table
                  headers={[
                    {
                      name: "Organization",
                      direction:
                        currentSort === "name" ? currentDirection : "DESC",
                      active: currentSort === "name",
                      onClick: () => handleSort("name"),
                    },
                    {
                      name: "Phone",
                      direction:
                        currentSort === "phone" ? currentDirection : "DESC",
                      active: currentSort === "phone",
                      onClick: () => handleSort("phone"),
                    },
                    {
                      name: "Address",
                      direction:
                        currentSort === "address" ? currentDirection : "DESC",
                      active: currentSort === "address",
                      onClick: () => handleSort("address"),
                    },
                    {
                      name: "Members",
                      direction:
                        currentSort === "userCount" ? currentDirection : "DESC",
                      active: currentSort === "userCount",
                      onClick: () => handleSort("userCount"),
                    },
                    { name: "Dashboard", srOnly: true },
                    { name: "Edit", srOnly: true },
                  ]}
                >
                  {organizations.map(
                    (organization: OrganizationRowProps, index: number) => (
                      <OrganizationRow
                        key={`org-row-${index}`}
                        {...organization}
                        isEven={index % 2 === 0}
                      />
                    )
                  )}
                </Table>
                <Pager
                  total={total}
                  pages={pages}
                  currentPage={currentPage}
                  getPage={(page: number) => {
                    return (e: MouseEvent) => {
                      e.preventDefault();
                      fetchOrganizations(searchInput, orderBy, direction, page);
                    };
                  }}
                />
              </div>
            )}
            {loading && <TableSkeleton />}
          </div>

          {total === 0 && searchInput && (
            <h1 className={styles.noResults}>
              {!loading ? "No organizations found." : "Searching..."}
            </h1>
          )}
        </div>
      </div>
    </>
  );
}
