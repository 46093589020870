import * as Yup from "yup";

import { ErrorMessage, Field, Formik } from "formik";

import { Breadcrumb } from "src/components/shared/breadcrumb/Breadcrumb";
import { Breadcrumbs } from "src/components/shared/breadcrumb/Breadcrumbs";
import { Button } from "src/components/shared/button";
import { ErrorMessage as CustomErrorMessage } from "src/components/shared/forms/error-message";
import { Link } from "react-router-dom";
import { PageLoader } from "src/components/shared/loader/page-loader/PageLoader";
import { Routes } from "src/helpers/routes";
import { StatusMessage } from "src/components/shared/status-message";
import { TailwindField } from "src/components/shared/forms/tailwind-field/TailwindField";
import styles from "./Profile.module.scss";
import { useAxios } from "src/hooks/useAxios";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { userPasswordReset } from "src/actions/user";
import {
  passwordValidation,
  passwordConfirmValidation,
} from "src/helpers/yupRules";

export function UpdatePassword() {
  const [currentUser] = useCurrentUser();
  const [dispatchAxios, { loading, error, success }] = useAxios();

  const schema = Yup.object().shape({
    oldPassword: Yup.string().required("Required"),
    newPassword: passwordValidation(),
    passwordConfirm: passwordConfirmValidation("newPassword"),
  });

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    passwordConfirm: "",
  };

  function handleSubmit(values: any) {
    if (values.newPassword && values.passwordConfirm) {
      dispatchAxios(
        userPasswordReset(
          `${currentUser.id}`,
          values.oldPassword,
          values.newPassword
        )
      );
    }
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs>
            <Breadcrumb>
              <Link to={Routes.profile}>Profile</Link>
            </Breadcrumb>
            <Breadcrumb>
              <Link to={Routes.updateProfile}>Update Password</Link>
            </Breadcrumb>
          </Breadcrumbs>
        </div>
        <div className={styles.header}>
          <h1 className="text-2xl leading-7 font-bold text-gray-900">
            Update Password
          </h1>
        </div>
        <div className={styles.contentWrapper}>
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validator={() => ({})}
            loading={loading}
          >
            {({ handleSubmit }) => (
              <form className={styles.root} onSubmit={handleSubmit}>
                {error &&
                  (error.response.status === 409 ? (
                    <StatusMessage type="error">
                      Incorrect Password
                    </StatusMessage>
                  ) : (
                    <StatusMessage type="error">
                      Update Password Failed
                    </StatusMessage>
                  ))}
                {success && <StatusMessage>Update Successful</StatusMessage>}
                <div className={styles.formContent}>
                  <PageLoader loading={loading} />
                  <div className={styles.fields}>
                    <div className={styles.fieldRow}>
                      <div className={styles.inputWrapper}>
                        <Field
                          type="password"
                          id="oldPassword"
                          name="oldPassword"
                          label="Current Password"
                          component={TailwindField}
                        />
                        <ErrorMessage
                          name="oldPassword"
                          component={CustomErrorMessage}
                        />
                      </div>
                    </div>
                    <div className={styles.fieldRow}>
                      <div className={styles.inputWrapper}>
                        <Field
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          label="New Password"
                          component={TailwindField}
                        />
                        <ErrorMessage
                          name="newPassword"
                          component={CustomErrorMessage}
                        />
                      </div>
                    </div>
                    <div className={styles.fieldRow}>
                      <div className={styles.inputWrapper}>
                        <Field
                          type="password"
                          id="passwordConfirm"
                          name="passwordConfirm"
                          label="Confirm Password"
                          component={TailwindField}
                        />
                        <ErrorMessage
                          name="passwordConfirm"
                          component={CustomErrorMessage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.actions}>
                  <Button type="submit" primary disabled={loading}>
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
