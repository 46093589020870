import * as Yup from "yup";

import { Breadcrumb, Breadcrumbs } from "src/components/shared/breadcrumb";
import { ErrorMessage, Field, Formik } from "formik";
import { Link, useParams } from "react-router-dom";
import { Routes, formatPath } from "src/helpers/routes";
import {
  TailwindComboBox,
  TailwindField,
  TailwindSelect,
  TailwindSelectOption,
} from "src/components/shared/forms/tailwind-field";
import { getUserAutocomplete, inviteUser, userOrgAdd } from "src/actions/user";
import { useEffect, useState } from "react";

import { Button } from "src/components/shared/button";
import { ErrorMessage as CustomErrorMessage } from "src/components/shared/forms/error-message";
import { ReactComponent as EmailIcon } from "./images/email.svg";
import { InitialUserValues } from "../AdminOrganization";
import { RootState } from "src/reducers";
import { Skeleton } from "src/components/shared/skeleton";
import { StatusMessage } from "src/components/shared/status-message";
import styles from "./InviteUserForm.module.scss";
import { useAxios } from "src/hooks/useAxios";
import { useSelector } from "react-redux";

interface InviteUserOrgProps {
  organizationName: string;
  phone: string;
  address: string;
  onSuccess?: () => void;
}

export function InviteUserOrgForm({
  organizationName,
  phone,
  address,
  onSuccess,
}: InviteUserOrgProps) {
  const { orgId } = useParams<{ orgId: string }>();
  const [isOpen, setOpen] = useState(false);
  const [activeForm, setActiveForm] = useState("invite");
  const [dispatchInvite, { loading, success, error }] = useAxios();
  const [selectsState, setSelectValue] = useState({
    role: "",
  });

  const { users }: { users: TailwindSelectOption[] } = useSelector(
    (root: RootState) => root.autocomplete
  );
  const [dispatchGetUsers, { loading: loadingUsers }] = useAxios();

  const phoneRegExp = /^$|^[^_]+$/;
  // Schema changes based on whether we're inviting a new user or adding
  // and existing user.
  let schema;
  if (activeForm === "add-existing") {
    schema = Yup.object().shape({
      userId: Yup.string().required("Required"),
    });
  } else {
    schema = Yup.object().shape({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      jobTitle: Yup.string().optional(),
      phone: Yup.string().matches(phoneRegExp, "Phone Number is not Valid"),
      email: Yup.string().email("Must be a valid email").required("Required"),
      role: Yup.string().required("Required"),
      //accessLevel: Yup.array().min(1, "Must select at least one access level"),
    });
  }

  interface CombinedInitialValues extends InitialUserValues {
    userId?: string;
  }

  const initialValues: CombinedInitialValues = {
    userId: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    phone: "",
    email: "",
    role: "",
    accessLevel: [],
    blocked: false,
  };

  function handleToggle(type: string) {
    return () => {
      setActiveForm(type);
      setOpen(!isOpen);
    };
  }

  function handleSubmit(values: any) {
    if (activeForm === "add-existing") {
      dispatchInvite(userOrgAdd(values.userId, orgId));
    } else {
      let updatedAccessLevelArr = [...values.accessLevel];
      if (values.accessLevel.includes("Corporate")) {
        updatedAccessLevelArr.push("Employee Clinic");
      }
      values.organization = { id: orgId };
      dispatchInvite(
        inviteUser({ ...values, accessLevel: updatedAccessLevelArr })
      );
    }
  }

  function handleSelect(id: any, selected: any) {
    setSelectValue(prevState => ({
      ...prevState,
      [id]: selected.value,
    }));
  }

  function handleCancel(resetForm: Function) {
    resetForm();
    setOpen(!isOpen);
    for (const [key] of Object.entries(selectsState)) {
      setSelectValue(prevState => ({
        ...prevState,
        [key]: "",
      }));
    }
  }

  function handleUserAutocomplete(name: string) {
    dispatchGetUsers(getUserAutocomplete(name));
  }

  // Hide form on success.
  useEffect(() => {
    if (success) {
      setOpen(false);
      // Run handler passed in from parent component.
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, setOpen]);

  const rootClass = [styles.root];
  if (isOpen) {
    rootClass.push(styles.isOpen);
  }

  return (
    <>
      <div className={rootClass.join(" ")}>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            setFieldValue,
            handleBlur,
            handleChange,
            resetForm,
            values,
            errors,
          }) => (
            <form onSubmit={handleSubmit}>
              {organizationName && (
                <div className={styles.breadcrumbs}>
                  <Breadcrumbs>
                    <Breadcrumb>
                      <Link to={Routes.adminPortal}>Organizations</Link>
                    </Breadcrumb>
                    <Breadcrumb>
                      <Link to={formatPath(Routes.adminOrg, { orgId })}>
                        {organizationName}
                      </Link>
                    </Breadcrumb>
                  </Breadcrumbs>
                </div>
              )}
              {error && (
                <StatusMessage type="error">
                  Invitiation failed: {error.response.data.message}
                </StatusMessage>
              )}
              {success &&
                (activeForm === "invite" ? (
                  <StatusMessage>Invitiation sent</StatusMessage>
                ) : (
                  <StatusMessage>
                    User added
                  </StatusMessage>
                ))}
              <div
                className={`${
                  styles.formContent
                } flex items-start justify-between ${
                  isOpen ? "flex-col" : "flex-row"
                }`}
              >
                <div>
                  <h1
                    className={`${styles.headingLarge} col-span-8 lg:col-span-8 `}
                  >
                    {organizationName ? organizationName : <Skeleton />}
                  </h1>
                  <div>
                    {address ? (
                      <p className="text-lg capitalize font-light">
                        {address.toLowerCase()}
                      </p>
                    ) : null}

                    {phone ? (
                      <p className="text-lg font-light">{phone}</p>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`${styles.fields} col-span-12 grid grid-cols-3 lg:grid-cols-3 gap-6 grid-row `}
                >
                  {activeForm === "invite" ? (
                    <>
                      <div className={styles.inputWrapper}>
                        <Field
                          type="text"
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          placeholder="John"
                          component={TailwindField}
                        />
                        <ErrorMessage
                          name="firstName"
                          component={CustomErrorMessage}
                        />
                      </div>
                      <div className={styles.inputWrapper}>
                        <Field
                          type="text"
                          id="lastName"
                          name="lastName"
                          label="Last Name"
                          placeholder="Doe"
                          component={TailwindField}
                        />
                        <ErrorMessage
                          name="lastName"
                          component={CustomErrorMessage}
                        />
                      </div>
                      <div className={styles.inputWrapper}>
                        <Field
                          type="text"
                          id="jobTitle"
                          name="jobTitle"
                          label="Title"
                          placeholder="Job Title"
                          component={TailwindField}
                        />
                        <ErrorMessage
                          name="jobTitle"
                          component={CustomErrorMessage}
                        />
                      </div>
                      <div className={styles.inputWrapper}>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="example@gmail.com"
                          component={TailwindField}
                        />
                        {!errors.email ? (
                          <div className={styles.inputIcon}>
                            <EmailIcon />
                          </div>
                        ) : (
                          ""
                        )}
                        <ErrorMessage
                          name="email"
                          component={CustomErrorMessage}
                        />
                      </div>
                      <div className={styles.inputWrapper}>
                        <Field
                          type="text"
                          id="phone"
                          name="phone"
                          label="Phone Number (optional)"
                          mask="(999) 999-9999"
                          value={values.phone}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          component={TailwindField}
                          setFieldValue={setFieldValue}
                        />
                        <ErrorMessage
                          name="phone"
                          component={CustomErrorMessage}
                        />
                      </div>
                      <div>
                        <div>
                          <div className={styles.inputWrapper}>
                            <Field
                              as="select"
                              id="role"
                              name="role"
                              label="Permissions"
                              component={TailwindSelect}
                              placeholder="Select Role"
                              options={[
                                { value: "ROLE_VIEWER", label: "Viewer" },
                                { value: "ROLE_USER", label: "User" },
                                { value: "ROLE_ADMIN", label: "Admin" },
                              ]}
                              handleSelect={handleSelect}
                              setValue={setFieldValue}
                              value={selectsState.role}
                            />
                            <ErrorMessage
                              name="role"
                              component={CustomErrorMessage}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={styles.inputWrapper}>
                      <Field
                        id="userId"
                        name="userId"
                        label="User Name"
                        loadingMessage={
                          loadingUsers ? "Loading users..." : "No users found"
                        }
                        placeholder="Please select a user"
                        component={TailwindComboBox}
                        options={users}
                        loading={loadingUsers}
                        handleSelect={handleSelect}
                        setValue={setFieldValue}
                        resetForm={resetForm}
                        initialValues={initialValues}
                        values={values}
                        handleResetOnChange={() => resetForm()}
                        value={values.userId}
                        onRequestOptions={handleUserAutocomplete}
                      />
                      <ErrorMessage
                        name="userId"
                        component={CustomErrorMessage}
                      />
                    </div>
                  )}
                </div>

                <div
                  className={`${
                    styles.actions
                  } col-span-12 lg:col-span-4 lg:ml-auto  ${
                    isOpen ? "lg:-ml-0  mt-" : ""
                  }`}
                >
                  {!isOpen ? (
                    <>
                      <Button
                        type="button"
                        icon="user"
                        primary
                        onClick={handleToggle("invite")}
                      >
                        Invite User to Organization
                      </Button>
                      <Button
                        type="button"
                        icon="user"
                        primary
                        onClick={handleToggle("add-existing")}
                        className="ml-5"
                      >
                        Add Existing User
                      </Button>
                    </>
                  ) : (
                    <div className="mt-4 flex">
                      <div className="mr-3">
                        <Button
                          type="button"
                          onClick={() => handleCancel(resetForm)}
                        >
                          Cancel
                        </Button>
                      </div>

                      <Button
                        type="submit"
                        icon="user"
                        primary
                        disabled={loading}
                      >
                        {activeForm === "invite" ? "Invite User" : "Add User"}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}
