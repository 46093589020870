import * as Yup from "yup";

import { BaseForm, styles } from "./BaseForm";
import { ErrorMessage, Field } from "formik";
import { Link, useParams } from "react-router-dom";
import {
  passwordConfirmValidation,
  passwordValidation,
} from "src/helpers/yupRules";
import { useEffect, useState } from "react";
import { userInviteCheck, userInvitePasswordReset } from "src/actions/user";

import { Button } from "src/components/shared/button";
import { ErrorMessage as CustomErrorMessage } from "src/components/shared/forms/error-message";
import { PageLoader } from "src/components/shared/loader/page-loader";
import { Routes } from "src/helpers/routes";
import { StatusMessage } from "src/components/shared/status-message";
import { useAxios } from "src/hooks/useAxios";
import { useQuery } from "src/hooks/useQuery";

export function InvitePasswordForm() {
  const { inviteId } = useParams<{ inviteId: string }>();
  const query = useQuery();
  const [dispatchAxios, { loading, success }] = useAxios();
  const [dispacthInviteCheck, { loading: checkLoading, error: checkError }] =
    useAxios();
  const [inviteError, setError] = useState<any>();
  const [check, setCheck] = useState(true);

  const schema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email").required("Required"),
    password: passwordValidation(),
    passwordConfirm: passwordConfirmValidation("password"),
  });

  const email = query.get("email") ?? "";
  const initialValues = {
    email,
    password: "",
    passwordConfirm: "",
  };

  useEffect(() => {
    dispacthInviteCheck(userInviteCheck(inviteId));
    setCheck(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!inviteError && checkError) {
      switch (checkError.response.status) {
        case 404:
          setError("Sorry, This invite was not found");
          break;
        case 498:
          setError("Sorry, This invite is invalid");
          break;
        default:
          setError(
            "Something went wrong, please contact the user who invited you"
          );
          break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkError]);

  function handleSubmit(values: any) {
    if (values.password && values.passwordConfirm) {
      dispatchAxios(
        userInvitePasswordReset(
          inviteId,
          email,
          values.password,
          values.passwordConfirm
        )
      );
    }
  }

  const title = success ? "Password Created" : "Create Password";
  const buttonText = success ? "" : "Confirm Password";

  return (
    <>
      {!inviteError ? (
        <>
          {checkLoading ? (
            <div className="bg-white w-full h-full relative">
              <PageLoader loading={checkLoading} whiteBg={true} />
            </div>
          ) : (
            <>
              {!check && (
                <BaseForm
                  schema={schema}
                  initialValues={initialValues}
                  handleSubmit={handleSubmit}
                  loading={loading}
                  disableEmail={true}
                  title={title}
                  heading="Create Password"
                  description="Please enter a new password. Your password must follow all criteria."
                  buttonText={buttonText}
                >
                  {success ? (
                    <>
                      <StatusMessage>
                        Successfully changed password
                      </StatusMessage>
                      <Link to={Routes.signIn}>
                        <Button primary fullWidth>
                          Back to Login
                        </Button>
                      </Link>
                    </>
                  ) : (
                    <>
                      <div className={styles.inputWrapper}>
                        <label htmlFor="code">New Password:</label>
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                        />
                        <ErrorMessage
                          name="password"
                          className="relative bottom-0"
                          component={CustomErrorMessage}
                        />
                      </div>

                      <div className={`${styles.inputWrapper} mb-5`}>
                        <label htmlFor="code">Confirm Password:</label>
                        <Field
                          id="passwordConfirm"
                          name="passwordConfirm"
                          type="password"
                          placeholder="Re-enter Password"
                        />
                        <ErrorMessage
                          name="passwordConfirm"
                          className="relative bottom-0"
                          component={CustomErrorMessage}
                        />
                      </div>
                    </>
                  )}
                </BaseForm>
              )}
            </>
          )}
        </>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <h1 className={styles.heading}>{inviteError}</h1>
        </div>
      )}
    </>
  );
}
