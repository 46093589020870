export enum Routes {
  root = "/",
  // Signin routes.
  signIn = "/signin",
  signUp = "/signup",
  resetPassword = "/reset-password",
  adminSignIn = "/signin/admin",
  otp = "/otp",
  otpPassword = "/otp/password",
  inviteAccept = "/invite/:inviteId",
  // Authenticated pages.
  appointments = "/appointments",
  apptCreate = "/appointments/create",
  apptCreateEmployee = "/appointments/create-employee",
  profile = "/profile",
  updateProfile = "/profile/update-profile",
  updatePassword = "/profile/update-password",
  // Admin routes.
  adminPortal = "/admin",
  adminOrg = "/admin/organization/:orgId",
}

export const formatPath = (path: string, params: any = {}) => {
  for (let param in params) {
    if (params.hasOwnProperty(param)) {
      path = path.replace(`:${param}`, params[param]);
    }
  }
  return path;
};
