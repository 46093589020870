import { TableCell, TableRow } from "src/components/shared/table";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  DotsVerticalIcon,
  ExclamationIcon,
  XIcon,
} from "@heroicons/react/outline";

import { IEmployee } from "src/interfaces/employee";
import moment from "moment";
import { useAxios } from "src/hooks/useAxios";
import { cancelAppointment } from "src/actions/appointments";

export interface AppointmentRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  isEven: boolean;
  visitType: string;
  location: string;
  date: string;
  time: string;
  status: string;
  employee: IEmployee;
  id: string;
  viewingUpcoming: boolean;
}
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function AppointmentRow(props: AppointmentRowProps) {
  const {
    isEven,
    visitType,
    location,
    date,
    time,
    employee,
    status,
    id,
    viewingUpcoming,
  } = props;
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [dispatchCancel, { success, error, loading }] = useAxios();
  const [cancelComment, setCancelComment] = useState("");
  const [appointmentCanceled, setAppointmentCanceled] = useState(false);
  const cancelButtonRef = useRef(null);

  const { firstName, lastName } = employee;

  const handleCancel = () => {
    dispatchCancel(cancelAppointment(id, cancelComment));
  };

  useEffect(() => {
    if (success) {
      setOpen(false);
      setAppointmentCanceled(true);
    }
    if (error) {
      setOpen(false);
      setTimeout(() => {
        setErrorOpen(true);
      }, 500);
    }
  }, [success, error]);

  return (
    <>
      <TableRow isEven={isEven}>
        <TableCell className="pl-4">
          {firstName} {lastName}
        </TableCell>
        <TableCell>{visitType}</TableCell>
        <TableCell className="overflow-ellipsis">{location}</TableCell>
        <TableCell>
          {moment(date).format("MM-DD-YYYY")} {time}
        </TableCell>
        <TableCell className="relative overflow-visible flex items-center h-full  justify-start px-0 py-4">
          {viewingUpcoming && (
            <>
              <span
                className={` inline-flex items-end justify-end px-1.5 py-1.5 rounded-full text-xs font-medium mr-2 ${
                  status === "active" && !appointmentCanceled
                    ? "bg-green-400"
                    : "bg-red-400"
                }`}
              />
              <Menu as="div" className="relative inline-block text-left">
                {status === "active" && !appointmentCanceled && (
                  <div>
                    <Menu.Button className=" rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                )}

                <Transition
                  as={"div"}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <div className="py-1">
                      <Menu.Item as="div">
                        {({ active }) => {
                          return status === "active" && !appointmentCanceled ? (
                            <button
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-red-700"
                                  : "text-red-500",
                                "block px-4 py-2 text-sm w-full text-left"
                              )}
                              onClick={() => setOpen(true)}
                            >
                              Cancel Appointment
                            </button>
                          ) : null;
                        }}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </>
          )}
        </TableCell>
      </TableRow>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Cancel Appointment?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 mb-5">
                        Are you sure you would like to cancel this appointment?
                        This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="comment"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Cancellation Comments
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows={4}
                      name="comment"
                      id="comment"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      value={cancelComment}
                      onChange={(e) => setCancelComment(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => handleCancel()}
                  >
                    <span className={`${loading ? "animate-pulse" : ""}`}>
                      {" "}
                      {!loading ? "Cancel Appointment" : "Cancelling..."}
                    </span>
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={errorOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <XIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Something Went Wrong.
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Please try again.</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                    onClick={() => setErrorOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

AppointmentRow.defaultProps = {
  isEven: false,
};
