import { Link } from "react-router-dom";

import { Routes } from "src/helpers/routes";
import { Breadcrumb, Breadcrumbs } from "src/components/shared/breadcrumb";
import { CreateEmployeeForm } from "./forms/CreateEmployeeForm";
import styles from "./Appointments.module.scss";

export function CreateNewEmployee() {
  return (
    <div className={styles.root}>
      <div className={styles.breadcrumbs}>
        <Breadcrumbs>
          <Breadcrumb>
            <Link to={Routes.appointments}>Appointments</Link>
          </Breadcrumb>
          <Breadcrumb>
            <Link to={Routes.apptCreateEmployee}>Create New Employee</Link>
          </Breadcrumb>
        </Breadcrumbs>
      </div>
      <div className={styles.header}>
        <h1 className="text-2xl leading-7 font-bold text-gray-900">
          Create New Employee
        </h1>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.intro}></div>
        <CreateEmployeeForm />
      </div>
    </div>
  );
}
