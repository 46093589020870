import React from 'react';
import { BreadcrumbProps } from './Breadcrumb';

export interface BreadcrumbsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement<BreadcrumbProps>[];
}

export function Breadcrumbs({ children }: BreadcrumbsProps) {
  const filteredChildren = children.map((child, index) => {
    let isFirst:boolean = false;
    if (index === 0) {
      isFirst = true;
    }

    return React.cloneElement(child, {
      key: `breadcrumb-${index}`,
      isFirst
    });
  });

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {filteredChildren}
      </ol>
    </nav>
  );
}
