import { ChangeEvent } from "react";
import styles from "./EncounterDatePicker.module.scss";

export interface EncounterDatePickerProps {
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

export function EncounterDatePicker({ onChange }: EncounterDatePickerProps) {
  return (
    <div className={`sm:col-span-2 ${styles.root}`}>
      <input
        onChange={onChange}
        type="date"
        className={`max-w-lg ${styles.encDatePicker} block enc-date-picker shadow-sm text-black focus:ring-blue-500 focus:border-blue-500 sm:max-w-sm sm:text-sm border-gray-300 rounded-md`}
      />
    </div>
  );
}
