import { Loader, LoaderColor } from "../loader/button-loader";

import { ReactComponent as ConfigureIcon } from "./images/configure.svg";
import { ReactComponent as DownloadIcon } from "./images/download.svg";
import { ReactComponent as UserIcon } from "./images/user.svg";
import styles from "./Button.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  primary?: boolean;
  secondary?: boolean;
  warning?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
}

enum Icons {
  configure = "configure",
  user = "user",
  download = "download",
}

export function Button(props: Props) {
  const {
    primary,
    secondary,
    warning,
    fullWidth,
    loading,
    icon,
    children,
    className,
    ...rest
  } = props;
  let classes: Array<string> = [];

  if (className) {
    classes = className.split(" ");
  }

  classes.push(styles.root);

  if (primary) {
    classes.push(styles.primary);
  } else if (secondary) {
    classes.push(styles.secondary);
  } else if (warning) {
    classes.push(
      "py-1 px-2 ml-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
    );
  }

  if (fullWidth) {
    classes.push(styles.fullWidth);
  }

  // TODO: Should probably turn this into an iconfont.
  let iconComponent;
  switch (icon) {
    case Icons.configure:
      iconComponent = (
        <div className={styles.icon}>
          <ConfigureIcon />
        </div>
      );
      break;

    case Icons.user:
      iconComponent = (
        <div className={styles.icon}>
          <UserIcon />
        </div>
      );
      break;
    case Icons.download:
      iconComponent = (
        <div className={styles.icon}>
          <DownloadIcon />
        </div>
      );
      break;
  }

  return (
    <button className={classes.join(" ")} {...rest}>
      {loading ? (
        <Loader color={LoaderColor.white} />
      ) : (
        <>
          {iconComponent}
          {children}
        </>
      )}
    </button>
  );
}

Button.defaultProps = {
  icon: "",
  fullWidth: false,
};
