import { IAxiosAction, IAxiosReturn, IAxiosState } from "src/interfaces/axios";
import { authStore, getClient } from "src/authentication";
// @todo: Implement some kind of caching.
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { redirect } from "src/actions/common";
import { useDispatch } from "react-redux";

export type DispatchAxios = (params: IAxiosAction) => Promise<IAxiosReturn>;

export function useAxios(): [DispatchAxios, IAxiosState] {
  const dispatch = useDispatch();
  const authState = useContext(authStore);
  const isMounted = useRef(true);
  const axios = getClient(authState);

  const [state, setState] = useState<IAxiosState>({
    loading: false,
    error: null,
    success: false,
    data: null,
  });

  const dispatchAxios: DispatchAxios = useCallback(
    (params: IAxiosAction) => {
      const action: IAxiosAction = {
        type: params.type,
        pathParameters: params.pathParameters,
        config: params.config,
      };

      if (params.data) {
        action.data = params.data;
      }

      dispatch({
        ...action,
        loading: true,
      });

      setState({
        ...state,
        error: null,
        loading: true,
        success: false,
      });

      return axios(params.config)
        .then(response => {
          // dispatch must come before setState
          dispatch({
            ...action,
            response,
            loading: false,
          });
          if (isMounted.current) {
            setState({
              ...state,
              loading: false,
              success: true,
              error: null,
              data: response.data,
            });

            if (params.redirectOnSuccess) {
              dispatch(
                redirect(
                  params.redirectOnSuccess,
                  params.preserveQuery || false
                )
              );
            }
          }

          return {
            success: true,
            error: null,
            response,
          };
        })
        .catch(error => {
          // dispatch must come before setState
          dispatch({
            ...action,
            error,
            loading: false,
            success: false,
          });

          if (isMounted.current) {
            setState({
              ...state,
              loading: false,
              success: false,
              error,
            });

            if (params.redirectOnFailed) {
              dispatch(redirect(params.redirectOnFailed));
            }
          }

          return {
            success: false,
            response: null,
            error,
          };
        });
    },
    [isMounted, dispatch, state, axios]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  return [dispatchAxios, state];
}
