import * as Yup from "yup";

import { Field, Formik } from "formik";
import {
  TailwindField,
  TailwindSelect,
} from "src/components/shared/forms/tailwind-field";
import { phoneNumber, ssn, validateDate } from "src/helpers/validation";

import { Button } from "src/components/shared/button";
import { PageLoader } from "src/components/shared/loader/page-loader";
import { Routes } from "src/helpers/routes";
import { StatusMessage } from "src/components/shared/status-message";
import { createEmployee } from "src/actions/employees";
import { redirect } from "src/actions/common";
import styles from "./AppointmentsForm.module.scss";
import { useAxios } from "src/hooks/useAxios";
import { useDepartments } from "src/hooks/useDepartments";
import { useDispatch } from "react-redux";
import { useState } from "react";

export function CreateEmployeeForm() {
  const dispatch = useDispatch();
  const [departments, { loading: loadingDepartments }] = useDepartments(true);
  const [dispatchAxios, { loading, error }] = useAxios();
  const errorMessage =
    error && error.response && error.response.data
      ? error.response.data.message
      : null;

  const [selectsState, setSelectValue] = useState({
    departmentId: "",
    gender: "",
    country: "",
  });

  function handleSelect(id: any, selected: any) {
    setSelectValue((prevState) => ({
      ...prevState,
      [id]: selected.value,
    }));
  }

  const schema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Must be a valid email").required("Required"),
    departmentId: Yup.number().required("Required"),
    country: Yup.string().required("Required"),
    street: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    zip: Yup.string().length(5).required("Required"),
    ssn: Yup.string()
      .matches(ssn, "Social Security Number is not valid")
      .required("Required"),
    phone: Yup.string()
      .matches(phoneNumber, "Phone is not valid")
      .required("Required"),
    dob: Yup.string()
      .test("dob", "Date is invalid", validateDate)
      .required("Required"),
    gender: Yup.string().required("Required"),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    departmentId: "",
    country: "US",
    street: "",
    city: "",
    state: "",
    zip: "",
    ssn: "",
    phone: "",
    dob: "",
    gender: "",
  };

  function handleCancel() {
    dispatch(redirect(Routes.appointments));
  }

  function handleSubmit(values: any) {
    dispatchAxios(createEmployee(values));
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, handleChange, handleBlur, values }) => (
        <form className={styles.root} onSubmit={handleSubmit}>
          <div className={`${styles.formContent} grid grid-cols-12`}>
            <PageLoader loading={loading} />

            <div className={`${styles.description} col-span-12 lg:col-span-4`}>
              <div className="text-lg text-gray-900 leading-6 font-medium">
                Employee Information
              </div>
              <div className="text-sm text-gray-500 leading-5 pr-4">
                Use a permanent address where you can receive mail.
              </div>
            </div>
            <div
              className={`${styles.fields} grid grid-cols-2 col-span-12 gap-y-8 lg:gap-8 lg:col-span-8`}
            >
              <div className="col-span-2">
                {errorMessage && (
                  <StatusMessage type="error">
                    Employee could not be created: {errorMessage}
                  </StatusMessage>
                )}
              </div>
              <div className="col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-8 ">
                <Field
                  type="text"
                  id="firstName"
                  name="firstName"
                  label="First name"
                  component={TailwindField}
                />
                <Field
                  type="text"
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  component={TailwindField}
                />
              </div>
              <div className="col-span-2 grid grid-cols-1 gap-8 ">
                <Field
                  type="email"
                  id="email"
                  name="email"
                  label="Email address"
                  component={TailwindField}
                />
              </div>
              <div className="col-span-2 grid grid-cols-1 gap-8 ">
                <Field
                  id="departmentId"
                  name="departmentId"
                  label="Location"
                  component={TailwindSelect}
                  loadingMessage={
                    loadingDepartments
                      ? "Loading locations..."
                      : "No locations found"
                  }
                  placeholder="Please select a location"
                  handleSelect={handleSelect}
                  setValue={setFieldValue}
                  value={selectsState.departmentId}
                  options={departments}
                />
              </div>
              <div className="col-span-2 grid grid-cols-1 gap-8">
                <Field
                  component={TailwindSelect}
                  id="country"
                  name="country"
                  label="Country / Region"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md shadow-sm"
                  placeholder="Select"
                  handleSelect={handleSelect}
                  setValue={setFieldValue}
                  value={selectsState.country}
                  options={[{ value: "US", label: "United States of America" }]}
                />
              </div>
              <div className="col-span-2 grid grid-cols-1 gap-8 ">
                <Field
                  type="text"
                  id="street"
                  name="street"
                  label="Street address"
                  component={TailwindField}
                />
              </div>
              <div className="col-span-2 grid grid-cols-1 lg:grid-cols-3 gap-8 ">
                <Field
                  type="text"
                  id="city"
                  name="city"
                  label="City"
                  component={TailwindField}
                />
                <Field
                  type="text"
                  id="state"
                  name="state"
                  label="State / Province"
                  component={TailwindField}
                />
                <Field
                  type="text"
                  id="zip"
                  name="zip"
                  label="Zip / Postal"
                  component={TailwindField}
                  mask="99999"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.zip}
                />
              </div>
              <div className="col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-8">
                <Field
                  mask="999-99-9999"
                  type="text"
                  id="ssn"
                  name="ssn"
                  label="Social Security Number"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  component={TailwindField}
                  setFieldValue={setFieldValue}
                  value={values.ssn}
                />
                <Field
                  mask="(999) 999-9999"
                  type="text"
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  component={TailwindField}
                  setFieldValue={setFieldValue}
                  value={values.phone}
                />
              </div>
              <div className="col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-8">
                <Field
                  mask="99/99/9999"
                  type="text"
                  id="dob"
                  name="dob"
                  label="Date of Birth"
                  maskPlaceholder="mm/dd/yyyy"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  component={TailwindField}
                  setFieldValue={setFieldValue}
                  value={values.dob}
                />

                <Field
                  component={TailwindSelect}
                  id="gender"
                  name="gender"
                  label="Gender"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md shadow-sm"
                  placeholder="Select"
                  handleChange={handleChange}
                  handleSelect={handleSelect}
                  setValue={setFieldValue}
                  value={selectsState.gender}
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className={styles.actions}>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" primary disabled={loading}>
              Create Employee
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
