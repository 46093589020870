import { MouseEvent, useEffect, useState } from "react";

import { Drawer } from "src/components/shared/drawer";
import { IEncounter } from "src/interfaces/encounter";
import { Result } from "./Result";
import { RootState } from "src/reducers";
import { getEncounterResult } from "src/actions/encounters";
import { useAxios } from "src/hooks/useAxios";
import { useSelector } from "react-redux";

interface Props extends IEncounter {
  handleClose(e: MouseEvent): any;
  id: number;
  title: string;
  link: string;
  resource: string;
}

export function ResultDrawer(props: Props) {
  const [open, setOpen] = useState(false);

  const { id, title, handleClose } = props;

  const [dispatchAxios, { loading }] = useAxios();
  const { encounterResult } = useSelector(
    (root: RootState) => root.encounterResults
  );

  useEffect(() => {
    dispatchAxios(getEncounterResult(`${id}`));
    setTimeout(() => {
      setOpen(true);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      heading={`${title}`}
      handleClose={handleClose}
      isOpen={open}
      isPrimary={false}
    >
      {loading && <p>loading result...</p>}
      {!loading && !encounterResult && (
        <p>Result could not be loaded, please try again later.</p>
      )}
      {!loading && encounterResult && (
        <Result {...props} heading={title} result={encounterResult} />
      )}
    </Drawer>
  );
}
