import { ChangeEvent } from "react";
import styles from "./SearchInput.module.scss";

export interface SearchInputProps {
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

export function SearchInput({ onChange }: SearchInputProps) {
  return (
    <div className={`sm:col-span-2 ${styles.root}`}>
      <input
        onChange={onChange}
        type="text"
        name="username"
        placeholder="Search"
        className="max-w-lg block w-full shadow-sm text-black focus:ring-blue-500 focus:border-blue-500 sm:max-w-sm sm:text-sm border-gray-300 rounded-md"
      />
      <svg
        className={`h-5 w-5 ${styles.searchIcon}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
}
