import { IAxiosAction } from "src/interfaces/axios";

export const DOCUMENTS_REQUEST_GET = "documents/get";
export const DOCUMENT_REQUEST_GET = "document/get";

const API_URL = process.env.REACT_APP_API_URL || "";

/**
 * Get documents for an encounter
 * @param encounterId
 * @returns
 */
export function getDocuments(encounterId: number): IAxiosAction {
  return {
    type: DOCUMENTS_REQUEST_GET,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/documents/encounter/${encounterId}`,
    },
  };
}

/**
 * Get the document (returns a PDF)
 * @param encounterId
 * @param documentId
 * @returns
 */
export function getDocument(
  encounterId: number,
  documentId: number
): IAxiosAction {
  return {
    type: DOCUMENT_REQUEST_GET,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/documents/encounter/${encounterId}/${documentId}`,
      responseType: 'blob',
    },
  };
}
