import * as Yup from "yup";

import { BaseForm, styles } from "./BaseForm";
import { ErrorMessage, Field } from "formik";
import { Redirect } from "react-router-dom";
import { useContext } from "react";
import { authStore, login } from "src/authentication";

import { ErrorMessage as CustomErrorMessage } from "src/components/shared/forms/error-message";
import { Routes } from "src/helpers/routes";
import { TailwindField } from "src/components/shared/forms/tailwind-field";
import { useState } from "react";

export function AdminSignInForm() {
  const authState = useContext(authStore);
  const schema = Yup.object().shape({
    opid: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const initialValues = {
    opid: "",
    password: "",
  };

  const [error, setError] = useState<string | true | undefined>(undefined);

  const handleSubmit = async (values: any) => {
    setError(undefined);
    const response = await login(authState, values.opid, values.password, true);

    const errorType = response.status;
    let errorMsg = "";
    if (errorType === 500) {
      errorMsg = "Invalid OPID / Password Combination";
      setError(errorMsg);
    } else if (errorType === 400) {
      errorMsg = "Invalid OPID / Password Combination";
      setError(errorMsg);
    }
    return;
  };

  // Redirect after login or if already logged in.
  if (authState.state.session.access_token) {
    return <Redirect to={Routes.root} />;
  }

  return (
    <BaseForm
      schema={schema}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      buttonText="Login"
      title="Administrator Sign In"
      isAdminLogin
    >
      <div className={`${styles.inputWrapper} pt-4`}>
        <Field
          label="Password"
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          component={TailwindField}
        />
        <ErrorMessage name="password" component={CustomErrorMessage} />
      </div>

      <div
        className={`${styles.actions} grid grid-cols-1 pt-3 lg:grid-cols-2 gap-4`}
      ></div>

      <div
        className={
          styles.root +
          ` ${
            error
              ? "text-red-700 h-21 md:h-16 transition-all ease-in-out duration-1000 opacity-100"
              : "h-0 transition-all ease-in-out duration-1000 opacity-0"
          }`
        }
        role="alert"
      >
        <strong className="font-bold">{error}</strong>
      </div>
    </BaseForm>
  );
}
