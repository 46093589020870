import {
  APPOINTMENTS_REQUEST_GET_ALL,
  APPOINTMENTS_REQUEST_GET_ANALYTICS,
  APPOINTMENTS_REQUEST_GET_AVAILABLE,
} from "src/actions/appointments";

const defaultState = {
  total: 0,
  pages: 0,
  currentPage: 1,
  searchInput: "",
  orderBy: "name",
  direction: "ASC",
  pastOnly: 0,
  appointments: [],
  availableDates: [],
  availableTimes: [],
  analytics: {},
};

const appointments = (state = defaultState, action: any) => {
  switch (action.type) {
    case APPOINTMENTS_REQUEST_GET_AVAILABLE:
      if (action.response && action.response.data) {
        return {
          ...state,
          availableDates: Object.keys(action.response.data),
          availableTimes: action.response.data,
        };
      }

      return state;

    case APPOINTMENTS_REQUEST_GET_ALL:
      if (action.response) {
        const getAllResponse = action.response.data;
        return {
          ...state,
          total: getAllResponse.total,
          pages: getAllResponse.pages,
          currentPage: getAllResponse.currentPage,
          searchInput: getAllResponse.searchInput,
          orderBy: getAllResponse.orderBy,
          direction: getAllResponse.direction,
          pastOnly: getAllResponse.pastOnly,
          appointments: getAllResponse.result,
        };
      }

      return state;

    case APPOINTMENTS_REQUEST_GET_ANALYTICS:
      return {
        ...state,
        analytics: action.response ? action.response.data : {},
      };

    default:
      return state;
  }
};

export default appointments;
