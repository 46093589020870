import { IAxiosAction } from 'src/interfaces/axios';

const API_URL = process.env.REACT_APP_API_URL || '';

export const REFERRALS_REQUEST_GET = 'referrals/get';

export function getReferrals(patientId: string): IAxiosAction {
  return {
    type: REFERRALS_REQUEST_GET,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/NON-FHIR/PatientReferrals/${patientId}`,
    },
  };
}
