import {
  AdminOrganization,
  AdminPortal,
} from "src/components/pages/admin-portal";
import {
  Appointments,
  CreateAppointment,
  CreateNewEmployee,
} from "src/components/pages/appointments";
import {
  InvitePasswordForm,
  OtpPasswordForm,
  ResetPasswordForm,
  SignInForm,
} from "src/components/pages/signin";
import { Profile, UpdateProfile } from "src/components/pages/profile";
import { Switch, matchPath, useHistory } from "react-router-dom";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dashboard } from "src/components/pages/dashboard";
import { LayoutDashboard } from "src/components/layouts/layout-dashboard";
import { LayoutSignIn } from "src/components/layouts/layout-sign-in";
import { RootState } from "src/reducers";
import { Routes } from "src/helpers/routes";
import { UpdatePassword } from "./components/pages/profile/UpdatePassword";
import { authStore } from "src/authentication";
import { redirected } from "src/actions/common";
import { AdminSignInForm } from "./components/pages/signin/AdminSignInForm";

export function Router() {
  const history = useHistory();
  const authState = useContext(authStore);
  const dispatch = useDispatch();
  const commonState = useSelector((root: RootState) => root.common);

  // @todo: Move this to custom hook.
  useEffect(() => {
    if (typeof authState.state.session.access_token === "undefined") {
      const publicRoutes: Array<string> = [
        Routes.signIn,
        Routes.resetPassword,
        Routes.otp,
        Routes.otpPassword,
        Routes.inviteAccept,
        Routes.adminSignIn,
      ];

      const pathname = window.location.pathname;
      const hasMatch = publicRoutes.filter((path: string) => {
        const matchedPath = matchPath(pathname, { path });
        return matchedPath ? 1 : 0;
      });

      if (!hasMatch.length) {
        history.push(Routes.signIn);
      }
    }
  }, [authState.state.session, history]);

  // Handle redirects.
  // @todo: Move this to custom hook.
  useEffect(() => {
    if (commonState.redirectTo) {
      history.push(commonState.redirectTo);
      dispatch(redirected());
    }
  }, [commonState.redirectTo, dispatch, history]);

  return (
    <Switch>
      <LayoutDashboard exact path={Routes.root} component={Dashboard} />
      <LayoutDashboard
        exact
        path={Routes.appointments}
        component={Appointments}
      />
      <LayoutDashboard
        exact
        path={Routes.apptCreate}
        component={CreateAppointment}
      />
      <LayoutDashboard
        exact
        path={Routes.apptCreateEmployee}
        component={CreateNewEmployee}
      />
      <LayoutDashboard
        exact
        path={Routes.adminPortal}
        component={AdminPortal}
      />

      <LayoutDashboard
        exact
        path={Routes.adminOrg}
        component={AdminOrganization}
      />
      <LayoutDashboard exact path={Routes.profile} component={Profile} />
      <LayoutDashboard
        exact
        path={Routes.updateProfile}
        component={UpdateProfile}
      />
      <LayoutDashboard
        exact
        path={Routes.updatePassword}
        component={UpdatePassword}
      />
      <LayoutSignIn exact path={Routes.signIn} component={SignInForm} />
      <LayoutSignIn
        exact
        path={Routes.resetPassword}
        component={ResetPasswordForm}
        image="two"
      />
      <LayoutSignIn
        exact
        path={Routes.adminSignIn}
        component={AdminSignInForm}
      />
      <LayoutSignIn
        exact
        path={Routes.otpPassword}
        component={OtpPasswordForm}
        image="four"
      />
      <LayoutSignIn
        exact
        path={Routes.inviteAccept}
        component={InvitePasswordForm}
        image="five"
      />
    </Switch>
  );
}
