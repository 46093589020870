import {
  ORGANIZATION_REQUEST_GET_USERS
} from 'src/actions/organization';

const defaultState = {
  total: 0,
  pages: 0,
  currentPage: 0,
  searchInput: '',
  orderBy: 'name',
  direction: 'ASC',
  // @todo: Add user and org interfaces.
  organization: [],
  users: [],
};

const organizationUsers = (state = defaultState, action: any) => {
  switch (action.type) {
    case ORGANIZATION_REQUEST_GET_USERS:
      if (action.response) {
        const getOrgUsersResponse = action.response.data;
        return {
          ...state,
          total: getOrgUsersResponse.total,
          pages: getOrgUsersResponse.pages,
          currentPage: getOrgUsersResponse.currentPage,
          searchInput: getOrgUsersResponse.searchInput,
          orderBy: getOrgUsersResponse.orderBy,
          direction: getOrgUsersResponse.direction,
          organization: getOrgUsersResponse.organization,
          users: getOrgUsersResponse.result
        }
      }
      return state;

    default:
      return state;
  }
};

export default organizationUsers;
