import React from "react";
import styles from "./Loader.module.scss";

export enum LoaderColor {
  white = "white",
}

interface IInterface extends React.HTMLAttributes<HTMLDivElement> {
  color?: LoaderColor;
}

export function Loader(props: IInterface) {
  const { color, ...rest } = props;
  const classes = [styles.root];
  if (color && styles[color]) {
    classes.push(styles[color]);
  }

  return (
    <div {...rest} className={classes.join(" ")}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
