import React from "react";
import { TileProps } from "./Tile";
import styles from "./Tiles.module.scss";

export interface TilesProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement<TileProps>[];
}

export function Tiles({ children }: TilesProps) {
  return (
    <div className={`${styles.root} grid grid-cols-1 lg:grid-cols-3 gap-6`}>
      {children}
    </div>
  );
}
