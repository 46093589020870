import { IAxiosAction } from "src/interfaces/axios";
import { validateUuid } from "src/helpers/validation";

const API_URL = process.env.REACT_APP_API_URL || "";

export const ORGANIZATION_REQUEST_GET = "organization/get";
export const ORGANIZATION_REQUEST_GET_ALL = "organization/get";
export const ORGANIZATION_REQUEST_GET_USERS = "organization/getUsers";
export const ORGANIZATION_REQUEST_AUTOCOMPLETE = "organization/autocomplete";
export const ORGANIZATION_REQUEST_ANALYTICS = "organization/analytics";

export function getOrganizations(
  name: string,
  orderBy: string,
  direction: string,
  page: number
): IAxiosAction {
  return {
    type: ORGANIZATION_REQUEST_GET,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/organization`,
      params: {
        name,
        orderBy,
        direction,
        page,
      },
    },
  };
}

export function getOrganizationUsers(
  orgId: string,
  name: string,
  orderBy: string,
  direction: string,
  page: number
): IAxiosAction {
  if (!validateUuid(orgId)) {
    throw new Error('Organization ID must be a valid uuid.');
  }

  return {
    type: ORGANIZATION_REQUEST_GET_USERS,
    pathParameters: {
      orgId,
    },
    config: {
      method: "GET",
      url: `${API_URL}/organization/${orgId}/users`,
      params: {
        name,
        orderBy,
        direction,
        page,
      },
    },
  };
}

export function getOrgAutocomplete(name: string): IAxiosAction {
  return {
    type: ORGANIZATION_REQUEST_AUTOCOMPLETE,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/organizations/autocomplete`,
      params: {
        name,
      },
    },
  };
}

export function getAllOrganizations(): IAxiosAction {
  return {
    type: ORGANIZATION_REQUEST_GET_ALL,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/organization`,
    },
  };
}

export function getOrganizationAnalytics(orgId: string): IAxiosAction {
  return {
    type: ORGANIZATION_REQUEST_ANALYTICS,
    pathParameters: {
      orgId,
    },
    config: {
      method: "GET",
      url: `${API_URL}/organization/${orgId}/analytics`,
      params: {},
    },
  };
}
