import {
  USER_REFRESHING,
  USER_REQUEST_GET,
  USER_REQUEST_GET_CURRENT,
  USER_REQUEST_INVITE,
  USER_REQUEST_OTP,
  USER_REQUEST_OTP_PASSWORD_RESET,
  USER_REQUEST_OTP_VALIDATE,
  USER_REQUEST_SET_ACTIVE_ORG,
} from "src/actions/user";

const defaultState = {
  isRefreshing: false,
  currentUser: {},
  recoverInfo: {
    uid: "",
    timestamp: "",
    token: "",
    password: "",
    passwordConfirm: "",
    passwordWasReset: false,
  },
};

const user = (state = defaultState, action: any) => {
  switch (action.type) {
    case USER_REQUEST_GET:
    case USER_REQUEST_GET_CURRENT:
    case USER_REQUEST_SET_ACTIVE_ORG:
      return {
        ...state,
        isRefreshing: false,
        currentUser: action.response ? action.response.data : state.currentUser,
      };

    case USER_REFRESHING:
      return {
        ...state,
        isRefreshing: action.payload || false,
      };

    case USER_REQUEST_INVITE:
      return state;

    case USER_REQUEST_OTP:
      return state;

    case USER_REQUEST_OTP_VALIDATE:
      if (!action.response) {
        return state;
      }

      // If action has a response then the request was successful.
      return {
        ...state,
        recoverInfo: {
          ...state.recoverInfo,
          email: action.config.data.email,
          token: action.config.data.token,
        },
      };

    case USER_REQUEST_OTP_PASSWORD_RESET:
      return {
        ...state,
        recoverInfo: {
          ...state.recoverInfo,
          passwordWasReset: action.response ? true : false,
        },
      };

    default:
      return state;
  }
};

export default user;
