import { IAxiosAction } from "src/interfaces/axios";

const API_URL = process.env.REACT_APP_API_URL || "";

export const ORDERS_REQUEST_GET = "orders/get";

export function getOrders(encounterId: string): IAxiosAction {
  return {
    type: ORDERS_REQUEST_GET,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/FHIR/R4/ServiceRequestSearch/${encounterId}`,
    },
  };
}
