import { Field, Formik } from "formik";
import { MouseEvent, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  TailwindSelect,
  TailwindSelectOption,
} from "src/components/shared/forms/tailwind-field";
import { authStore, logout } from "src/authentication";

import { Link, useHistory } from "react-router-dom";
import { Routes } from "src/helpers/routes";
import styles from "./UserHeader.module.scss";
import { useAxios } from "src/hooks/useAxios";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { userSetActiveOrg, userSetRefreshing } from "src/actions/user";
import { ArrowLeftIcon } from "@heroicons/react/outline";

export function UserHeader() {
  const [isOpen, setOpen] = useState(false);
  const authState = useContext(authStore);
  const [currentUser] = useCurrentUser(true);
  const [dispatchAxios] = useAxios();
  const [organizations, setOrganizations] = useState([]);
  const loadingText = "Loading Organizations...";
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentOrgType, setCurrentOrgType] = useState<string>("");

  const initialValues = {
    organizationSelect: "No Organizations",
  };

  const [selectsState, setSelectValue] = useState({
    organization: { value: "", label: loadingText },
  });

  const initials = [];
  if (currentUser.firstName) {
    initials.push(currentUser.firstName.charAt(0));
  }

  if (currentUser.lastName) {
    initials.push(currentUser.lastName.charAt(0));
  }

  let menuStateClasses =
    "invisible transition-transform overflow-hidden transform opacity-0 scale-0";
  if (isOpen) {
    menuStateClasses =
      "transform transition-transform overflow-hidden scale-100";
  }

  let organizationOptions: TailwindSelectOption[] = [];
  if (!organizations.length) {
    organizationOptions = [{ value: "", label: loadingText }];
  } else {
    organizationOptions = organizations.map(
      ({ id, name }: { id: string; name: string }) => ({
        value: id,
        label: name,
      })
    );
  }

  function handleLogout(e: MouseEvent) {
    e.preventDefault();
    logout(authState);
  }

  function handleSelect(id: any, selected: any) {
    dispatchAxios(userSetActiveOrg(currentUser.id, selected.value));
    dispatch(userSetRefreshing(true));
    setSelectValue(prevState => ({
      ...prevState,
      organization: selected,
    }));
    if (
      history.location.pathname !== Routes.root &&
      !history.location.pathname.startsWith(Routes.adminPortal)
    ) {
      history.push(Routes.root);
    }
  }
  useEffect(() => {
    if (currentUser.organizations) {
      setOrganizations(currentUser.organizations);
    }
    if (currentUser.activeOrg) {
      dispatch(userSetRefreshing(false));
      setSelectValue(prevState => ({
        ...prevState,
        organization: {
          value: currentUser.activeOrg.id,
          label: currentUser.activeOrg.name,
        },
      }));
    }
  }, [currentUser, setOrganizations, setSelectValue, dispatch]);

  return (
    <div className={styles.root}>
      {currentUser.role !== "ROLE_SUPER_ADMIN" ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          {({ setFieldValue, values }) => {
            return (
              <form className={styles.organizationSelect}>
                <Field
                  id="organization"
                  name="organization"
                  loadingMessage="Loading organizations..."
                  component={TailwindSelect}
                  options={organizationOptions}
                  handleSelect={handleSelect}
                  setValue={setFieldValue}
                  value={selectsState.organization}
                />
                <div>
                  {currentOrgType && organizations.length ? (
                    <div className="ml-5">
                      <p className="text-lg font-bold">
                        {currentOrgType} Account
                      </p>
                      <p className="text-red-500">
                        *Please be sure to select the right account
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      ) : (
        <>
          {currentUser?.activeOrg?.name ? (
            <div className="flex items-center">
              <Link
                className="pr-4"
                to={
                  history.location.pathname === Routes.adminPortal
                    ? Routes.root
                    : Routes.adminPortal
                }
              >
                <ArrowLeftIcon className="w-4 h-4" />
              </Link>
              {currentUser.activeOrg?.name}
            </div>
          ) : (
            <>&nbsp;</>
          )}
        </>
      )}
      <div className="ml-3 relative z-10 flex items-center">
        <div>
          <button
            onClick={() => setOpen(!isOpen)}
            type="button"
            className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            id="user-menu"
            aria-expanded={isOpen}
            aria-haspopup="true"
          >
            <span className="sr-only">Open user menu</span>
            <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
              <span className="text-sm font-medium leading-none text-white">
                {initials.join("")}
              </span>
            </span>
          </button>
          <button
            onClick={() => setOpen(!isOpen)}
            type="button"
            className={`fixed top-0 left-0 right-0 bottom-0 opacity-0 w-full h-full cursor-default ${menuStateClasses}`}
          ></button>
          <div
            className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${menuStateClasses}`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
            tabIndex={-1}
          >
            <Link
              onClick={() => setOpen(!isOpen)}
              to={Routes.profile}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-48 text-left"
              role="menuitem"
            >
              Your Profile
            </Link>
            <button
              onClick={handleLogout}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-48 text-left"
              role="menuitem"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
