import { IAnalytics } from "src/interfaces/analytics";
import { Stat } from "src/components/shared/stat";

interface Props {
  analytics: {
    drugScreens: IAnalytics;
    newAppointments: IAnalytics;
    workersComp: IAnalytics;
  };
  loading: boolean;
}

export function AppointmentAnalytics(props: Props) {
  const { analytics, loading } = props;
  return (
    <div className="divide-y divide-gray-200">
      <Stat
        heading={"New Appointments"}
        stat={analytics.newAppointments.monthTotal}
        smallText={"this month"}
        change={analytics.newAppointments.monthChange}
        loading={loading}
      />
      <Stat
        heading={"Worker's Comp"}
        stat={analytics.workersComp.monthTotal}
        smallText={"this month"}
        change={analytics.workersComp.monthChange}
        loading={loading}
      />
      <Stat
        heading={"Drug Screens"}
        stat={analytics.drugScreens.monthTotal}
        smallText={"this month"}
        change={analytics.drugScreens.monthChange}
        loading={loading}
      />
    </div>
  );
}
