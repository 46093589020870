import { Field, FormikErrors, FormikTouched } from "formik";
import { TableCell, TableRow } from "src/components/shared/table";

import { IUser } from "src/interfaces/user";
import { TailwindField } from "src/components/shared/forms/tailwind-field";
import { Button } from "src/components/shared/button";
import { useState } from "react";

export interface UserRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  isEven: boolean;
  isForm: boolean;
  id: string;
  fullName: string;
  email: string;
  phone: string;
  touched: FormikTouched<IUser>;
  jobTitle: string;
  roles: string[];
  blocked?: boolean;
  currentUser: IUser;
  loading: boolean;
  errors: FormikErrors<IUser>;
  handleBlur: (event: React.SyntheticEvent) => void;
  values: any;
  handleChange: (event: React.SyntheticEvent) => void;
  accessLevel: string[];
  onClick(): any;
  onRemove(): any;
  onResendInvite(): any;
}

export function UserRow({
  isEven,
  isForm,
  id,
  fullName,
  jobTitle,
  email,
  phone,
  roles,
  blocked,
  onClick,
  onRemove,
  onResendInvite,
  handleChange,
  accessLevel,
  values,
  errors,
  touched,
  handleBlur,
  loading,
  currentUser,
}: UserRowProps) {
  const isCurrentUser = currentUser.id === id;
  const [resendLoading, setResendLoading] = useState(false);

  if (!isForm) {
    return (
      <TableRow isEven={isEven}>
        <TableCell className="px-6 py-4">
          <div>{fullName}</div>
        </TableCell>
        <TableCell className="px-6 py-4">
          <div>{jobTitle}</div>
        </TableCell>
        <TableCell className="px-6 py-4">
          <div className="relative">
            {/* If roles array is empty, user is super admin */}
            {roles.length ? roles : "Super Admin"}
            <div className="text-xs font-light w-full whitespace-normal">
              {accessLevel?.map(level =>
                level !== "Employee Clinic" ? (
                  <span key={level}>
                    <>
                      {`${level}`}
                      <br />
                    </>
                  </span>
                ) : null
              )}
            </div>
          </div>
        </TableCell>
        <TableCell className="px-6 py-4">{phone}</TableCell>
        <TableCell className="px-6 py-4">
          <div>{email}</div>
        </TableCell>
        <TableCell className="px-6 py-4 text-right">
          {blocked ? "Pending" : "Accepted"}
        </TableCell>
        <TableCell className="px-6 py-4">
          <div>
            <Button primary className="py-1 px-2" onClick={onClick}>
              Edit
            </Button>
            {!isCurrentUser && (
              <Button
                warning
                onClick={() => {
                  if (window && window.confirm("Are you sure?")) {
                    onRemove();
                  }
                }}
              >
                Remove
              </Button>
            )}
          </div>
          {!isCurrentUser && blocked && (
            <div>
              <Button
                primary
                className="py-1 px-2 mt-2 bg-green-500 rounded-md text-white hover:bg-green-600"
                disabled={resendLoading}
                onClick={() => {
                  setResendLoading(true);
                  onResendInvite().then(() => setResendLoading(false));
                }}
              >
                {resendLoading ? "Resending..." : "Resend Invite"}
              </Button>
            </div>
          )}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow isEven={isEven}>
      <TableCell>
        <Field
          type="text"
          id="firstName"
          name="firstName"
          component={TailwindField}
        />
        <div className="pr-3">
          <Field
            type="text"
            id="lastName"
            name="lastName"
            component={TailwindField}
          />
        </div>
      </TableCell>
      <TableCell>
        <Field
          type="text"
          id="jobTitle"
          name="jobTitle"
          component={TailwindField}
        />
      </TableCell>
      <TableCell>
        <Field
          as="select"
          id="role"
          name="role"
          disabled={isCurrentUser}
          className="disabled:bg-gray-300 disabled:text-gray-500 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        >
          <option value="">Set Permissions</option>
          <option value="ROLE_VIEWER">Viewer</option>
          <option value="ROLE_USER">User</option>
          <option value="ROLE_ADMIN">Admin</option>
        </Field>
      </TableCell>
      <TableCell>
        <div>
          <Field
            type="text"
            id="phone"
            name="phone"
            component={TailwindField}
            mask="(999) 999-9999"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.phone}
          />
          <p className="text-center text-xs mt-1">(optional)</p>
        </div>
      </TableCell>
      <TableCell>
        <Field type="email" id="email" name="email" component={TailwindField} />
      </TableCell>
      <TableCell>&nbsp;</TableCell>
      <TableCell>
        <div className=" grid items-center">
          <button
            className=" bg-green-500 flex justify-center items-center rounded-md text-white px-1 py-2 hover:bg-green-600"
            type="submit"
          >
            {loading ? (
              <>
                <div className="animate-pulse">Saving...</div>
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </TableCell>
    </TableRow>
  );
}

UserRow.defaultProps = {
  isEven: false,
};
