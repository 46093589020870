import { default as OldSkeleton } from "react-skeleton-loader";
import styles from "./Skeleton.module.scss";

export function Skeleton() {
  return (
    <div>
      <OldSkeleton animated={true} color={styles.blue} />
    </div>
  );
}
