import * as Yup from "yup";

import { Field, Formik } from "formik";

import { Breadcrumb } from "src/components/shared/breadcrumb/Breadcrumb";
import { Breadcrumbs } from "src/components/shared/breadcrumb/Breadcrumbs";
import { Button } from "src/components/shared/button";
import { Link } from "react-router-dom";
import { PageLoader } from "src/components/shared/loader/page-loader/PageLoader";
import { Routes } from "src/helpers/routes";
import { Skeleton } from "src/components/shared/skeleton";
import { StatusMessage } from "src/components/shared/status-message";
import { TailwindField } from "src/components/shared/forms/tailwind-field/TailwindField";
import styles from "./Profile.module.scss";
import { useAxios } from "src/hooks/useAxios";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { userUpdate } from "src/actions/user";

export function UpdateProfile() {
  const [currentUser] = useCurrentUser();
  const [dispatchAxios, { error, success, loading }] = useAxios();

  const phoneRegExp = /^$|^[^_]+$/;

  const schema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phone: Yup.string().matches(phoneRegExp, "Phone Number is not Valid"),
    jobTitle: Yup.string().optional(),
    encounterSubscrSetting: Yup.string().optional(), // TODO: must be "all", "daily", or "none"
  });

  const initialValues = {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    phone: currentUser.phone,
    jobTitle: currentUser.jobTitle,
    encounterSubscrSetting: currentUser.encounterSubscrSetting,
  };

  function handleSubmit(values: any) {
    dispatchAxios(
      userUpdate(
        `${currentUser.id}`,
        values.firstName,
        values.lastName,
        values.phone,
        values.jobTitle,
        values.encounterSubscrSetting
      )
    );
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs>
            <Breadcrumb>
              <Link to={Routes.profile}>Profile</Link>
            </Breadcrumb>
            <Breadcrumb>
              <Link to={Routes.updateProfile}>Update Profile</Link>
            </Breadcrumb>
          </Breadcrumbs>
        </div>
        <div className={styles.header}>
          <h1 className="text-2xl leading-7 font-bold text-gray-900">
            Update Profile
          </h1>
        </div>
        <div className={styles.contentWrapper}>
          <Formik
            enableReinitialize
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, handleBlur, handleChange, values, errors }) => (
              <form className={styles.root} onSubmit={handleSubmit}>
                {error && (
                  <StatusMessage type="error">
                    Update Profile Failed
                  </StatusMessage>
                )}
                {success && <StatusMessage>Update Successful</StatusMessage>}
                <div className={styles.formContent}>
                  <PageLoader loading={loading} />
                  <div className={styles.fields}>
                    <div className={styles.fieldRow}>
                      <div className={styles.inputWrapper}>
                        {currentUser.fullName ? (
                          <Field
                            type="text"
                            id="firstName"
                            name="firstName"
                            label="First Name"
                            component={TailwindField}
                          />
                        ) : (
                          <Skeleton />
                        )}
                      </div>
                    </div>
                    <div className={styles.fieldRow}>
                      <div className={styles.inputWrapper}>
                        {currentUser.fullName ? (
                          <Field
                            type="text"
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            component={TailwindField}
                          />
                        ) : (
                          <Skeleton />
                        )}
                      </div>
                    </div>
                    <div className={styles.fieldRow}>
                      <div className={styles.inputWrapper}></div>
                      {currentUser.fullName ? (
                        <Field
                          type="text"
                          id="phone"
                          name="phone"
                          label="Phone (optional)"
                          component={TailwindField}
                          mask="(999) 999-9999"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.phone}
                        />
                      ) : (
                        <Skeleton />
                      )}
                    </div>
                    <div className={styles.fieldRow}>
                      <div className={styles.inputWrapper}>
                        {currentUser.fullName ? (
                          <Field
                            type="text"
                            id="jobTitle"
                            name="jobTitle"
                            label="Job Title"
                            component={TailwindField}
                          />
                        ) : (
                          <Skeleton />
                        )}
                      </div>
                    </div>


                    <div className={`${styles.fieldRow} ${styles.rblHorizontal}`}>
                      Encounter Subscription
                      <div className={styles.inputWrapper}>
                        <label htmlFor="encounterSubscrSetting-all">
                          <Field
                            id="encounterSubscrSetting-all"
                            type="radio"
                            name="encounterSubscrSetting"
                            value="all"
                            checked={values.encounterSubscrSetting === 'all'}
                          />
                          All
                        </label>
                        <label htmlFor="encounterSubscrSetting-none">
                          <Field
                            id="encounterSubscrSetting-none"
                            type="radio"
                            name="encounterSubscrSetting"
                            value="none"
                            checked={values.encounterSubscrSetting === 'none'}
                          />
                          None
                        </label>
                      </div>
                    </div>

                  </div>
                </div>

                <div className={styles.actions}>
                  <Button type="submit" primary>
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
