export interface TabProps extends React.HTMLAttributes<HTMLDivElement> {
  children: string;
  active: boolean;
  onClick(): any;
}

export function Tab({ children, active, onClick }: TabProps) {
  let classNames =
    "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";

  if (active) {
    classNames =
      "border-blue-500 text-blue-600 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  }

  return (
    <button onClick={onClick} className={classNames}>
      {children}
    </button>
  );
}

Tab.defaultProps = {
  active: false,
};
