export interface TableRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  isEven: boolean;
  children: React.ReactNode[] | React.ReactNode;
  onClick?(): any;
}

export function TableRow({ isEven, children, onClick }: TableRowProps) {
  let baseClass = isEven ? "bg-white" : "bg-gray-50";

  if (typeof onClick !== "undefined") {
    baseClass += " cursor-pointer";
  }

  return (
    <tr className={baseClass} onClick={onClick}>
      {children}
    </tr>
  );
}

TableRow.defaultProps = {
  isEven: false,
};
