import { Skeleton } from "../../shared/skeleton";
import { Table } from "./Table";
import { TableCell } from "./TableCell";
import { TableRow } from "./TableRow";

export interface TableSkeletonProps {
  rows: number;
  cells: number;
}

export function TableSkeleton({ rows, cells }: TableSkeletonProps) {
  const tableRows: Array<any> = [];

  for (let i = 0; i < rows; i++) {
    const tableCells: Array<any> = [];

    for (let j = 0; j < cells; j++) {
      tableCells.push(
        <TableCell key={`table-cell-${i}-${j}`} className="p-5">
          <Skeleton />
        </TableCell>
      );
    }

    tableRows.push(
      <TableRow key={`table-row-${i}`} isEven={i % 2 === 0}>
        {tableCells}
      </TableRow>
    );
  }

  return (
    <div className="pb-8">
      <Table>{tableRows}</Table>
    </div>
  );
}

TableSkeleton.defaultProps = {
  rows: 5,
  cells: 5,
};
