import { CalendarIcon, HomeIcon, KeyIcon } from "@heroicons/react/outline";

import { Link } from "react-router-dom";
import { Logo } from "src/components/shared/logo";
import { Routes } from "src/helpers/routes";
import styles from "./SidebarMenu.module.scss";
import { useCurrentUser } from "src/hooks/useCurrentUser";

export function SidebarMenu() {
  const [currentUser] = useCurrentUser(true);
  const role = currentUser.role;

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <Link to={Routes.root}>
          <Logo primary={false} />
        </Link>
      </div>
      <ul className={styles.menu}>
        <>
          <li className={`${styles.menuItem} ${styles.menuItemDashboard}`}>
            <Link to={Routes.root}>
              <HomeIcon className="h-5 w-5" />
              Dashboard
            </Link>
          </li>
          <li className={`${styles.menuItem} ${styles.menuItemDashboard}`}>
            <Link to={Routes.appointments}>
              <CalendarIcon className="h-5 w-5" />
              Appointments
            </Link>
          </li>
        </>
        {(role === "ROLE_ADMIN" || role === "ROLE_SUPER_ADMIN") && (
          <li className={`${styles.menuItem} ${styles.menuItemDashboard}`}>
            <Link to={Routes.adminPortal}>
              <KeyIcon className="h-5 w-5" />
              Admin Portal
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
}
