import { EMPLOYEES_REQUEST_GET } from "src/actions/employees";

const defaultState = {
  results: {},
};

const employees = (state = defaultState, action: any) => {
  switch (action.type) {
    case EMPLOYEES_REQUEST_GET:
      if (action.response) {
        return {
          ...state,
          results: action.response.data,
        };
      }
      return state;

    default:
      return state;
  }
};

export default employees;
