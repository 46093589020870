import {
  REFERRALS_REQUEST_GET
} from 'src/actions/referrals';

const defaultState = {
  referrals: []
};

const referralResults = (state = defaultState, action: any) => {
  switch (action.type) {
    case REFERRALS_REQUEST_GET:
      if (action.response && action.response.data) {
        return {
          ...state,
          referrals: action.response.data.Referrals || []
        }
      }
      return state;

    default:
      return state;
  }
};

export default referralResults;
