import { Routes } from 'src/helpers/routes';
import { IAxiosAction } from 'src/interfaces/axios';
import { IEmployee } from 'src/interfaces/employee';

const API_URL = process.env.REACT_APP_API_URL || '';

export const EMPLOYEES_REQUEST_GET = 'employees/get';
export const EMPLOYEES_REQUEST_CREATE = 'employees/create';

export function getEmployees(): IAxiosAction {
  return {
    type: EMPLOYEES_REQUEST_GET,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/employee`
    },
  };
}

export function createEmployee(employee: IEmployee): IAxiosAction {
  return {
    type: EMPLOYEES_REQUEST_CREATE,
    pathParameters: {},
    redirectOnSuccess: Routes.apptCreate,
    config: {
      method: 'POST',
      url: `${API_URL}/employee`,
      data: {
        ...employee,
      },
    },
  };
}
