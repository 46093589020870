import { ORDERS_REQUEST_GET } from "src/actions/orders";

const defaultState = {
  orders: [],
};

const orderResults = (state = defaultState, action: any) => {
  switch (action.type) {
    case ORDERS_REQUEST_GET:
      if (action.response && action.response.data) {
        return {
          ...state,
          orders: action.response.data || [],
        };
      }
      return state;

    default:
      return state;
  }
};

export default orderResults;
