import { MouseEvent, useEffect, useState } from "react";

import { Drawer } from "src/components/shared/drawer";
import { IEncounter } from "src/interfaces/encounter";
import { Orders } from "./Orders";
import { RootState } from "src/reducers";
import { getOrders } from "src/actions/orders";
import { useAxios } from "src/hooks/useAxios";
import { useSelector } from "react-redux";

export interface OrderProps extends IEncounter {
  title: string;
  handleClose(e: MouseEvent): any;
  encounterFhirId: string;
}

export function OrdersDrawer(props: OrderProps) {
  const [open, setOpen] = useState(false);
  const { title, handleClose, encounterFhirId } = props;

  const [dispatchAxios, { loading }] = useAxios();
  const { orders } = useSelector((root: RootState) => root.orderResults);

  useEffect(() => {
    dispatchAxios(getOrders(encounterFhirId));
    setTimeout(() => {
      setOpen(true);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      heading={`${title}`}
      handleClose={handleClose}
      isOpen={open}
      isPrimary={false}
      className={"bg-gray-50"}
    >
      {loading && <p>loading orders...</p>}
      {!loading && (!orders || !orders.length) && <p>No orders found.</p>}
      {!loading && orders && orders.length > 0 && (
        <Orders {...props} orders={orders} />
      )}
    </Drawer>
  );
}
