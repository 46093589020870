import { ErrorMessage, Field, Formik } from "formik";

import { Button } from "src/components/shared/button";
import { ErrorMessage as CustomErrorMessage } from "src/components/shared/forms/error-message";
import React from "react";
import { TailwindField } from "src/components/shared/forms/tailwind-field";
import styles from "./BaseForm.module.scss";

interface Props {
  title: string;
  heading: string;
  description: string;
  buttonText: string;
  loading: boolean;
  disableEmail: boolean;
  initialValues: object;
  schema: object;
  handleSubmit(values: any): any;
  children: React.ReactElement<any>[] | React.ReactElement<any>;
  isAdminLogin?: boolean;
}

export { styles };
export function BaseForm(props: Props) {
  const {
    title,
    heading,
    description,
    buttonText,
    loading,
    disableEmail,
    initialValues,
    schema,
    handleSubmit,
    children,
    isAdminLogin = false,
  } = props;

  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>{title}</h1>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-1">
              <div className={styles.inputWrapper}>
                <Field
                  id={isAdminLogin ? "opid" : "email"}
                  name={isAdminLogin ? "opid" : "email"}
                  type={isAdminLogin ? "text" : "email"}
                  label={
                    isAdminLogin
                      ? "OPID"
                      : "Email address associated with your account"
                  }
                  disabled={disableEmail}
                  placeholder={isAdminLogin ? "OPID" : `you@example.com`}
                  component={TailwindField}
                />
                <ErrorMessage name="email" component={CustomErrorMessage} />
              </div>
              <div className={`${styles.recoverInfo} grid grid-cols-1 gap-6`}>
                {heading && (
                  <div className={styles.recoverHeading}>{heading}</div>
                )}
                {description && (
                  <p className={styles.recoverText}>{description}</p>
                )}
                {children}
              </div>

              {buttonText && (
                <Button type="submit" primary fullWidth disabled={loading}>
                  {buttonText}
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

BaseForm.defaultProps = {
  title: "",
  heading: "",
  description: "",
  loading: false,
  disableEmail: false,
  buttonText: "Submit",
};
