import { ReactComponent as LogoBlue } from './images/logo-blue.svg';
import { ReactComponent as LogoWhite } from './images/logo-white.svg';

interface Props extends React.SVGAttributes<SVGElement> {
  primary?: boolean;
}

export function Logo(props: Props) {
  const { primary, ...rest } = props;

  return (
    <>
      {primary ? <LogoBlue {...rest} /> : <LogoWhite {...rest} />}
    </>
  );
}

Logo.defaultProps = {
  primary: true
}
