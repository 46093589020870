import { Link } from "react-router-dom";

import { Routes } from "src/helpers/routes";
import { Logo } from "src/components/shared/logo";
import styles from "./Header.module.scss";

export function Header() {
  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <Link to={Routes.signIn}>
          <Logo />
        </Link>
      </div>
    </div>
  );
}
