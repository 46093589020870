import { IAxiosAction } from 'src/interfaces/axios';

const API_URL = process.env.REACT_APP_API_URL || '';

export const DEPARTMENTS_REQUEST_GET_ALL = 'departments/getAll';

export function getDepartments(): IAxiosAction {
  return {
    type: DEPARTMENTS_REQUEST_GET_ALL,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/department`,
      params: {},
    },
  };
}
