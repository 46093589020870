import React, { MouseEvent } from "react";

import { ChevronLeftIcon } from "@heroicons/react/outline";
import styles from "./Drawer.module.scss";

export interface DrawerProps extends React.HTMLAttributes<HTMLDivElement> {
  heading: string;
  isOpen: boolean;
  isPrimary: boolean;
  handleClose(e: MouseEvent): any;
  children: any;
}

export function Drawer({
  heading,
  isOpen,
  isPrimary,
  handleClose,
  children,
}: DrawerProps) {
  let rootClasses = [styles.root];

  if (isOpen) {
    rootClasses.push(styles.isOpen);
  }

  if (isPrimary) {
    rootClasses.push(styles.isPrimary);
  } else {
    rootClasses.push(styles.isSecondary);
  }

  return (
    <div className={rootClasses.join(" ")}>
      <div className={styles.overlay} onClick={handleClose}></div>
      <div className={styles.contentWrapper}>
        <div className={styles.headingWrapper}>
          <div
            className={`${styles.heading} text-lg leading-6 font-medium`}
            onClick={!isPrimary ? handleClose : undefined}
          >
            {!isPrimary && (
              <ChevronLeftIcon className={`${styles.icon} h-5 w-5`} />
            )}
            {heading}
          </div>
          <button className={styles.closeButton} onClick={handleClose}>
            Close
          </button>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

Drawer.defaultProps = {
  heading: "",
  isOpen: false,
  isPrimary: true,
};
