import * as Yup from "yup";

import { BaseForm } from "./BaseForm";
import { StatusMessage } from "src/components/shared/status-message";
import { useAxios } from "src/hooks/useAxios";
import { userOtpCreate, userOtpValidate } from "src/actions/user";
import { useQuery } from "src/hooks/useQuery";
import { useEffect } from "react";

export function ResetPasswordForm() {
  const [dispatchAxios, { loading, success }] = useAxios();
  const [
    dispatchAxiosValidate,
    { loading: loadingValidate, error: errorValidate },
  ] = useAxios();
  const query = useQuery();

  const schema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email").required("Required"),
  });

  const initialValues = {
    email: "",
  };

  function handleSubmit(values: any) {
    dispatchAxios(userOtpCreate(values.email));
  }

  useEffect(() => {
    const uid = query.get("uid");
    const timestamp = query.get("timestamp");
    const token = query.get("token");

    if (uid && timestamp && token && !loadingValidate && !errorValidate) {
      dispatchAxiosValidate(userOtpValidate(uid, timestamp, token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, loadingValidate, errorValidate]);

  let errorMessage = "";
  if (errorValidate) {
    switch (errorValidate.response.status) {
      case 498:
        errorMessage =
          "This one-time password link has expired or had too many failed attempts.";
        break;

      default:
        errorMessage = "Something went wrong, please try again later.";
        break;
    }
  }

  return (
    <BaseForm
      schema={schema}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      buttonText="Submit Email"
      loading={loading}
      title="Recover Password"
    >
      <div className="pt-4">
        {success && (
          <StatusMessage>
            If you have an account, an email will be sent to you with instructions to reset your password.
          </StatusMessage>
        )}
        {errorMessage && !success && (
          <StatusMessage type="error">{errorMessage}</StatusMessage>
        )}
      </div>
    </BaseForm>
  );
}
