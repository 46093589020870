import { REDIRECT, REDIRECT_TO } from "src/actions/common";

const defaultState = {
  redirectTo: null,
};

const common = (state = defaultState, action: any) => {
  switch (action.type) {
    case REDIRECT:
      return {
        ...state,
        redirectTo: null,
      };

    case REDIRECT_TO:
      const preserveQuery = action.payload.preserveQuery || false;
      const path = action.payload.path || "/";

      return {
        ...state,
        redirectTo: !preserveQuery ? path : path + window.location.search,
      };

    default:
      return state;
  }
};

export default common;
