import Autocomplete from "react-autocomplete";
import React, { useEffect, useState } from "react";
import styles from "./TailwindField.module.scss";

export type TailwindComboboxOption = {
  value: string;
  label: any;
};
interface FormikForm {
  getFieldMeta(name: string): any;
}
interface FormikField extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

interface FieldProps extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  options: TailwindComboboxOption[];
  wrapperClassname?: React.HTMLAttributes<HTMLDivElement>["className"];
  disabled?: React.InputHTMLAttributes<HTMLInputElement>["disabled"];
  label: React.ReactNode;
  labelHidden: boolean;
  form: FormikForm;
  field: FormikField;
  loading: boolean;
  handleSelect: Function;
  setValue: Function;
  value: any;
  loadingMessage: string;
  emptyMessage: string;
  resetForm: any;
  values: any;
  initialValues: any;
  onRequestOptions?: (value: any) => void;
}

export function TailwindComboBox(props: FieldProps) {
  const {
    form,
    field,
    options,
    placeholder,
    setValue,
    disabled,
    labelHidden,
    loadingMessage,
    handleSelect,
    label,
    loading,
    resetForm,
    onRequestOptions,
  } = props;
  const [displayValue, setDisplayValue] = useState("");
  const meta = form.getFieldMeta(field.name);
  let labelClasses = "text-sm leading-5 font-medium text-gray-700";
  if (labelHidden) {
    labelClasses += " sr-only";
  }

  let disabledClasses = "disabled:bg-gray-300 disabled:text-gray-500";

  // Check if inputted text matches any of the options
  // if match exists, set field value to that option value
  useEffect(() => {
    if (loading && !onRequestOptions) {
      setDisplayValue(`${loadingMessage}`);
    }
    options.forEach((option: any) => {
      if (displayValue.toLocaleLowerCase() === option.label.toLowerCase()) {
        setValue(field.name, option.value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayValue]);

  return (
    <div className="relative">
      <label htmlFor={field.name} className={labelClasses}>
        {label}
      </label>
      <Autocomplete
        value={displayValue}
        items={options}
        onChange={e => {
          resetForm();
          setValue(field.name, e.target.value);
          setDisplayValue(e.target.value);
          if (typeof onRequestOptions === "function") {
            onRequestOptions(e.target.value);
          }
        }}
        onSelect={(val: any, item: any) => {
          handleSelect(field.name, item, setValue);
          resetForm();
          setDisplayValue(item.label);
          setValue(field.name, item.value);
        }}
        shouldItemRender={(item, value) =>
          item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
        }
        inputProps={{
          placeholder: loading ? loadingMessage : placeholder,
          spellCheck: false,
          className: `${styles.selected} ${
            disabled
              ? disabledClasses
              : "relative bg-white border border-gray-300"
          }  w-full rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm`,
        }}
        getItemValue={(item: any) => item.value}
        renderInput={(props: any) => <input {...props} />}
        wrapperStyle={{
          position: "relative",
          display: "inline-block",
          width: "100%",
          borderRadius: "6px",
        }}
        menuStyle={{
          position: "absolute",
          zIndex: 998,
          width: "100%",
          top: "100%",
          marginTop: ".5rem",
          maxHeight: "250px",
          overflowY: "auto",
          borderRadius: "6px",
          left: 0,
          right: 0,
          boxShadow:
            "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
        }}
        renderItem={(item: any, highlighted: boolean) => (
          <div
            className="p-3 hover:cursor-pointer text-sm focus: text-white"
            key={`emp-name--${item.value}`}
            style={{
              backgroundColor: highlighted ? "#3792e7" : "white",
              color: highlighted ? "white" : "rgb(55 65 81)",
              transition: "all 150ms ease-in-out",
            }}
          >
            {item.label}
          </div>
        )}
      />
      {meta && meta.touched && meta.error && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <svg
            className="h-5 w-5 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
    </div>
  );
}

TailwindComboBox.defaultProps = {
  placeholder: " ",
};
