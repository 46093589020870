import { IAppointment } from "src/interfaces/appointment";
import { IAxiosAction } from "src/interfaces/axios";
import { Routes } from "src/helpers/routes";

const API_URL = process.env.REACT_APP_API_URL || "";

export const APPOINTMENTS_REQUEST_CREATE = "appointments/create";
export const APPOINTMENTS_REQUEST_GET_ALL = "appointments/getAll";
export const APPOINTMENTS_REQUEST_GET_AVAILABLE = "appointments/getAvailable";
export const APPOINTMENTS_REQUEST_GET_ANALYTICS = "appointments/getAnalytics";
export const APPOINTMENTS_REQUEST_CANCEL = "appointments/cancel";
export const APPOINTMENTS_REQUEST_UPLOAD_PDF = "appointments/uploadPdf";

export function createAppointment(appointment: IAppointment): IAxiosAction {
  return {
    type: APPOINTMENTS_REQUEST_CREATE,
    pathParameters: {},
    config: {
      method: "POST",
      url: `${API_URL}/appointment`,
      data: {
        ...appointment,
      },
    },
  };
}

export function uploadAppointmentForm(
  contactId: string,
  pdf_file: File
): IAxiosAction {
  const formData = new FormData();
  const meta = JSON.stringify({ contactId });
  formData.append("meta", meta);
  formData.append("pdf_file", pdf_file);

  return {
    type: APPOINTMENTS_REQUEST_UPLOAD_PDF,
    pathParameters: {},
    redirectOnSuccess: Routes.appointments + "?success=1",
    redirectOnFailed: Routes.appointments + "?upload_failure=1",

    config: {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${API_URL}/appointment/authorizationForm`,
      data: formData,
    },
  };
}

export function getAllAppointments(
  name: string,
  orderBy: string,
  direction: string,
  page: number = 1,
  pastOnly: number = 0
): IAxiosAction {
  return {
    type: APPOINTMENTS_REQUEST_GET_ALL,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/appointment`,
      params: {
        name,
        orderBy,
        direction,
        page,
        pastOnly,
      },
    },
  };
}

export function getAvailableAppointments(
  visitTypeId: string,
  departmentId: string,
  employeeId: string
): IAxiosAction {
  return {
    type: APPOINTMENTS_REQUEST_GET_AVAILABLE,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/appointments/search`,
      params: {
        patientId: employeeId,
        visitTypeId,
        departmentId,
      },
    },
  };
}

export function getAnalytics(): IAxiosAction {
  return {
    type: APPOINTMENTS_REQUEST_GET_ANALYTICS,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/appointments/analytics`,
      params: {},
    },
  };
}

export function cancelAppointment(
  appointmentId: string,
  comment: string
): IAxiosAction {
  return {
    type: APPOINTMENTS_REQUEST_CANCEL,
    redirectOnSuccess: Routes.appointments + "?delete_success=1",
    pathParameters: {},
    config: {
      method: "DELETE",
      data: {
        reason: 6,
        comment,
      },
      url: `${API_URL}/appointment/${appointmentId}`,
    },
  };
}
