import styles from "./TailwindField.module.scss";
import { useEffect } from "react";
import { useState } from "react";

export type TailwindSelectOption = {
  value: string;
  label: any;
};

interface FormikForm {
  getFieldMeta(name: string): any;
}

interface FormikField extends React.InputHTMLAttributes<HTMLSelectElement> {
  name: string;
}

interface FieldProps extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  options: TailwindSelectOption[];
  wrapperClassname?: React.HTMLAttributes<HTMLDivElement>["className"];
  disabled?: React.InputHTMLAttributes<HTMLInputElement>["disabled"];
  label: React.ReactNode;
  labelHidden: boolean;
  form: FormikForm;
  field: FormikField;
  loading: boolean;
  handleSelect: Function;
  setValue: Function;
  value: any;
  loadingMessage: string;
  emptyMessage: string;
}

export function TailwindSelect(props: FieldProps) {
  const {
    id,
    options,
    wrapperClassname,
    disabled,
    field,
    form,
    placeholder,
    label,
    labelHidden,
    loading,
    handleSelect,
    setValue,
    value,
    loadingMessage,
    ...rest
  } = props;

  // Builds Options if avaliable
  let selectOptions: TailwindSelectOption[] = [];

  // Filter through and build  options and load initial states
  if (loading) {
    selectOptions = [{ value: "", label: `${loadingMessage}` }];
  } else {
    selectOptions = placeholder ? [{ value: "", label: `${placeholder}` }] : [];

    Object.keys(options).forEach((option: string, index) => {
      selectOptions.push(options[index]);
    });

    if (
      !Object.keys(options).length &&
      (!loading || typeof loading === "undefined")
    ) {
      selectOptions = [{ value: "", label: `${loadingMessage}` }];
    }
  }

  const [selected, setSelected] = useState(selectOptions[0]);
  const [open, setOpen] = useState(false);

  let labelClasses = "text-sm leading-5 font-medium text-gray-700";
  if (labelHidden) {
    labelClasses += " sr-only";
  }

  let menuStateClasses =
    "invisible transition-transform overflow-hidden transform opacity-0 scale-0";
  if (open) {
    menuStateClasses =
      "transform transition-transform overflow-hidden scale-100";
  }

  let disabledClasses = "disabled:bg-gray-300 disabled:text-gray-500";

  function handleClick(selected: any) {
    setSelected(selected);
    setOpen(false);
    handleSelect(id, selected, setValue);
    setValue(id, selected.value);
  }

  function handleMenuToggle() {
    setOpen(!open);
  }

  useEffect(() => {
    if (value) {
      if (selected.value) {
        setSelected(selected);
      } else {
        setSelected(value);
      }
    } else {
      setSelected(selectOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, loading, value]);

  return (
    <div {...rest} className={wrapperClassname}>
      <label htmlFor={id} className={labelClasses}>
        {label}
      </label>
      <div className="relative">
        <button
          type="button"
          disabled={disabled}
          className={`${styles.selected} ${
            disabled
              ? disabledClasses
              : "relative bg-white border border-gray-300"
          }  w-full rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={() => handleMenuToggle()}
        >
          <span className="flex items-center pr-7">
            <span className="ml-3 block truncate">{selected.label}</span>
          </span>
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>

        {!loading && selectOptions.length > 1 ? (
          <ul
            className={`${
              open ? styles.open : styles.closed
            } absolute z-10 mt-3 w-full bg-white shadow-lg max-h-56 selectList rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
            tabIndex={-1}
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            {selectOptions.map(
              (option: TailwindSelectOption, index: number) => (
                <li
                  className={`text-gray-900 cursor-default select-none ${styles.option} relative py-2 pl-3 pr-9`}
                  id={`list-option-${index}`}
                  onClick={() =>
                    handleClick({ label: option.label, value: option.value })
                  }
                  key={index}
                >
                  <div className="flex items-center">
                    <span
                      className={`font-normal ml-3 block truncate ${
                        selected.value === option.value ? "font-bold" : ""
                      }`}
                    >
                      {option.label}
                    </span>
                  </div>

                  <span
                    className={`absolute ${
                      styles.check
                    } inset-y-0 right-0 flex items-center pr-4 ${
                      selected.value === option.value ? "" : "invisible"
                    }`}
                  >
                    {selected.value ? (
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </span>
                </li>
              )
            )}
          </ul>
        ) : (
          ""
        )}
      </div>
      <button
        onClick={() => setOpen(!open)}
        type="button"
        className={`fixed top-0 left-0 right-0 bottom-0 opacity-0 w-full h-full cursor-default ${menuStateClasses}`}
      ></button>
    </div>
  );
}

TailwindSelect.defaultProps = {
  disabled: false,
  label: "",
  labelHidden: false,
};
