import { useEffect, useState } from "react";

import { ReactComponent as AttatchmentIcon } from "./images/attatchment.svg";
import { Document } from "src/hooks/useDocuments";
import { ReactComponent as DocumentIcon } from "./images/document.svg";
import { RootState } from "src/reducers";
import { getDocument } from "src/actions/documents";
import moment from "moment";
import styles from "./DocumentRow.module.scss";
import { useAxios } from "src/hooks/useAxios";
import { useSelector } from "react-redux";

export interface DocumentRowProps extends React.HTMLAttributes<HTMLDivElement> {
  needed: Boolean;
  data: Document;
  date: Date;
}

export function DocumentRow({ needed, data, date }: DocumentRowProps) {
  const segments = data.link.split("/");
  const [dispatchAxios, { loading }] = useAxios();
  const [loadedDocument, setLoadedDocument] = useState(0);
  const { currentDocument } = useSelector((root: RootState) => root.documents);

  function openDocument(documentId: number) {
    setLoadedDocument(documentId);
    dispatchAxios(getDocument(+segments[2], documentId));
  }

  const formatedDate = moment(date).local().format("MMMM DD YYYY");

  useEffect(() => {
    if (!loading && currentDocument && loadedDocument === data.id) {
      const blob = new Blob([currentDocument], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.rel = "noreferrer";
      link.click();
    }
  }, [currentDocument, loadedDocument, loading, data]);

  return (
    <>
      {!needed ? (
        <div className="bottom-0 inset-x-0 py-4 ">
          <div className="text-sm">
            <div
              className={`${styles.documentLink} font-medium cursor-pointer`}
              onClick={() => openDocument(data.id)}
            >
              <div className="flex flex-wrap items-center">
                <div className="rounded-full bg-gray-50  p-2 mr-3">
                  <DocumentIcon />
                </div>
                <h3>{data.title}</h3>
                <time className="ml-auto color  font-normal text-gray-400">
                  {formatedDate}
                </time>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bottom-0 inset-x-0 py-4 ">
          <div className="text-sm">
            <div
              className={`${styles.documentLink} ${styles.documentImportant} font-medium cursor-pointer`}
              onClick={() => openDocument(data.id)}
            >
              <div className="flex flex-wrap items-center">
                <div className="rounded-full bg-gray-50  p-2 mr-3">
                  <AttatchmentIcon />
                </div>
                <h3>{data.title}</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
