import styles from "./ErrorMessage.module.scss";

export function ErrorMessage(props: React.HTMLAttributes<HTMLDivElement>) {
  const { children, className, ...rest } = props;

  return (
    <div {...rest} className={`${styles.root} ${className}`}>
      {children}
    </div>
  );
}
