import { useDispatch } from "react-redux";

import { Routes, formatPath } from "src/helpers/routes";
import { redirect } from "src/actions/common";
import { TableRow, TableCell } from "src/components/shared/table";
import { ReactComponent as ArrowIcon } from "../images/arrow-right.svg";
import { Button } from "src/components/shared/button";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { userSetActiveOrg, userSetRefreshing } from "src/actions/user";
import { useAxios } from "src/hooks/useAxios";
import { IAxiosReturn } from "src/interfaces/axios";

export interface OrganizationRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  isEven: boolean;
  id: string;
  name: string;
  users: number;
  address: string;
  phone: string;
}

export function OrganizationRow({
  isEven,
  id,
  name,
  users,
  address,
  phone,
}: OrganizationRowProps) {
  const dispatch = useDispatch();
  const [dispatchAxios, { loading }] = useAxios();
  const [currentUser] = useCurrentUser(false);

  function goToOrg() {
    dispatch(redirect(formatPath(Routes.adminOrg, { orgId: id })));
  }

  function goToOrgDashboard() {
    dispatchAxios(userSetActiveOrg(currentUser.id, id)).then(
      (status: IAxiosReturn) => {
        if (!status.success) {
          return;
        }
        dispatch(userSetRefreshing(true));
        dispatch(redirect(formatPath(Routes.root)));
        dispatch(userSetRefreshing(false));
      }
    );
  }

  return (
    <TableRow isEven={isEven}>
      <TableCell className="w-56 px-6 py-4">
        <div onClick={goToOrg}>{name}</div>
      </TableCell>
      <TableCell className="w-10 text-right">
        <div onClick={goToOrg}>{phone}</div>
      </TableCell>
      <TableCell>
        <div onClick={goToOrg}>{address}</div>
      </TableCell>
      <TableCell className="w-4 text-right px-4">
        <div onClick={goToOrg}>{users}</div>
      </TableCell>
      <TableCell className="w-10 p-4">
        <div className="w-full flex justify-end" onClick={goToOrg}>
          <ArrowIcon />
        </div>
      </TableCell>
      <TableCell className="w-10 p-4">
        <Button primary onClick={goToOrgDashboard}>
          {loading ? "Loading..." : "Dashboard"}
        </Button>
      </TableCell>
    </TableRow>
  );
}

OrganizationRow.defaultProps = {
  isEven: false,
};
