import {
  ORGANIZATION_REQUEST_ANALYTICS,
  ORGANIZATION_REQUEST_GET,
  ORGANIZATION_REQUEST_GET_ALL,
} from "src/actions/organization";

const defaultState = {
  total: 0,
  pages: 0,
  currentPage: 1,
  searchInput: "",
  orderBy: "name",
  direction: "ASC",
  organizations: [],
  analytics: {},
};

const organization = (state = defaultState, action: any) => {
  switch (action.type) {
    case ORGANIZATION_REQUEST_GET:
      if (action.response) {
        const getOrgResponse = action.response.data;

        return {
          ...state,
          total: getOrgResponse.total,
          pages: getOrgResponse.pages,
          currentPage: getOrgResponse.currentPage,
          searchInput: getOrgResponse.searchInput,
          orderBy: getOrgResponse.orderBy,
          direction: getOrgResponse.direction,
          organizations: getOrgResponse.result,
        };
      }
      return state;

    case ORGANIZATION_REQUEST_ANALYTICS:
      return {
        ...state,
        analytics: action.response ? action.response.data : {},
      };

    case ORGANIZATION_REQUEST_GET_ALL: {
      if (action.response) {
        const getOrgResponse = action.response.data;
        return {
          ...state,
          organizations: getOrgResponse.result,
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default organization;
