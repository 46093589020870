import { ReactComponent as ArrowDown } from "./images/arrow-down.svg";
import { ReactComponent as ArrowUp } from "./images/arrow-up.svg";
import React from "react";
import { TableRowProps } from "./TableRow";
import styles from "./Table.module.scss";

export interface TableHeadCellData {
  name: string;
  srOnly?: boolean;
  direction?: string;
  active?: boolean;
  onClick?(): any;
}

export interface TableProps extends React.HTMLAttributes<HTMLDivElement> {
  headers?: TableHeadCellData[];
  fixed?: boolean;
  reverseStripes?: boolean;
  children: any;
}

function isIndexEven(index: number, reverse: boolean = false): boolean {
  let isEven: boolean = false;
  if (index % 2 === 0) {
    isEven = true;
  }

  if (reverse) {
    return !isEven;
  }

  return isEven;
}

export function Table({
  headers,
  reverseStripes,
  fixed,
  children,
}: TableProps) {
  let formattedHeaders;
  if (headers) {
    formattedHeaders = headers.map(
      ({ name, srOnly, direction, active, onClick }: TableHeadCellData) => {
        if (srOnly) {
          return (
            <th
              key={`thead-${name}`}
              scope="col"
              className="relative px-6 py-3"
            >
              <span className="sr-only">{name}</span>
            </th>
          );
        }

        let textClass: string = active ? "text-blue-500" : "text-gray-500";
        return (
          <th
            onClick={onClick}
            key={`thead-${name}`}
            scope="col"
            className={`relative px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${textClass}`}
          >
            {name}
            <div className={styles.icon}>
              {direction && (direction === "ASC" ? <ArrowUp /> : <ArrowDown />)}
            </div>
          </th>
        );
      }
    );
  }

  const filteredChildren = children.map(
    (
      child:
        | React.ReactElement<TableRowProps>[]
        | React.ReactElement<TableRowProps>,
      index: number
    ) => {
      let isEven: boolean = isIndexEven(index, reverseStripes);

      if (!Array.isArray(child)) {
        return React.cloneElement(child, { isEven });
      }

      return child.map((computedChild, index) => {
        isEven = isIndexEven(index + 1, reverseStripes);
        return React.cloneElement(computedChild, { isEven });
      });
    }
  );

  return (
    <div className="flex flex-col">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table
              className={`min-w-full divide-y divide-gray-200 relative ${
                fixed ? "table-fixed" : "table-auto"
              } ${styles.responsive}`}
            >
              {formattedHeaders && (
                <thead className="bg-gray-50">
                  <tr>{formattedHeaders}</tr>
                </thead>
              )}
              <tbody>{filteredChildren}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
