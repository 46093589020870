import { Skeleton } from "src/components/shared/skeleton";

export interface StatProps {
  heading: string;
  stat: number | string;
  smallText: string;
  change: number | string;
  loading?: boolean;
}

export function Stat({ heading, stat, smallText, change, loading }: StatProps) {
  let direction: string = "";
  if (parseFloat(`${change}`) < 0) {
    direction = "down";
  } else if (parseFloat(`${change}`) > 0) {
    direction = "up";
  } else if (change === 0) {
    direction = "even";
  }
  return (
    <div className="px-4 py-5 sm:p-3">
      {heading && (
        <dt className="text-base font-normal text-gray-900">{heading}</dt>
      )}
      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
        {typeof stat === "number" ? (
          <div className="flex items-baseline text-2xl font-semibold text-blue-600">
            {stat}
            {smallText && (
              <span className="ml-2 text-sm font-medium text-gray-500">
                {" "}
                {smallText}
              </span>
            )}
          </div>
        ) : (
          <Skeleton />
        )}

        {!loading && direction === "up" && (
          <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
            <svg
              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z "
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Increased by</span>
            {change}
          </div>
        )}

        {!loading && direction === "down" && (
          <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 md:mt-2 lg:mt-0">
            <svg
              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Decreased by</span>
            {change}
          </div>
        )}

        {!loading && direction === "even" && (
          <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-500 md:mt-2 lg:mt-0 ">
            {change}%
          </div>
        )}
      </dd>
    </div>
  );
}

Stat.defaultProps = {
  heading: "",
  stat: "",
  smallText: "",
  change: "",
  loading: false,
};
