import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import { reduxTimeout } from 'redux-timeout';
import thunkMiddleware from 'redux-thunk';
import { reducers } from 'src/reducers';

// Add middleware to redux.
const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(thunkMiddleware, reduxTimeout());
  }
  else {
    // Add additional logging to non-production environments.
    return applyMiddleware(thunkMiddleware, reduxTimeout(), logger);
  }
};

export const store = createStore(reducers, getMiddleware());
