import { AppointmentRow, AppointmentRowProps } from "./AppointmentRow";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Tab, Tabs } from "src/components/shared/tabs";
import { Table, TableSkeleton } from "src/components/shared/table";

// import { PageLoader } from "src/components/shared/loader/page-loader/PageLoader";
import { Pager } from "src/components/shared/pager";
import { RootState } from "src/reducers";
import { SearchInput } from "src/components/shared/search-input";
import { getAllAppointments } from "src/actions/appointments";
import { useAxios } from "src/hooks/useAxios";
import { useSelector } from "react-redux";

export function AppointmentResults() {
  const [dispatchAxios, { loading }] = useAxios();
  const [currentSort, setCurrentSort] = useState("name");
  const [currentDirection, setCurrentDirection] = useState("ASC");
  const {
    total,
    pages,
    currentPage,
    searchInput,
    orderBy,
    direction,
    pastOnly,
    appointments,
  } = useSelector((root: RootState) => root.appointments);
  let results: Array<any> = appointments || [];

  function fetchAllAppointments(
    search: string,
    order: string,
    sort: string,
    page: number,
    past: number
  ) {
    dispatchAxios(getAllAppointments(search, order, sort, page, past));
  }

  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    fetchAllAppointments(e.target.value, orderBy, direction, 1, pastOnly);
  }

  function handleSort(column: string) {
    const newDirection = direction === "ASC" ? "DESC" : "ASC";
    setCurrentSort(column);
    setCurrentDirection(newDirection);
    fetchAllAppointments(
      searchInput,
      column,
      newDirection,
      currentPage,
      pastOnly
    );
  }

  function handleTogglePast(past: number) {
    fetchAllAppointments(searchInput, orderBy, direction, currentPage, past);
  }

  // Load all organization users once on render.
  useEffect(() => {
    fetchAllAppointments(
      searchInput,
      orderBy,
      direction,
      currentPage,
      pastOnly
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mb-5">
        <SearchInput onChange={handleSearch} />
      </div>
      <div className="relative">
        {pastOnly === 0 && (
          <div className="md:absolute right-0 bottom-1 flex flex-col items-end justify-center">
            <div className="flex items-center justify-end">
              <p className="text-sm flex italic font-light text-gray-500 pr-3">
                active
              </p>
              <span
                className={` inline-flex items-center h-3 w-3 rounded-full text-xs font-medium bg-green-400`}
              />
            </div>
            <div className="flex items-center justify-end">
              <p className="text-sm flex  italic font-light text-gray-500 pr-3">
                canceled
              </p>
              <span
                className={` inline-flex items-center h-3 w-3 rounded-full text-xs font-medium bg-red-400`}
              />
            </div>
          </div>
        )}
        <Tabs>
          <Tab active={pastOnly === 0} onClick={() => handleTogglePast(0)}>
            Upcoming Appointments
          </Tab>
          <Tab active={pastOnly === 1} onClick={() => handleTogglePast(1)}>
            Past Appointments
          </Tab>
        </Tabs>
      </div>
      {total > 0 && (
        <div className="relative rounded-xl">
          <Table
            headers={[
              {
                name: "Employee Name",
                direction: currentSort === "name" ? currentDirection : "DESC",
                active: currentSort === "name",
                onClick: () => handleSort("name"),
              },
              {
                name: "Visit Type",
                direction:
                  currentSort === "visitType" ? currentDirection : "DESC",
                active: currentSort === "visitType",
                onClick: () => handleSort("visitType"),
              },
              {
                name: "Location",
                direction:
                  currentSort === "location" ? currentDirection : "DESC",
                active: currentSort === "location",
                onClick: () => handleSort("location"),
              },
              {
                name: "Date and Time",
                direction: currentSort === "date" ? currentDirection : "DESC",
                active: currentSort === "date",
                onClick: () => handleSort("date"),
              },
            ]}
          >
            {results.map((appointment: AppointmentRowProps, index: number) => {
              return (
                <AppointmentRow
                  {...appointment}
                  viewingUpcoming={pastOnly === 0}
                  key={`appointment-row-${index}`}
                />
              );
            })}
          </Table>
          <Pager
            total={total}
            pages={pages}
            currentPage={currentPage}
            getPage={(page: number) => {
              return (e: MouseEvent) => {
                e.preventDefault();
                fetchAllAppointments(
                  searchInput,
                  orderBy,
                  direction,
                  page,
                  pastOnly
                );
              };
            }}
          />
        </div>
      )}
      {loading && !total && <TableSkeleton rows={5} cells={5} />}
      {!loading && !total && (
        <div className="mt-5 mb-5 text-lg">No appointments found</div>
      )}
    </>
  );
}
