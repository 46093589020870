import Autocomplete from "react-autocomplete";
import React from "react";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  field: React.InputHTMLAttributes<HTMLInputElement>;
  form: any;
  options: Array<any>;
  onRequestOptions(value: any): any;
}

export function AutoCompleteInput(props: IProps) {
  const { form, field, options, onRequestOptions, placeholder } = props;

  const { name, value } = field;
  const { setFieldValue } = form;

  function handleOnChange(event: any, value: string) {
    setFieldValue(name!, value);
    onRequestOptions(value);
  }

  const meta = form.getFieldMeta(name);
  return (
    <div className="relative">
      <Autocomplete
        {...field}
        value={value}
        items={options}
        onChange={handleOnChange}
        onSelect={handleOnChange}
        inputProps={{ placeholder, type: "text" }}
        getItemValue={(item: string) => item}
        wrapperStyle={{
          position: "relative",
        }}
        menuStyle={{
          position: "absolute",
          zIndex: 998,
          width: "100%",
          top: "100%",
          marginTop: "1rem",
          borderRadius: "6px",
          overflow: "hidden",
          border: "2px solid #2563eb",
          left: 0,
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
        }}
        renderItem={(item: string, highlighted: boolean) => (
          <div
            className="p-3 hover:cursor-pointer"
            key={`org-name--${item}`}
            style={{ backgroundColor: highlighted ? "#eee" : "white" }}
          >
            {item}
          </div>
        )}
      />
      {meta && meta.touched && meta.error && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <svg
            className="h-5 w-5 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
    </div>
  );
}

AutoCompleteInput.defaultProps = {
  placeholder: " ",
};
