import { ReactComponent as EditCircle } from "./images/edit-circle.svg";
import { ReactComponent as EmployeeIcon } from "./images/employee.svg";
import { Link } from "react-router-dom";
import { ReactComponent as PlusCircle } from "./images/plus-circle.svg";
import styles from "./CallToAction.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  heading: string;
  description: string;
  linkUrl: string;
  linkText: string;
  edit: boolean;
}

export function CallToAction({
  heading,
  description,
  linkUrl,
  linkText,
  edit,
}: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.contentWrapper}>
        <div className={styles.image}>
          <EmployeeIcon />
        </div>
        <div className={styles.content}>
          <div className="text-lg text-gray-900">{heading}</div>
          <div className="text-sm text-gray-500">{description}</div>
        </div>
      </div>
      <div className={styles.linkWrapper}>
        <Link
          to={linkUrl}
          className={`${styles.link} text-sm bg-blue-50 shadow-sm sm:rounded-lg`}
        >
          {edit ? <EditCircle /> : <PlusCircle />}
          {linkText}
        </Link>
      </div>
    </div>
  );
}
