import { CallToAction } from "src/components/shared/cta";
import { ProfileDetails } from "./ProfileDetails";
import React from "react";
import { Routes } from "src/helpers/routes";
import styles from "./Profile.module.scss";
export function Profile() {
  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <h1 className="text-2xl leading-7 font-bold text-gray-900">
          Your Profile
        </h1>
      </header>
      <div className={styles.contentWrapper}>
        <div className={styles.intro}>
          <h2 className="text-lg text-gray-900 leading-6">
            Current Profile Information
          </h2>
        </div>
        <ProfileDetails />
        <div
          className={`${styles.callToActions} grid grid-cols-1 lg:grid-cols-2 gap-6`}
        >
          <CallToAction
            heading="Update Profile"
            description="Keep your profile update and edit your current info."
            linkUrl={Routes.updateProfile}
            linkText="Update Profile"
            edit={true}
          />

          <CallToAction
            heading="Update Password"
            description="Update your password if you feel that your current info is not secure."
            linkUrl={Routes.updatePassword}
            linkText="Update Password"
            edit={true}
          />
        </div>
      </div>
    </div>
  );
}
