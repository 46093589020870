import { IAxiosAction } from "src/interfaces/axios";

const API_URL = process.env.REACT_APP_API_URL || "";

export const ENCOUNTERS_REQUEST_GET_RECENT = "encounters/recent";
export const ENCOUNTERS_REQUEST_GET_RESULT = "encounters/result";

export function getRecentEncounters(
  searchTerm: string,
  from: string,
  to: string,
  orderBy: string,
  direction: string,
  page: number = 1,
  type: string = "all",
  visitType: string = "all"
): IAxiosAction {
  const params = {
    searchTerm,
    from,
    to,
    orderBy,
    direction,
    page,
    type,
    visitType,
  };

  return {
    type: ENCOUNTERS_REQUEST_GET_RECENT,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/encounter`,
      params: params,
    },
  };
}

export function getEncounterResult(resultId: string): IAxiosAction {
  return {
    type: ENCOUNTERS_REQUEST_GET_RESULT,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/encounter/result/${resultId}`,
    },
  };
}
