import styles from "./Tiles.module.scss";

export interface TileProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode[] | React.ReactNode | string;
}

export function Tile({ children }: TileProps) {
  return (
    <div className={`${styles.tile} rounded-lg shadow w-full `}>{children}</div>
  );
}
