import { MouseEvent, useEffect, useState } from "react";

import { Button } from "src/components/shared/button";
import { Document } from "src/hooks/useDocuments";
import { DocumentRow } from "../../../shared/document-row/DocumentRow";
import { Drawer } from "src/components/shared/drawer";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { IEncounter } from "src/interfaces/encounter";
import { IResult } from "src/interfaces/result";
import { OrdersDrawer } from "./OrdersDrawer";
import { ReferralsDrawer } from "./ReferralsDrawer";
import { ResultDrawer } from "./ResultDrawer";
import { RootState } from "src/reducers";
import { Skeleton } from "src/components/shared/skeleton";
import { getDocuments } from "src/actions/documents";
import { useAxios } from "src/hooks/useAxios";
import { useSelector } from "react-redux";

interface Props extends IEncounter {
  isOpen: boolean;
  encounterFhirId?: string;
  handleClose(e: MouseEvent): any;
}

export function EncounterDrawer(props: Props) {
  const {
    id,
    patientFirstName,
    patientId,
    patientLastName,
    visitType,
    results,
    isOpen,
    encounterFhirId,
    handleClose,
  } = props;

  const [dispatchAxios, { loading }] = useAxios();
  const [referralOpen, setReferralOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const [activeResult, setActiveResult] = useState("");
  const { documents } = useSelector((root: RootState) => root.documents);
  const patientName = patientFirstName + " " + patientLastName;
  const isDrugScreen = ~visitType.toLowerCase().indexOf("drug screen");
  // const isWcVisit = ~type.toLowerCase().indexOf("work comp");

  function getActiveDocuments() {
    if (isOpen && documents) {
      dispatchAxios(getDocuments(id));
    }
  }

  useEffect(() => {
    getActiveDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function handleSecondaryClose(e: MouseEvent) {
    e.stopPropagation();
    setActiveResult("");
    setReferralOpen(false);
    setOrderOpen(false);
  }
  // Group results by type.
  const resultCache: any = {};
  const groupedResults = results.reduce((acc: any, current: IResult) => {
    const type = current.type || "Misc";
    // Save each result in a keyed object to make grabbing the active result
    // easier.
    if (!resultCache[current.id]) {
      resultCache[current.id] = current;
    }

    acc[type] = acc[type] || [];
    acc[type].push(current);
    return acc;
  }, {});

  // Build final lists.
  const resultList = Object.keys(groupedResults).map((type) => {
    const currentResults = groupedResults[type];

    const resultGroup = currentResults.map((result: IResult) => {
      if (result.link) {
        return (
          <li
            key={`result-item-${result.id}`}
            className="mb-2 text-sm leading-5 font-medium text-gray-900 hover:text-blue-500 cursor-pointer"
          >
            <a href={result.link} target="_blank" rel="noreferrer">
              {result.title}
            </a>
          </li>
        );
      }

      return (
        <li
          key={`result-item-${result.id}`}
          className="mb-2 text-sm leading-5 font-medium text-gray-900 hover:text-blue-500 cursor-pointer"
          onClick={() => setActiveResult(result.id)}
        >
          {result.title}
        </li>
      );
    });

    return (
      <div key={`result-group-${type}`}>
        <h2 className="mb-2 text-lg leading-6 font-medium text-gray-900">
          {type.toLowerCase() === "scrub notes" ? "Doctor Notes" : type}
        </h2>
        <ul className="mb-10">{resultGroup}</ul>
      </div>
    );
  });

  // Group documents by type.
  const groupedDocResults = documents.reduce((acc: any, current: any) => {
    let type = current.type || "form";

    // Format types.
    switch (type) {
      case "form":
        type = "Forms";
        break;

      case "result":
        type = "Lab";
        break;
    }

    acc[type] = acc[type] || [];
    acc[type].push(current);
    return acc;
  }, {});

  // Build final documents lists.
  const resultDocList = Object.keys(groupedDocResults).map((type) => {
    const currentResults = groupedDocResults[type];

    const filterdResults = currentResults.filter(
      (result: Document) => ~result.link.indexOf(`${id}`)
    );

    const date = new Date(props.appointmentDateTime);
    const resultGroup = filterdResults.map((result: Document) => {
      return (
        <li key={`result-item-${result.id}`}>
          {loading ? (
            <div className="py-4">
              <Skeleton />
            </div>
          ) : (
            <DocumentRow
              key={`result-document-${result.id}`}
              needed={false}
              data={result}
              date={date}
            />
          )}
        </li>
      );
    });

    return (
      <div key={`result-group-${type}`}>
        <h3 className="text-lg leading-6 font-medium">{type}</h3>
        {loading ? (
          <div className="py-4">
            <Skeleton />
          </div>
        ) : (
          <ul className="mb-10">{resultGroup}</ul>
        )}
      </div>
    );
  });

  if (isDrugScreen) {
    return (
      <Drawer
        heading={`${patientName}: ${visitType} Details`}
        handleClose={handleClose}
        isOpen={isOpen}
      >
        {resultDocList}

        <a
          href="https://fhccbhs.mytotaltest.com/clnt/login.asp"
          className="text-blue-500 flex items-center hover:text-blue-700"
          target="_blank"
          rel="noreferrer"
        >
          Click here for drug test results{" "}
          <span className="pl-1">
            <ExternalLinkIcon className="h-4 w-4 mt-1/2" />
          </span>
        </a>
      </Drawer>
    );
  }

  return (
    <Drawer
      heading={`${patientName}: ${visitType} Details`}
      handleClose={handleClose}
      isOpen={isOpen}
    >
      <Button
        primary
        type="button"
        className="mb-10"
        onClick={() => setReferralOpen(true)}
      >
        Referrals
      </Button>

      <Button
        primary
        type="button"
        className="mb-10 ml-4"
        onClick={() => setOrderOpen(true)}
      >
        Orders
      </Button>

      {resultDocList}

      {resultList}

      {activeResult && resultCache[activeResult] && (
        <ResultDrawer
          {...props}
          {...resultCache[activeResult]}
          handleClose={handleSecondaryClose}
        />
      )}

      {referralOpen && (
        <ReferralsDrawer
          {...props}
          title={`All referrals for patient ${patientName}`}
          patientId={patientId}
          handleClose={handleSecondaryClose}
        />
      )}
      {orderOpen && encounterFhirId && (
        <OrdersDrawer
          {...props}
          title={`All orders for patient ${patientName}`}
          encounterFhirId={encounterFhirId}
          handleClose={handleSecondaryClose}
        />
      )}
    </Drawer>
  );
}
