export interface TableCellProps
  extends React.HTMLAttributes<HTMLTableCellElement> {
  children: any;
}

export function TableCell({ children, className }: TableCellProps) {
  return (
    <td
      className={`text-sm font-medium text-gray-900${
        className ? " " + className : "px-6 py-4 overflow-elipsis"
      }`}
    >
      {children}
    </td>
  );
}
