import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import FontBoldRoboto from "../../../../src/assets/fonts/TypoPRO-Roboto-Bold.ttf";
import FontMedRoboto from "../../../../src/assets/fonts/TypoPRO-Roboto-Medium.ttf";
import FontRegularRoboto from "../../../../src/assets/fonts/TypoPRO-Roboto-Regular.ttf";
import { IEncounter } from "src/interfaces/encounter";
import Logo from "./images/centracare.png";
import moment from "moment";

interface OrderProps extends IEncounter {
  order: any;
}
export function LayoutOrderPdf(props: OrderProps) {
  const {
    order,
    patientFirstName,
    patientLastName,
    locationAddress,
    locationCity,
    patientPhoneNumber,
    locationState,
    locationZipCode,
    locationPhoneNumber,
    patientMrn,
    patientSSN,
    patientSex,
    patientBirthDate,
  } = props;

  const styles = StyleSheet.create({
    page: {
      height: "100%",
      width: "100%",
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      paddingBottom: 20,
      position: "relative",
    },
    body: { alignContent: "space-between", display: "flex" },
    column: { display: "flex", flexDirection: "column" },
    image: {
      display: "flex",
      width: 100,
      marginLeft: "auto",
      marginRight: "auto",
    },
    headers: {
      fontSize: 16,
      paddingTop: "8pt",
      fontFamily: "Roboto",
      fontWeight: 500,
    },
    subHeaders: {
      fontSize: 16,
      paddingBottom: "4pt",
      paddingTop: "8pt",
      fontFamily: "Roboto",
      fontWeight: 500,
    },
    item: {
      paddingBottom: "4pt",
      paddingTop: "4pt",
      fontSize: 12,
      fontFamily: "Roboto",
    },
    label: { fontWeight: 600, fontSize: 12, fontFamily: "Roboto" },
    subLabel: { fontWeight: 500, fontSize: 12, fontFamily: "Roboto" },

    row: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },

    section: { borderBottom: 1, borderBottomColor: "#e4e4e7", padding: 8 },

    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColHeader: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCol: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },

    tableCell: {
      margin: 5,
      fontSize: 10,
    },
  });

  // const table = {
  //   display: "table",
  //   width: "auto",
  //   borderStyle: "solid",
  //   borderColor: "#bfbfbf",
  //   borderWidth: 1,
  //   borderRightWidth: 0,
  //   borderBottomWidth: 0,
  // };

  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: FontRegularRoboto,
      },
      {
        src: FontMedRoboto,
        fontWeight: 600,
      },
      {
        src: FontBoldRoboto,
        fontWeight: 500,
      },
    ],
  });
  return (
    <Document>
      <Page wrap style={styles.page} size="A4">
        <View style={styles.body}>
          <View style={styles.row}>
            <Image source={Logo} style={styles.image} />
          </View>
          <View style={styles.row}>
            <View>
              <Text style={styles.label}>{locationAddress}</Text>
              <Text
                style={styles.label}
              >{`${locationCity}, ${locationState}`}</Text>
              <Text style={styles.label}>{locationZipCode}</Text>
              <Text style={styles.label}>
                Phone: {`${locationPhoneNumber}`}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.label}>Date:&nbsp;</Text>
                <Text style={styles.item}>
                  {moment(order.resource.authoredOn).format("MM/DD/YYYY")}
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.row}>
              <Text style={styles.headers}>Patient:</Text>
            </View>
            <View style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.item}>
                  <Text style={styles.label}>Legal Name:&nbsp;</Text>
                  {patientFirstName + " " + patientLastName}
                </Text>
                <Text style={styles.item}>
                  {/* <Text style={styles.label}>Address:&nbsp;</Text>
                  {`%Address%`} */}
                </Text>
                <Text style={styles.item}>
                  <Text style={styles.label}> Phone:&nbsp;</Text>
                  {patientPhoneNumber}
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.item}>
                  <Text style={styles.label}>MRN:&nbsp;</Text>
                  {patientMrn}
                </Text>
                <Text style={styles.item}>
                  <Text style={styles.label}>DOB:&nbsp; </Text>
                  {moment(patientBirthDate).format("MM/DD/YYYY")}
                </Text>
                <Text style={styles.item}>
                  <Text style={styles.label}>SSN:&nbsp; </Text>
                  {patientSSN}
                </Text>
                <Text style={styles.item}>
                  <Text style={styles.label}>Sex:&nbsp; </Text>
                  {patientSex}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            {/* <View style={table as ReactPDF.Styles}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.label}>INSURANCE</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.label}>PAYOR</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.label}>PLAN</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.label}>GROUP #</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.label}>SUBSCRIBER ID</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{"%DATA%"}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{"%DATA%"}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{"%DATA%"}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{"%DATA%"}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{"%DATA%"}</Text>
                </View>
              </View>
            </View> */}
            <View style={styles.row}>
              <View>
                {/* <Text style={styles.item}>
                  <Text style={styles.label}>Allergies:&nbsp; </Text>
                  {"%Data%"}
                </Text> */}
              </View>
              <View style={styles.row}>
                <Text style={styles.item}>
                  <Text style={styles.label}> Order Date:&nbsp;</Text>
                  {moment(order.resource.authoredOn).format("MM/DD/YYYY")}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.row}>
              <Text style={styles.subHeaders}>{order.resource.code.text}</Text>
            </View>
            <View style={styles.row}>
              <View style={styles.column}>
                {/* <Text style={styles.item}>
                  <Text style={styles.label}>Diagnosis:&nbsp;</Text>
                  {"%Data%"}
                </Text> */}

                <Text style={styles.item}>
                  <Text style={styles.label}>Reason for Exam:&nbsp;</Text>
                  {order.resource.reasonCode
                    ? order.resource.reasonCode[0].text
                    : ""}
                </Text>
              </View>
            </View>
            {/* <View style={styles.row}>
              <Text style={styles.item}>
                <Text style={styles.label}>Priority:&nbsp;</Text>
                {`%Data%`}
              </Text>
              <Text style={styles.item}>
                <Text style={styles.label}> Expiration Date:&nbsp;</Text>
                {`%Data%`}
              </Text>

              <Text style={styles.item}>
                <Text style={styles.label}>Interval:&nbsp;</Text>
                {`%Data%`}
              </Text>

              <Text style={styles.item}>
                <Text style={styles.label}> Count:&nbsp;</Text>
                {`%Data%`}
              </Text>
            </View> */}
          </View>
          {/* <View style={styles.section}>
            <View style={styles.row}>
              <Text style={styles.label}>
                Does this patient require anesthesia?::&nbsp;
                {`%Data%`}
              </Text>
            </View>
            <View style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.item}>
                  <Text style={styles.label}>Relase to patient:&nbsp;</Text>
                  {`%Data%`}
                </Text>
              </View>
            </View>
          </View> */}
          <View>
            <View style={styles.row}>
              <View style={styles.column}>
                {/* <Text style={styles.item}>
                  <Text style={styles.label}>Ordered By:&nbsp;</Text>
                  {`%Data%`}
                </Text> */}
                <Text style={styles.item}>
                  <Text style={styles.label}>
                    Electronically Signed By:&nbsp;
                  </Text>
                  {order.resource.requester.display}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
