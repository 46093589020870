import { AppointmentResults } from "./elements/AppointmentResults";
import { CallToAction } from "src/components/shared/cta";
import { Routes } from "src/helpers/routes";
import { StatusMessage } from "src/components/shared/status-message";
import styles from "./Appointments.module.scss";
import { useQuery } from "src/hooks/useQuery";

export function Appointments() {
  const query = useQuery();
  const success = query.get("success") ?? null;
  const deleteSuccess = query.get("delete_success") ?? null;
  const uploadFailure = query.get("upload_failure") ?? null;

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <h1 className="text-2xl leading-7 font-bold text-gray-900">
          Appointments
        </h1>
      </header>
      <div>
        {success && (
          <StatusMessage>Appointment created successfully!</StatusMessage>
        )}
        {deleteSuccess && (
          <StatusMessage>Appointment canceled successfully!</StatusMessage>
        )}
        {uploadFailure && (
          <>
            <StatusMessage>Appointment created successfully!</StatusMessage>
            <StatusMessage type="error">
              Authorization form upload failed. Please bring a physical copy of
              your authorization form to your appointment.
            </StatusMessage>
          </>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.intro}>
          <h2 className="text-lg text-gray-900 leading-6">
            Create Your Next Appointment
          </h2>
        </div>
        <div className={`grid grid-cols-12 gap-8 ${styles.callToActions}`}>
          <div className="col-span-12 lg:col-span-6">
            <CallToAction
              heading="New Employee"
              description="Create an Appointment for an Employee not yet in the Employer Portal."
              linkUrl={Routes.apptCreateEmployee}
              linkText="Create Appointment"
              edit={false}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <CallToAction
              heading="Existing Employee"
              description="Create an Appointment for an Employee already in the Employer Portal."
              linkUrl={Routes.apptCreate}
              linkText="Create Appointment"
              edit={false}
            />
          </div>
        </div>
        <div>
          <AppointmentResults />
        </div>
      </div>
    </div>
  );
}
