import { Button } from "src/components/shared/button";
import { IEncounter } from "src/interfaces/encounter";
import { LayoutOrderPdf } from "src/components/layouts/layout-order-pdf/LayoutOrderPdf";
import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

export interface OrderListProps extends IEncounter {
  orders: any[];
}

export function Orders(props: OrderListProps) {
  const {
    orders,
    patientFirstName,
    patientLastName,
    locationAddress,
    locationCity,
    patientPhoneNumber,
    locationState,
    locationZipCode,
    locationPhoneNumber,
    patientMrn,
    patientSSN,
    patientSex,
    patientBirthDate,
  } = props;

  return (
    <div className="mb-10">
      {orders?.map((result, index) => {
        const OrderDocument = () => {
          return <LayoutOrderPdf {...props} order={result} />;
        };

        if (result.resource.resourceType == 'OperationOutcome') {
          return <></>;
        }

        return (
          <div
            className="grid grid-flow-row bg-white  rounded-md shadow-md mb-12 border border-gray-200"
            key={`referral-${result.ExternalReferralNumber}-${index}`}
          >
            <div className="mt-4 p-4 ">
              <div className="flex justify-between">
                <div>
                  <p>{locationAddress}</p>
                  <p>{`${locationCity}, ${locationState}`}</p>
                  <p>{locationZipCode}</p>
                  <p>Phone: {`${locationPhoneNumber}`}</p>
                </div>
                <div>
                  <p className="pb-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Date:&nbsp;
                    </span>
                    {moment(result.resource.authoredOn).format("MM/DD/YYYY")}
                  </p>
                  {/* <p className="pb-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Order ID::&nbsp;
                    </span>
                    %DATA%
                  </p> */}
                </div>
              </div>
              <div className="grid grid-flow-row divide-y-2 pt-4">
                <div>
                  <h3 className="text-xl">Patient:</h3>
                  <div className="flex justify-between items-start">
                    <div>
                      <p className="py-1">
                        <span className="font-bold text-gray-600 pr-2">
                          Legal Name:&nbsp;
                        </span>
                        {patientFirstName + " " + patientLastName}
                      </p>
                      {/* <p className="py-1">
                        <span className="font-bold text-gray-600 pr-2">
                          Address:&nbsp;
                        </span>
                        {`${locationAddress}, ${locationCity}, ${locationState} ${locationZipCode}`}
                      </p> */}
                      <p className="py-1">
                        <span className="font-bold text-gray-600 pr-2">
                          Phone:&nbsp;
                        </span>
                        {patientPhoneNumber}
                      </p>
                    </div>
                    <div>
                      <p className="py-1">
                        <span className="font-bold text-gray-600 pr-2">
                          MRN:&nbsp;
                        </span>
                        {patientMrn}
                      </p>
                      <p className="py-1">
                        <span className="font-bold text-gray-600 pr-2">
                          DOB:&nbsp;
                        </span>
                        {moment(patientBirthDate).format("MM/DD/YYYY")}
                      </p>
                      <p className="py-1">
                        <span className="font-bold text-gray-600 pr-2">
                          SSN:&nbsp;
                        </span>
                        {patientSSN}
                      </p>
                      <p className="py-1">
                        <span className="font-bold text-gray-600 pr-2">
                          Sex:&nbsp;
                        </span>
                        {patientSex}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt-4">
                  {/* <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="text-left">PAYOR</th>
                        <th className="text-left">PLAN</th>
                        <th className="text-left">GROUP #</th>
                        <th className="text-left">SUBSCRIBER ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table> */}
                  <div className="pt-4">
                    <p className="pt-1">
                      <span className="font-bold text-gray-600 pr-2">
                        Allergies:&nbsp;
                      </span>
                      {/* %Allergies% */}
                    </p>
                    <p className="py-1">
                      <span className="font-bold text-gray-600 pr-2">
                        Order Date:&nbsp;
                      </span>
                      {moment(result.resource.authoredOn).format("MM/DD/YYYY")}
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-xl font-semibold pt-2">
                    {result.resource.code.text}
                  </h3>
                  {/* <p className="py-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Diagnosis:&nbsp;
                    </span>
                  </p> */}
                  <p className="py-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Reason for Exam:&nbsp;
                    </span>
                    {result.resource.reasonCode
                      ? result.resource.reasonCode[0].text
                      : ""}
                  </p>
                </div>
                <div className="flex justify-between items-start">
                  {/* <p className="py-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Priority:&nbsp;
                    </span>
                  </p> */}
                  {/* <p className="py-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Expiration Date:&nbsp;
                    </span>
                  </p> */}
                  {/* <p className="py-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Interval:&nbsp;
                    </span>
                  </p> */}
                  {/* <p className="py-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Count:&nbsp;
                    </span>
                  </p> */}
                </div>
                <div>
                  {/* <p className="py-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Does this patient require anesthesia or sedation?:&nbsp;
                    </span>
                    {"%Data%"}
                  </p> */}
                  {/* <p className="py-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Relase to patient?:&nbsp;
                    </span>
                    {"%Data%"}
                  </p> */}
                </div>
                <div>
                  {/* <p className="py-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Ordered By:&nbsp;
                    </span>
                    {"%Data%"}
                  </p> */}
                  <p className="py-1">
                    <span className="font-bold text-gray-600 pr-2">
                      Electronically Signed By:&nbsp;
                    </span>
                    {result.resource.requester.display}
                  </p>
                  <div className="w-full flex justify-end">
                    <Button
                      type="button"
                      icon="download"
                      primary
                      onClick={async () => {
                        const doc = <OrderDocument />;
                        const asPdf = pdf(doc); // {} is important, throws without an argument
                        asPdf.updateContainer(doc);
                        const blob = await asPdf.toBlob();
                        saveAs(blob, `order-${result.resource.authoredOn}.pdf`);
                      }}
                    >
                      Download PDF
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
