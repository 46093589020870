import { Route, RouteProps } from "react-router-dom";

import { Header } from "src/components/shared/header";
import React from "react";
import fifthImg from "./images/5.png";
import firstImg from "./images/1.png";
import fourthImg from "./images/4.png";
import styles from "./LayoutSignIn.module.scss";
import thirdImg from "./images/3.png";

interface Props extends RouteProps {
  component: React.ComponentType;
  image: string;
}

export function LayoutSignIn(props: Props) {
  const { component: Component, image, ...rest } = props;
  let imageComponent: any;

  switch (image) {
    case "one":
      imageComponent = (
        <img alt="workers" className={styles.coverAsset} src={firstImg} />
      );
      break;

    case "two":
      imageComponent = (
        <img alt="worker" className={styles.coverAsset} src={firstImg} />
      );
      break;

    case "three":
      imageComponent = (
        <img alt="worker" className={styles.coverAsset} src={thirdImg} />
      );
      break;

    case "four":
      imageComponent = (
        <img alt="worker" className={styles.coverAsset} src={fourthImg} />
      );
      break;

    case "five":
      imageComponent = (
        <img alt="lab" className={styles.coverAsset} src={fifthImg} />
      );
      break;

    default:
      imageComponent = (
        <img alt="workers" className={styles.coverAsset} src={firstImg} />
      );
      break;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <Header />
          <div className={"l-container"}>
            <div className={styles.root}>
              <div className={styles.left}>
                <Component {...rest} />
              </div>
              <div className={styles.right}>
                <div className={styles.rightInner}>{imageComponent}</div>
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
}

LayoutSignIn.defaultProps = {
  image: "one",
};
