import { ORGANIZATION_REQUEST_AUTOCOMPLETE } from "src/actions/organization";
import { USER_REQUEST_AUTOCOMPLETE } from "src/actions/user";

const defaultState = {
  organizations: [],
  users: [],
};

const autocomplete = (state = defaultState, action: any) => {
  switch (action.type) {
    case ORGANIZATION_REQUEST_AUTOCOMPLETE:
      if (action.response && action.response.data) {
        return {
          ...state,
          organizations: action.response.data,
        };
      }
      return state;

    case USER_REQUEST_AUTOCOMPLETE:
      if (action.response && action.response.data) {
        return {
          ...state,
          users: action.response.data,
        };
      }
      return state;

    default:
      return state;
  }
};

export default autocomplete;
