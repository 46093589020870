// @todo: Handle multiple instances.
import React from "react";
import { TabProps } from "./Tab";

export interface TabsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement<TabProps>[];
}

export function Tabs({ children }: TabsProps) {
  return (
    <div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {children}
          </nav>
        </div>
      </div>
    </div>
  );
}
