import { EncounterRow, EncounterRowProps } from "./EncounterRow";
import { MouseEvent, useEffect, useState } from "react";
import { Table, TableSkeleton } from "src/components/shared/table";

import { Pager } from "src/components/shared/pager";
import { RootState } from "src/reducers";
import { getRecentEncounters } from "src/actions/encounters";
import { useAxios } from "src/hooks/useAxios";
import { useSelector } from "react-redux";

interface Props {
  type: string;
  searchResultsLoading: boolean;
}

export function RecentResults({ type, searchResultsLoading }: Props) {
  const [dispatchAxios, { loading }] = useAxios();
  const [currentActiveEncounter, setCurrentActiveEncounter] = useState("");
  const [currentSort, setCurrentSort] = useState("name");
  const [currentDirection, setCurrentDirection] = useState("ASC");
  const {
    total,
    pages,
    currentPage,
    searchInput,
    from,
    to,
    orderBy,
    direction,
    encounters,
  } = useSelector((root: RootState) => root.encounterResults);

  function fetchRecentEncounters(
    search: string,
    from: string,
    to: string,
    order: string,
    sort: string,
    page: number
  ) {
    let adjustedSearchType = type;
    if (type === "corporate") {
      adjustedSearchType = "corporate,employee clinic";
    }
    dispatchAxios(
      getRecentEncounters(search, from, to, order, sort, page, adjustedSearchType)
    );
  }

  function handleSort(column: string) {
    const newDirection = direction === "ASC" ? "DESC" : "ASC";
    setCurrentSort(column);
    setCurrentDirection(newDirection);
    fetchRecentEncounters(searchInput, from, to, column, newDirection, currentPage);
  }

  function handleDrawerOpen(encounter: EncounterRowProps) {
    const encounterId = encounter.id + "-" + encounter.visitType;
    if (!loading) {
      setCurrentActiveEncounter(encounterId);
    }
  }

  useEffect(() => {
    fetchRecentEncounters(searchInput, from, to, orderBy, direction, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      {loading || searchResultsLoading ? (
        <TableSkeleton rows={5} cells={5} />
      ) : (
        <>
          <>
            {!loading && !total && (
              <div className="mt-5 mb-5 text-lg">No results found</div>
            )}
            {!loading && total > 0 && (
              <>
                <Table
                  headers={[
                    {
                      name: "Employee Name",
                      direction:
                        currentSort === "name" ? currentDirection : "DESC",
                      active: currentSort === "name",
                      onClick: () => handleSort("name"),
                    },
                    {
                      name: "DOB",
                      direction:
                        currentSort === "patientBirthDate" ? currentDirection : "DESC",
                      active: currentSort === "patientBirthDate",
                      onClick: () => handleSort("patientBirthDate"),
                    },
                    {
                      name: "Visit Type",
                      direction:
                        currentSort === "visitType" ? currentDirection : "DESC",
                      active: currentSort === "visitType",
                      onClick: () => handleSort("visitType"),
                    },
                    {
                      name: "Location",
                      direction:
                        currentSort === "location" ? currentDirection : "DESC",
                      active: currentSort === "location",
                      onClick: () => handleSort("location"),
                    },
                    {
                      name: "Date and Time",
                      direction:
                        currentSort === "appointmentDateTime"
                          ? currentDirection
                          : "DESC",
                      active: currentSort === "appointmentDateTime",
                      onClick: () => handleSort("appointmentDateTime"),
                    },
                    {
                      name: "Status",
                      direction:
                        currentSort === "status" ? currentDirection : "DESC",
                      active: currentSort === "status",
                      onClick: () => handleSort("status"),
                    },
                  ]}
                >
                  {encounters.map(
                    (encounter: EncounterRowProps, index: number) => (
                      <EncounterRow
                        {...encounter}
                        key={`encounter-row-${index}`}
                        isOpen={
                          currentActiveEncounter ===
                          encounter.id + "-" + encounter.visitType
                        }
                        handleOpen={() => handleDrawerOpen(encounter)}
                        handleClose={(e: MouseEvent) => {
                          e.stopPropagation();
                          setCurrentActiveEncounter("");
                        }}
                      />
                    )
                  )}
                </Table>
                <Pager
                  total={total}
                  pages={pages}
                  currentPage={currentPage}
                  getPage={(page: number) => {
                    return (e: MouseEvent) => {
                      e.preventDefault();
                      fetchRecentEncounters(
                        searchInput,
                        from,
                        to,
                        orderBy,
                        direction,
                        page
                      );
                    };
                  }}
                />
              </>
            )}
          </>
        </>
      )}
    </>
  );
}

RecentResults.defaultProps = {
  visitType: "all",
};
