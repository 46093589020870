import * as Yup from "yup";

import { BaseForm, styles } from "./BaseForm";
import { ErrorMessage, Field } from "formik";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { authStore, login } from "src/authentication";

import { ErrorMessage as CustomErrorMessage } from "src/components/shared/forms/error-message";
import { Routes } from "src/helpers/routes";
import { TailwindField } from "src/components/shared/forms/tailwind-field";
import { useState } from "react";
import { useAxios } from "src/hooks/useAxios";
import { getCurrentUser } from "src/actions/user";
import { IAxiosReturn } from "src/interfaces/axios";
import { redirect } from "src/actions/common";
import { useDispatch } from "react-redux";

export function SignInForm() {
  const authState = useContext(authStore);
  const [loginFailCount, setLoginFailCount] = useState<number>(0);
  const [error, setError] = useState<string | true | undefined>(undefined);
  const [dispatchAxios, { loading }] = useAxios();
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (loginFailCount > 4) {
      setError("Having trouble logging in? Please reset your password.");
    }
  }, [loginFailCount]);

  const handleSubmit = async (values: any) => {
    setError(undefined);
    const response = await login(authState, values.email, values.password);

    const errorType = response.status;
    let errorMsg = "";

    if (errorType === 500) {
      errorMsg = "Invalid Username / Password Combination";
      setLoginFailCount(prevstate => prevstate + 1);
      setError(errorMsg);
    } else if (errorType === 400) {
      setLoginFailCount(prevstate => prevstate + 1);
      errorMsg = "Invalid Username / Password Combination";
      setError(errorMsg);
    }
    return;
  };

  // Redirect after login or if already logged in.
  useEffect(() => {
    const token = authState.state.session.access_token;
    if (loading || typeof token === "undefined") {
      return;
    }

    // Redirect based on user role.
    dispatchAxios(getCurrentUser()).then((status: IAxiosReturn) => {
      let dashboardRoute = Routes.root;
      if (status.success && status.response.data?.role === "ROLE_SUPER_ADMIN") {
        dashboardRoute = Routes.adminPortal;
      }

      dispatch(redirect(dashboardRoute));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.state.session, dispatch]);

  return (
    <BaseForm
      schema={schema}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      buttonText="Login"
      title="Sign in to your account"
    >
      <div className={`${styles.inputWrapper} pt-4`}>
        <Field
          label="Password"
          id="password"
          name="password"
          type="password"
          component={TailwindField}
        />
        <ErrorMessage name="password" component={CustomErrorMessage} />
      </div>

      <div
        className={`${styles.actions} grid grid-cols-1 pt-3 lg:grid-cols-2 gap-4`}
      >
        <label className={styles.checkboxWrapper}>
          <Field
            name="rememberMe"
            type="checkbox"
            label="Remember me"
            className={styles.checkbox}
          />
          Remember me
        </label>
        <Link
          to={Routes.resetPassword}
          className={`${styles.forgotPasswordLink} lg:ml-auto`}
        >
          Forgot your password?
        </Link>
      </div>

      <div
        className={
          styles.root +
          ` ${
            error
              ? "text-red-700 h-21 md:h-16 transition-all ease-in-out duration-1000 opacity-100"
              : "h-0 transition-all ease-in-out duration-1000 opacity-0"
          }`
        }
        role="alert"
      >
        <strong className="font-bold">{error}</strong>
      </div>
    </BaseForm>
  );
}
