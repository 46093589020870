import React from "react";
import styles from "./PageLoader.module.scss";

export interface PageLoaderProps {
  createLoading?: boolean;
  uploadLoading?: boolean;
}

export function AppointmentPageLoader({
  createLoading,
  uploadLoading,
}: PageLoaderProps) {
  return (
    <div
      className={
        "absolute top-0 left-0 right-0 bottom-0 w-full h-100 z-50 overflow-hidden bg-gray-700 flex flex-col items-center t justify-center transition-all duration-250 opacity-0" +
        (createLoading || uploadLoading
          ? "block opacity-75 "
          : " opacity-0 hidden")
      }
    >
      <div
        className={
          "ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4 " +
          styles.loader
        }
      ></div>
      <h2 className="text-center text-white text-xl font-semibold">
        {createLoading
          ? "Creating Appointment..."
          : uploadLoading
          ? "Uploading Form..."
          : "Loading..."}
      </h2>
      <p className="w-1/3 text-center text-white">
        This may take a few seconds, please don't close this page.
      </p>
    </div>
  );
}
