import { Skeleton } from "src/components/shared/skeleton";
import styles from "./Profile.module.scss";
import { useCurrentUser } from "src/hooks/useCurrentUser";

export function ProfileDetails() {
  const [currentUser] = useCurrentUser(true);

  const firstName = currentUser.firstName;
  const lastName = currentUser.lastName;
  const phone = currentUser.phone;
  const email = currentUser.email;
  const jobTitle = currentUser.jobTitle;
  const encounterSubscrSetting = currentUser.encounterSubscrSetting;

  return (
    <div className={`grid grid-cols-2 gap-4 ${styles.profileWrapper}`}>
      <div className="pb-6">
        <label
          htmlFor="name"
          className="font-semibold text-gray-700 block pb-1"
        >
          First Name
        </label>
        <div className="flex">
          {currentUser.fullName ? (
            <input
              disabled
              id="firstName"
              className="border-1  rounded-r px-4 py-2 w-full"
              type="text"
              value={`${firstName}`}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="pb-6">
        <label
          htmlFor="name"
          className="font-semibold text-gray-700 block pb-1"
        >
          Last Name
        </label>
        <div className="flex">
          {currentUser.fullName ? (
            <input
              disabled
              id="lastName"
              className="border-1  rounded-r px-4 py-2 w-full"
              type="text"
              value={`${lastName}`}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="pb-6">
        <label
          htmlFor="name"
          className="font-semibold text-gray-700 block pb-1"
        >
          Email
        </label>
        <div className="flex">
          {currentUser.fullName ? (
            <input
              disabled
              id="email"
              className="border-1  rounded-r px-4 py-2 w-full"
              type="text"
              value={`${email}`}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="pb-6">
        <label
          htmlFor="name"
          className="font-semibold text-gray-700 block pb-1"
        >
          Phone
        </label>
        <div className="flex">
          {currentUser.fullName ? (
            <input
              disabled
              id="phone"
              className="border-1  rounded-r px-4 py-2 w-full"
              type="text"
              value={`${phone}`}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="pb-6">
        <label
          htmlFor="name"
          className="font-semibold text-gray-700 block pb-1"
        >
          Job Title
        </label>
        <div className="flex">
          {currentUser.fullName ? (
            <input
              disabled
              id="phone"
              className="border-1  rounded-r px-4 py-2 w-full"
              type="text"
              value={`${jobTitle}`}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="pb-6">
        <label
          className="font-semibold text-gray-700 block pb-1"
        >
          Encounter Subscription
        </label>
        <div className={`flex ${styles.rblHorizontal}`}>
          <label>
          {
            <input
              disabled
              type="radio"
              checked={encounterSubscrSetting === 'all'}
            />
          }
          All
          </label>
          <label>
          {
            <input
              disabled
              type="radio"
              checked={encounterSubscrSetting === 'none'}
            />
          }
          None
          </label>
        </div>
      </div>
    </div>
  );
}
