import React from 'react';

export interface BreadcrumbProps extends React.HTMLAttributes<HTMLDivElement> {
  isFirst: boolean;
  children: React.ReactElement;
}

export function Breadcrumb({ isFirst, children }: BreadcrumbProps) {
  let wrapperClass = 'flex items-center';
  let className = 'ml-4 text-sm font-medium text-gray-500 hover:text-gray-700';

  if (isFirst) {
    wrapperClass = '';
    className = 'text-sm font-medium text-gray-400 hover:text-gray-500';
  }

  const link = React.cloneElement(children, { className });

  return (
    <li>
      <div className={wrapperClass}>
        {!isFirst &&
          <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        }
        {link}
      </div>
    </li>
  );
}

Breadcrumb.defaultProps = {
  isFirst: false
}
