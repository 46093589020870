import { ReactComponent as CheckboxIcon } from "./images/checkbox.svg";
import { ReactComponent as ErrorIcon } from "./images/error.svg";
import styles from "./StatusMessage.module.scss";

enum ETypes {
  success = "success",
  error = "error",
}

interface IMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  type: ETypes | keyof typeof ETypes;
  children: React.ReactElement<any>[] | React.ReactElement<any> | string;
}

export function StatusMessage(props: IMessageProps) {
  const { type, children } = props;
  const rootClass: Array<string> = [styles.root];

  // TODO: Should probably turn this into an iconfont.
  let iconComponent;
  switch (type) {
    case ETypes.success:
      rootClass.push(styles.success);
      iconComponent = <CheckboxIcon className={styles.icon} />;
      break;

    case ETypes.error:
      rootClass.push(styles.error);
      iconComponent = <ErrorIcon className={styles.icon} />;
      break;
  }

  return (
    <div className={rootClass.join(" ")}>
      {iconComponent}
      {children}
    </div>
  );
}

StatusMessage.defaultProps = {
  type: ETypes.success,
};
