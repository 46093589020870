import { MouseEvent, useEffect, useState } from "react";

import { Drawer } from "src/components/shared/drawer";
import { IEncounter } from "src/interfaces/encounter";
import { Referrals } from "./Referrals";
import { RootState } from "src/reducers";
import { getReferrals } from "src/actions/referrals";
import { useAxios } from "src/hooks/useAxios";
import { useSelector } from "react-redux";

interface Props extends IEncounter {
  title: string;
  patientId: string;
  handleClose(e: MouseEvent): any;
}

export function ReferralsDrawer(props: Props) {
  const [open, setOpen] = useState(false);

  const { title, patientId, handleClose } = props;

  const [dispatchAxios, { loading }] = useAxios();
  const { referrals } = useSelector((root: RootState) => root.referralResults);

  useEffect(() => {
    dispatchAxios(getReferrals(patientId));
    setTimeout(() => {
      setOpen(true);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      heading={`${title}`}
      handleClose={handleClose}
      isOpen={open}
      isPrimary={false}
      className={"bg-gray-50"}
    >
      {loading && <p>loading referrals...</p>}
      {!loading && (!referrals || !referrals.length) && (
        <p>No referrals found.</p>
      )}
      {!loading && referrals && referrals.length > 0 && (
        <Referrals {...props} results={referrals} />
      )}
    </Drawer>
  );
}
