import { Skeleton } from "src/components/shared/skeleton";

export interface IconStatProps {
  heading: string;
  stat: number | string;
  change: number;
  loading?: boolean;
}

export function IconStat({ heading, stat, change, loading }: IconStatProps) {
  if (isNaN(change)) {
    change = 0;
  }

  let direction: string = "";
  if (change < 0) {
    direction = "down";
  } else if (change > 0) {
    direction = "up";
  } else {
    direction = "even";
  }

  return (
    <div className="px-4 py-5 sm:p-6">
      <dt>
        <div className="absolute bg-blue-500 rounded-md p-3">
          <svg
            className="h-6 w-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        </div>
        <p className="ml-16 text-sm font-medium text-gray-500">{heading}</p>
      </dt>

      <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
        {typeof stat === "number" ? (
          <p className="flex items-baseline text-2xl font-semibold text-blue-600">
            {stat}
          </p>
        ) : (
          <Skeleton />
        )}

        {!loading && direction === "up" && (
          <p className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium text-green-600 md:mt-2 lg:mt-0">
            <svg
              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Increased by</span>
            {change}%
          </p>
        )}

        {!loading && direction === "down" && (
          <p className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium text-red-600 md:mt-2 lg:mt-0">
            <svg
              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Decreased by</span>
            {change}%
          </p>
        )}
        {!loading && direction === "even" && (
          <p className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium text-gray-500 md:mt-2 lg:mt-0">
            {change}%
          </p>
        )}
      </dd>
    </div>
  );
}

IconStat.defaultProps = {
  heading: "",
  stat: "",
  change: "",
  loading: false,
};
