import { Tab, Tabs } from "src/components/shared/tabs";
import { Tile, Tiles } from "src/components/shared/tiles";

import { AppointmentAnalytics } from "./elements/AppointmentAnalytics";
import { Button } from "src/components/shared/button";
import { EmployeeAnalytics } from "./elements/EmployeeAnalytics";
import { IAnalytics } from "src/interfaces/analytics";
import { Link } from "react-router-dom";
import { RecentResults } from "./elements/RecentResults";
import { RootState } from "src/reducers";
import { Routes } from "src/helpers/routes";
import { SearchInput } from "src/components/shared/search-input";
import { EncounterDatePicker } from "src/components/shared/encounter-date-picker";
import { Skeleton } from "src/components/shared/skeleton";
import baseStyles from "../admin-portal/AdminPortal.module.scss";
import { getOrganizationAnalytics } from "src/actions/organization";
import { getRecentEncounters } from "src/actions/encounters";
import moment from "moment";
import { useAxios } from "src/hooks/useAxios";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";

export function Dashboard() {
  const [activeTab, setActiveTab] = useState("all");
  const [currentUser] = useCurrentUser();
  const [dispatchEncounters, { loading: encountersLoading }] = useAxios();
  const [dispatchAnalytics, { loading: analyticsLoading }] = useAxios();
  const organization = useSelector(
    (root: RootState) => root.user.currentUser.activeOrg
  );
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [currentDateRangeFrom, setCurrentDateRangeFrom] = useState("");
  const [currentDateRangeTo, setCurrentDateRangeTo] = useState("");
  const analytics = useSelector(
    (root: RootState) => root.organization.analytics
  );
  const { isRefreshing } = useSelector((root: RootState) => root.user);

  const result = analytics || {};
  const drugScreens: IAnalytics = result.drug_screens || {};
  const newAppointments: IAnalytics = result.new_appointments || {};
  const newEmployees: IAnalytics = result.new_employees || {};
  const workersComp: IAnalytics = result.workers_comp || {};

  const appointmentAnalytics = {
    drugScreens,
    newAppointments,
    workersComp,
  };

  const employeeAnalytics = {
    newEmployees,
  };
  //set active tab once on load
  useEffect(() => {
    if (typeof currentUser !== "undefined" && currentUser.accessLevel) {
      currentUser.accessLevel.length === 3 ||
      currentUser.role === "ROLE_SUPER_ADMIN"
        ? setActiveTab("all")
        : currentUser.accessLevel.length === 2
        ? setActiveTab("corporate,employee clinic")
        : setActiveTab("work comp");
    }
  }, [currentUser]);

  const allowedEncounters = currentUser.accessLevel;
  const isSuperAdmin = currentUser.role === "ROLE_SUPER_ADMIN";

  function greeting() {
    const hour = moment().hour();
    return hour > 16 ? "Evening" : hour > 11 ? "Afternoon" : "Morning";
  }

  // Fetch once on render or when active org changes.
  useEffect(() => {
    if (
      typeof organization !== "undefined" &&
      (!analyticsLoading || isRefreshing)
    ) {
      dispatchAnalytics(getOrganizationAnalytics(organization.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, isRefreshing]);

  function handleSearch(query: string, dateRangeFrom: string, dateRangeTo: string) {
    dispatchEncounters(
      getRecentEncounters(query, dateRangeFrom, dateRangeTo, "status", "ASC", 1, activeTab)
    );
  }

  // Add delay to debounce search and prevent requests on every keystroke
  useEffect(() => {
    if (currentUser.activeOrg || isRefreshing) {
      const timeoutId = setTimeout(() => handleSearch(currentSearchQuery, currentDateRangeFrom, currentDateRangeTo), 750);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchQuery, currentDateRangeFrom, currentDateRangeTo, isRefreshing]);

  return (
    <>
      <div className={baseStyles.root}>
        <header className={baseStyles.header}>
          <div className="grid grid-cols-7 w-full items-center">
            <div className="text-2xl leading-7 font-bold text-gray-900 col-span-3">
              {currentUser.fullName ? (
                <>
                  Good {greeting()}, {currentUser.fullName}
                </>
              ) : (
                <Skeleton />
              )}
            </div>
            <div className={`${baseStyles.ctaLink} col-span-4`}>
              <Link to={Routes.appointments}>
                <Button icon="configure" primary>
                  Create Appointment
                </Button>
              </Link>
            </div>
          </div>
        </header>
        <div className={baseStyles.contentWrapper}>
          <div className={baseStyles.tableIntro}>
            <h2 className={baseStyles.headingSmall}>Dashboard Activities</h2>
            <p className={baseStyles.paraLight}>
              The dashboard lists all of the visits for your organization.
            </p>
            <SearchInput
              onChange={(e) => {
                setCurrentSearchQuery(e.target.value);
              }}
            />
            From
            <EncounterDatePicker
              onChange={(e) => {
                setCurrentDateRangeFrom(e.target.value);
              }}
            />
            To
            <EncounterDatePicker
              onChange={(e) => {
                setCurrentDateRangeTo(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="mt-3">
          <RecentResults
            type={activeTab}
            searchResultsLoading={encountersLoading || isRefreshing}
          />
        </div>
        <Tiles>
          <Tile>
            <AppointmentAnalytics
              analytics={appointmentAnalytics}
              loading={analyticsLoading || isRefreshing}
            />
          </Tile>
          <Tile>
            <EmployeeAnalytics
              analytics={employeeAnalytics}
              loading={analyticsLoading || isRefreshing}
            />
          </Tile>
        </Tiles>
      </div>
    </>
  );
}
