import React, { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { Routes } from "src/helpers/routes";
import { SidebarMenu } from "src/components/shared/menus";
import { UserHeader } from "src/components/shared/header";
import { authStore } from "src/authentication";
import styles from "./LayoutDashboard.module.scss";

interface Props extends RouteProps {
  component: React.ComponentType;
}

export function LayoutDashboard(props: Props) {
  const authState = useContext(authStore);
  const access_token = authState.state.session.access_token;
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        access_token ? (
          <>
            <div className={"l-container"}>
              <div
                className={`${styles.root} grid grid-cols-10 2xl:grid-cols-7`}
              >
                <div
                  className={`${styles.left} col-span-3 lg:col-span-2 2xl:col-span-1`}
                >
                  <div className={styles.innerLeft}>
                    <SidebarMenu />
                  </div>
                </div>
                <div
                  className={`${styles.right} col-span-7 lg:col-span-8 2xl:col-span-6`}
                >
                  <UserHeader />
                  <div className={styles.innerRight}>
                    <Component {...rest} />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Redirect to={{ pathname: Routes.signIn }} />
        )
      }
    />
  );
}
