import {
  DEPARTMENTS_REQUEST_GET_ALL
} from 'src/actions/departments';

const defaultState = {
  departments: []
};

const departments = (state = defaultState, action: any) => {
  switch (action.type) {
    case DEPARTMENTS_REQUEST_GET_ALL:
      if (action.response && action.response.data) {
        return {
          ...state,
          departments: action.response.data || []
        }
      }
      return state;

    default:
      return state;
  }
};

export default departments;
