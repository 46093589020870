import { TableCell, TableRow } from "src/components/shared/table";

import { EncounterDrawer } from "./EncounterDrawer";
import { IEncounter } from "src/interfaces/encounter";
import { MouseEvent } from "react";
import moment from "moment";

export interface EncounterRowProps extends IEncounter {
  isEven: boolean;
  isOpen: boolean;
  handleOpen(): any;
  handleClose(e: MouseEvent): any;
}

export function EncounterRow(props: EncounterRowProps) {
  const {
    isEven,
    patientFirstName,
    patientLastName,
    patientBirthDate,
    visitType,
    location,
    appointmentDateTime,
    status,
    isOpen,
    handleOpen,
    handleClose,
  } = props;

  const patientName = patientFirstName + " " + patientLastName;
  const formatedDate = moment(appointmentDateTime)
    .local()
    .format("MMMM DD YYYY (h:mm A)");

  const patientBirthDateFormatted = moment(patientBirthDate)
    .local()
    .format("MM/DD/YYYY");

  return (
    <TableRow isEven={isEven} onClick={handleOpen}>
      <TableCell className="px-6">{patientName}</TableCell>
      <TableCell className="px-6">{patientBirthDateFormatted}</TableCell>
      <TableCell className="px-6">{visitType}</TableCell>
      <TableCell className="px-6">{location}</TableCell>
      <TableCell className="px-6">{formatedDate}</TableCell>
      <TableCell>
        {status}
        <EncounterDrawer {...props} handleClose={handleClose} isOpen={isOpen} />
      </TableCell>
    </TableRow>
  );
}

EncounterRow.defaultProps = {
  isEven: false,
};
