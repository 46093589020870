export const REDIRECT = "common/redirect";
export const REDIRECT_TO = "common/redirectTo";

export function redirect(path: string, preserveQuery: boolean = false) {
  return {
    type: REDIRECT_TO,
    payload: {
      path,
      preserveQuery,
    },
  };
}

export function redirected() {
  return {
    type: REDIRECT,
    payload: null,
  };
}
