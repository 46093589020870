import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/reducers';
import { useAxios } from 'src/hooks/useAxios';
import { getDepartments } from 'src/actions/departments';
import { IDepartment } from 'src/interfaces/department';

export function useDepartments(list: boolean = false) {
  const [dispatchAxios, { loading }] = useAxios();
  const { departments } = useSelector((root: RootState) => root.departments);

  useEffect(() => {
    if (!departments.length && !loading) {
      dispatchAxios(getDepartments());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (list) {
    const departmentList = departments.map((department: IDepartment) => ({
      value: department.id,
      label: department.external
    }));

    return [departmentList, loading];
  }

  return [departments, loading]
}
