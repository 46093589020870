import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import FontBoldRoboto from "../../../../src/assets/fonts/TypoPRO-Roboto-Bold.ttf";
import FontMedRoboto from "../../../../src/assets/fonts/TypoPRO-Roboto-Medium.ttf";
import FontRegularRoboto from "../../../../src/assets/fonts/TypoPRO-Roboto-Regular.ttf";
import { IEncounter } from "src/interfaces/encounter";
import Logo from "./images/centracare.png";
import moment from "moment";

interface ReferralProps extends IEncounter {
  referral: any;
}
export function LayoutReferralPdf(props: ReferralProps) {
  const {
    referral,
    patientFirstName,
    patientLastName,
    locationAddress,
    locationCity,
    patientPhoneNumber,
    locationState,
    locationZipCode,
    locationPhoneNumber,
    patientMrn,
    patientSSN,
    patientSex,
    patientBirthDate,
  } = props;

  const styles = StyleSheet.create({
    page: {
      height: "100%",
      width: "100%",
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      paddingBottom: 20,
      position: "relative",
    },
    body: { alignContent: "space-between", display: "flex" },
    column: { display: "flex", flexDirection: "column" },
    image: {
      display: "flex",
      width: 100,
      marginRight: "auto",
      paddingBottom: 20,
    },
    headers: {
      marginTop: 22,
      fontSize: 22,
      marginBottom: "8pt",
      fontFamily: "Roboto",
      fontWeight: 600,
    },
    subHeaders: {
      fontSize: 16,
      paddingBottom: "4pt",
      paddingTop: "8pt",
      fontFamily: "Roboto",
      fontWeight: 500,
    },
    item: {
      paddingBottom: "4pt",
      paddingTop: "4pt",
      fontSize: 12,
      fontFamily: "Roboto",
    },
    label: { fontWeight: 600, fontSize: 12, fontFamily: "Roboto" },
    subLabel: { fontWeight: 500, fontSize: 12, fontFamily: "Roboto" },

    row: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      paddingBottom: 16,
    },

    section: { borderBottom: 1, borderBottomColor: "#e4e4e7" },

    subText: {
      padding: 28,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      marginTop: "auto",
    },
    details: {
      padding: 12,
      border: 1,
      borderColor: "black",
    },
  });

  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: FontRegularRoboto,
      },
      {
        src: FontMedRoboto,
        fontWeight: 600,
      },
      {
        src: FontBoldRoboto,
        fontWeight: 500,
      },
    ],
  });

  const address = referral.PatientAddress[0];
  return (
    <Document>
      <Page wrap style={styles.page} size="A4">
        <View style={styles.body}>
          <View style={styles.row}>
            <View>
              <Image source={Logo} style={styles.image} />
              <Text style={styles.label}>{locationAddress}</Text>
              <Text
                style={styles.label}
              >{`${locationCity}, ${locationState}`}</Text>
              <Text style={styles.label}>{locationZipCode}</Text>
              <Text style={styles.label}>
                Phone: {`${locationPhoneNumber}`}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.label}>Date:&nbsp;</Text>
                <Text style={styles.item}>
                  {referral.AuthorizationStartDate}
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.row}>
              <Text style={styles.subHeaders}>
                {referral?.Procedures?.length
                  ? referral?.Procedures[0].Procedure
                  : ""}
              </Text>
            </View>

            <View style={styles.row}>
              <View style={styles.column}>
                <Text style={styles.item}>
                  <Text style={styles.label}>Legal Name:&nbsp;</Text>
                  {`${patientFirstName} ${patientLastName}`}
                </Text>
                <Text style={styles.item}>
                  <Text style={styles.label}>Address:&nbsp;</Text>
                  {`${address.Street} ${address.State.Abbreviation} ${address.Zip}`}
                </Text>
                <Text style={styles.item}>
                  <Text style={styles.label}> Phone:&nbsp;</Text>
                  {patientPhoneNumber}
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.item}>
                  <Text style={styles.label}>MRN:&nbsp;</Text>
                  {patientMrn}
                </Text>
                <Text style={styles.item}>
                  <Text style={styles.label}>DOB:&nbsp; </Text>
                  {moment(patientBirthDate).format("MM/DD/YYYY")}
                </Text>
                <Text style={styles.item}>
                  <Text style={styles.label}>SSN:&nbsp; </Text>
                  {patientSSN}
                </Text>
                <Text style={styles.item}>
                  <Text style={styles.label}>Sex:&nbsp; </Text>
                  {patientSex}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.row}>
              <Text style={styles.subHeaders}>
                Referring Provider Information
              </Text>
            </View>
            <View style={styles.row}>
              <View>
                <Text style={styles.item}>
                  <Text style={styles.label}>Name:&nbsp; </Text>
                  {referral.ReferredByProviderName}
                </Text>
              </View>
              <View style={styles.row}>
                {/* <Text style={styles.item}>
                  <Text style={styles.label}> Phone:&nbsp;</Text>
                  {"%Data%"}
                </Text> */}
              </View>
              <View>
                {/* <Text style={styles.item}>
                  <Text style={styles.label}>Fax:&nbsp;</Text>
                  {"%Data%"}
                </Text> */}
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.row}>
              <Text style={styles.subHeaders}>Referral Information</Text>
            </View>
            <View style={styles.row}>
              <View style={styles.column}>
                {/* <Text style={styles.item}>
                  <Text style={styles.label}># Vists:&nbsp;</Text>
                  {"%Data%"}
                </Text> */}

                {/* <Text style={styles.item}>
                  <Text style={styles.label}>Urgency:&nbsp;</Text>
                  {"%Data%"}
                </Text> */}

                <Text style={styles.item}>
                  <Text style={styles.label}>Start Date:&nbsp;</Text>
                  {referral.AuthorizationStartDate}
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.item}>
                  <Text style={styles.label}> Referral Type:&nbsp;</Text>
                  {referral.Type}
                </Text>

                <Text style={styles.item}>
                  <Text style={styles.label}>Referral Reason:&nbsp;</Text>
                  {/* {"%Reason%"} */}
                </Text>

                <Text style={styles.item}>
                  <Text style={styles.label}> End Date:&nbsp;</Text>
                  {referral.AuthorizationEndDate}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.row}>
              <Text style={styles.subHeaders}>
                <Text style={styles.subLabel}>
                  Diagnosis:&nbsp;{" "}
                  {referral.Diagnoses[0].Description +
                    " " +
                    referral.Diagnoses[0].Diagnosis}
                </Text>
              </Text>
            </View>
            <View style={styles.row}>
              <View style={styles.column}>
                {/* <Text style={styles.item}>
                  <Text style={styles.label}>Refer to Dept:&nbsp;</Text>
                  {"%Data%"}
                </Text> */}
                <Text style={styles.item}>
                  <Text style={styles.label}>Refer to Provider:&nbsp;</Text>
                  {referral.ReferredToProviderName}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.subText} fixed>
          <Text style={[styles.item]}>
            <Text style={styles.label}>Electronically Signed By:&nbsp;</Text>
            {referral.ReferredByProviderName}
          </Text>
          <Text style={[styles.item, styles.details]}>
            This document serves as a request of services and does not
            constitute Insurance authorization or approval of services. To
            determine eligibility, please contact the members Insurance carrier
            to verify and review coverage
          </Text>
        </View>
      </Page>
    </Document>
  );
}
