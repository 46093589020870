import * as Yup from "yup";
import { Link, Redirect } from "react-router-dom";
import { Field, Formik, ErrorMessage } from "formik";

import { Button } from "src/components/shared/button";
import { ErrorMessage as CustomErrorMessage } from "src/components/shared/forms/error-message";
import {
  passwordValidation,
  passwordConfirmValidation,
} from "src/helpers/yupRules";
import { Routes } from "src/helpers/routes";
import { StatusMessage } from "src/components/shared/status-message";
import { styles } from "./BaseForm";
import { useAxios } from "src/hooks/useAxios";
import { userOtpPasswordReset } from "src/actions/user";
import { useQuery } from "src/hooks/useQuery";

export function OtpPasswordForm() {
  const [dispatchAxios, { loading, success, error }] = useAxios();
  const query = useQuery();
  const uid = query.get("uid");
  const timestamp = query.get("timestamp");
  const token = query.get("token");

  if (!uid || !timestamp || !token) {
    return <Redirect to={Routes.resetPassword} />;
  }

  const schema = Yup.object().shape({
    password: passwordValidation(),
    passwordConfirm: passwordConfirmValidation("password"),
  });

  const initialValues = {
    password: "",
    passwordConfirm: "",
  };

  function handleSubmit(values: any) {
    if (values.password && values.passwordConfirm) {
      dispatchAxios(
        userOtpPasswordReset({
          uid,
          timestamp,
          token,
          password: values.password,
          passwordConfirm: values.passwordConfirm,
        })
      );
    }
  }

  let title = "Recover Password";
  let buttonText = "Confirm Password";
  let errorMessage = "";

  if (success) {
    title = "Password Recovered";
    buttonText = "";
  }

  if (error) {
    switch (error.response.status) {
      case 498:
        errorMessage =
          "This one-time password link has expired or had too many failed attempts.";
        break;

      default:
        errorMessage = "Something went wrong, please try again later.";
        break;
    }
  }

  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>{title}</h1>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-1">
              <div className={`${styles.recoverInfo} grid grid-cols-1 gap-6`}>
                <p className={styles.recoverText}>
                  Please enter a new password. Your password must follow all
                  criteria.
                </p>
              </div>
              <div className={styles.inputWrapper}>
                {success ? (
                  <>
                    <StatusMessage>Successfully changed password</StatusMessage>
                    <Link to={Routes.signIn}>
                      <Button primary fullWidth>
                        Back to Login
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    {errorMessage && (
                      <StatusMessage type="error">{errorMessage}</StatusMessage>
                    )}
                    <div className={`${styles.inputWrapper} pb-4`}>
                      <label htmlFor="code">New Password:</label>
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Enter Password"
                      />
                      <ErrorMessage
                        name="password"
                        className="relative bottom-0"
                        component={CustomErrorMessage}
                      />
                    </div>

                    <div className={`${styles.inputWrapper} mb-5`}>
                      <label htmlFor="code">Confirm Password:</label>
                      <Field
                        id="passwordConfirm"
                        name="passwordConfirm"
                        type="password"
                        placeholder="Re-enter Password"
                      />
                      <ErrorMessage
                        name="passwordConfirm"
                        className="relative bottom-0"
                        component={CustomErrorMessage}
                      />
                    </div>
                  </>
                )}
              </div>

              {buttonText && (
                <Button type="submit" primary fullWidth disabled={loading}>
                  {buttonText}
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
