import ReactPDF, {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import FontBoldRoboto from "../../../../src/assets/fonts/TypoPRO-Roboto-Bold.ttf";
import FontMedRoboto from "../../../../src/assets/fonts/TypoPRO-Roboto-Medium.ttf";
import FontRegularRoboto from "../../../../src/assets/fonts/TypoPRO-Roboto-Regular.ttf";
import Html from "react-pdf-html";
import { IEncounter } from "src/interfaces/encounter";
import Logo from "./images/centracare.png";
import moment from "moment";

interface resultProps extends IEncounter {
  result: any;
  signedBy: string;
}
export function LayoutResultPdf(props: resultProps) {
  const {
    result,
    signedBy,
    patientFirstName,
    patientLastName,
    patientBirthDate,
    locationAddress,
    locationCity,
    location,
    locationState,
    locationZipCode,
    locationPhoneNumber,
    appointmentDateTime,
  } = props;

  const patientDobFormatted = moment(patientBirthDate).format("MM/DD/YYYY");
  const appointmentDateTimeFormatted = moment(appointmentDateTime).format("MM/DD/YYYY");

  const stylesheet = {
    img: {
      width: 340,
      height: 340,
    },
  };

  // Check if note is html if so render as html

  const styles = StyleSheet.create({
    page: {
      height: "100%",
      width: "100%",
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      paddingBottom: 0, // bigger so fixed signature doesn't go over note content
      position: "relative",
    },
    even: { backgroundColor: "#f3f4f6" },
    odd: { backgroundColor: "#ffffff" },

    body: { alignContent: "space-between", display: "flex" },
    tableBody: {
      borderWidth: 0,
      //overflow: "hidden",
      //borderRadius: 8,
      //borderStyle: "solid",
      //borderColor: "#f3f4f6",
      display: "flex",
    },
    electronicSignature: {
      backgroundColor: "white",
      opacity: 0.5,
      zIndex: 100
    },
    bottomPage: {
      padding: 28,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      marginTop: "auto",
    },
    tableRowWrap: {
      //overflow: "hidden",
      //borderRadius: 8,
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    image: {
      display: "flex",
      width: 100,
    },
    headers: {
      marginTop: 22,
      fontSize: 22,
      marginBottom: "8pt",
      fontFamily: "Roboto",
      fontWeight: 600,
    },
    subHeaders: {
      fontSize: 16,
      paddingBottom: "4pt",
      paddingTop: "8pt",
      fontFamily: "Roboto",
      fontWeight: 500,
    },
    pb4: { paddingBottom: "16" },
    mtAuto: { marginTop: "40pt" },
    item: {
      paddingBottom: "4pt",
      paddingTop: "4pt",
      fontSize: 12,
      marginLeft: "auto",
      fontFamily: "Roboto",
    },
    label: { fontWeight: 600, fontSize: 12, fontFamily: "Roboto" },
    infoLabel: { fontSize: 12, fontFamily: "Roboto" },
    subLabel: { fontWeight: 500, fontSize: 12, fontFamily: "Roboto" },

    row: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "stretch",
      display: "flex",
    },

    section: { padding: 8 },

    subText: {
      fontSize: 12,
      fontFamily: "Roboto",
    },

    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColHeader: {
      width: "20%",
      borderStyle: "solid",
      padding: 8,
      display: "flex",
      justifyContent: "center",
    },
    tableCol: {
      width: "20%",
    },
    tableData: {
      width: "80%",
      padding: 8,
    },
  });

  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: FontRegularRoboto,
      },
      {
        src: FontMedRoboto,
        fontWeight: 600,
      },
      {
        src: FontBoldRoboto,
        fontWeight: 500,
      },
    ],
  });

  const table = {
    display: "table",
    width: "100%",
  };

  const tableRows = Object.keys(result)
    .filter(
      (label: string) =>
        label !== "Results" && label !== "Notes" && label !== "ID"
    )
    .map((label: string, index) => {
      const isEven = index % 2 === 0 ? styles.even : styles.odd;
      const isHtml = /<[a-z/][\s\S]*>/i.test(result[label]);

      if (!isHtml) {
        return (
          <View style={[styles.tableRow, isEven]}>
            <View style={styles.tableColHeader}>
              <Text style={styles.label}>{label}</Text>
            </View>
            <View style={styles.tableData}>
              <Text style={styles.item}>
                {label === "Date"
                  ? moment(result[label]).format("MM/DD/YYYY")
                  : result[label]}
              </Text>
            </View>
          </View>
        );
      } else {
        const html = result[label].replace(
          new RegExp("font-family:[^;']*(;)?", "g"),
          ""
        ).replace(
          new RegExp("&#176;", "g"), // Degrees
          "\u00b0"
        ).replace(
          new RegExp("&#178;", "g"), // Meters Squared
          "\u00b2"
        );

        return <Html stylesheet={stylesheet}>{html}</Html>;
      }
    });

  return (
    <Document>
      <Page wrap={true} style={styles.page} size="A4">
        <View wrap={true} style={styles.body}>
          <View style={[styles.section, styles.row, styles.pb4]}>
            <View style={styles.column}>
              <Image source={Logo} style={styles.image} />
              <View style={styles.mtAuto}>
                <Text
                  style={[styles.infoLabel]}
                >{`${patientFirstName} ${patientLastName}`} - DOB: {patientDobFormatted} - Date of Service: {appointmentDateTimeFormatted}</Text>
            </View>
            </View>
            <View style={styles.column}>
              <Text style={styles.infoLabel}>{location}</Text>
              <Text style={styles.infoLabel}>{locationAddress}</Text>
              <Text
                style={styles.infoLabel}
              >{`${locationCity}, ${locationState}`}</Text>
              <Text style={styles.infoLabel}>{locationZipCode}</Text>
              <Text style={styles.infoLabel}>
                Phone: {`${locationPhoneNumber}`}
              </Text>
            </View>
          </View>
          <View style={styles.tableBody}>
            <View style={table as ReactPDF.Styles}>
              <View style={styles.tableRowWrap}>{tableRows}</View>
            </View>
          </View>
        </View>
        <View style={[styles.row, styles.bottomPage]} fixed>
          <View style={styles.column}>
            <Text style={styles.item}>
              <Text style={[styles.label, styles.electronicSignature]}>
                {`Electronically Signed By: ${signedBy}`}
              </Text>
              {/* {order.resource.requester.display} */}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
