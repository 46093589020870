export interface PagerProps {
  total: number;
  pages: number;
  currentPage: number;
  getPage(page: number): any;
}

export function Pager({ total, pages, currentPage, getPage }: PagerProps) {
  // Create page links.
  const linkClasses =
    "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 py-4 px-4 inline-flex items-center text-sm font-medium";
  const linkClassesActive =
    "border-blue-500 text-blue-500 border-t-2 py-4 px-4 inline-flex items-center text-sm font-medium";
  const start = Math.max(1, currentPage - 2);
  const end = Math.min(start + 5, pages);
  const pageLinks: Array<React.ReactNode> = [];

  for (let i = start; i <= end; i++) {
    pageLinks.push(
      <button
        key={`pager-link-${i}`}
        onClick={getPage(i)}
        className={currentPage === i ? linkClassesActive : linkClasses}
      >
        {i}
      </button>
    );
  }

  return (
    <nav className="border-gray-200 px-4 flex items-center justify-between sm:px-0">
      <div className="mt-10 md:-mt-px w-0 flex-1 flex">
        {currentPage > 1 && (
          <button
            onClick={getPage(1)}
            className="border-t-2 border-transparent pt-4 pr-4 inline-flex items-center text-sm font-medium text-gray-500 text-gray-500 hover:text-blue-700"
          >
            <svg
              className="mr-1 h-5 w-5 "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            First
          </button>
        )}
        {currentPage > 1 && (
          <button
            onClick={getPage(currentPage - 1)}
            className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-blue-700"
          >
            Previous
          </button>
        )}
      </div>
      <div className=" -mt-px flex">{pageLinks}</div>
      <div className="mt-10 md:-mt-px w-0 flex-1 flex justify-end">
        {currentPage < pages && (
          <>
            <button
              onClick={getPage(currentPage + 1)}
              className="border-t-2 border-transparent pr-4 pt-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-blue-700"
            >
              Next
            </button>
            <button
              onClick={getPage(pages)}
              className="border-t-2 border-transparent pt-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-blue-700"
            >
              Last
              <svg
                className="ml-1 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </>
        )}
      </div>
    </nav>
  );
}
