import appointments from "src/reducers/appointments";
import autocomplete from "src/reducers/autocomplete";
import { combineReducers } from "redux";
import common from "src/reducers/common";
import departments from "src/reducers/departments";
import documents from "src/reducers/documents";
import employees from "src/reducers/employees";
import encounterResults from "src/reducers/encounter-results";
import orderResults from "./orderResults";
import organization from "src/reducers/organization";
import organizationUsers from "src/reducers/organization-users";
import referralResults from "src/reducers/referral-results";
import user from "src/reducers/user";

export const reducers = combineReducers({
  autocomplete,
  appointments,
  common,
  departments,
  documents,
  employees,
  encounterResults,
  organization,
  organizationUsers,
  referralResults,
  orderResults,
  user,
});

export type RootState = ReturnType<typeof reducers>;
