import { IAxiosAction } from "src/interfaces/axios";
import { Routes } from "src/helpers/routes";

const API_URL = process.env.REACT_APP_API_URL || "";

export const USER_REFRESHING = "user/refresh";
export const USER_REQUEST_GET = "user/get";
export const USER_REQUEST_AUTOCOMPLETE = "user/autocomplete";
export const USER_REQUEST_GET_CURRENT = "user/getCurrent";
export const USER_REQUEST_SET_ACTIVE_ORG = "user/setActiveOrg";
export const USER_REQUEST_UPDATE = "user/update";
export const USER_REQUEST_INVITE = "user/invite";
export const USER_REQUEST_INVITE_CHK = "user/inviteCheck";
export const USER_REQUEST_INVITE_ACCEPT = "user/inviteAccept";
export const USER_REQUEST_INVITE_RESEND = "user/inviteResend";
export const USER_REQUEST_ORG_ADD = "user/orgAdd";
export const USER_REQUEST_ORG_REMOVE = "user/orgRemove";
export const USER_REQUEST_OTP = "user/otp";
export const USER_REQUEST_OTP_CREATE = "user/otp/create";
export const USER_REQUEST_OTP_VALIDATE = "user/otp/validate";
export const USER_REQUEST_OTP_PASSWORD_RESET = "user/otp/password";
export const USER_REQUEST_PASSWORD_RESET = "user/password";

export function getCurrentUser(): IAxiosAction {
  return {
    type: USER_REQUEST_GET_CURRENT,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/user/me`,
    },
  };
}

export function getUserAutocomplete(name: string): IAxiosAction {
  return {
    type: USER_REQUEST_AUTOCOMPLETE,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/user/autocomplete`,
      params: {
        name,
      },
    },
  };
}

interface IUpdateUser {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jobTitle: string;
  encounterSubscrSetting: string;
  role: string;
  accessLevel: string[];
}
export function getUser(userId: string, data: IUpdateUser): IAxiosAction {
  return {
    type: USER_REQUEST_GET,
    pathParameters: {
      userId,
    },
    config: {
      method: "GET",
      url: `${API_URL}/user/${userId}`,
    },
  };
}

export function updateUser(userId: string, data: IUpdateUser): IAxiosAction {
  return {
    type: USER_REQUEST_UPDATE,
    pathParameters: {
      userId,
    },
    config: {
      method: "PUT",
      url: `${API_URL}/user/${userId}`,
      data,
    },
  };
}

interface IUserInvite {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  organization: string;
  role: string;
}
export function inviteUser(data: IUserInvite): IAxiosAction {
  return {
    type: USER_REQUEST_INVITE,
    pathParameters: {},
    config: {
      method: "POST",
      url: `${API_URL}/user/invite`,
      data,
    },
  };
}

export function userInvitePasswordReset(
  inviteId: string,
  email: string,
  password: string,
  passwordConfirm: string
): IAxiosAction {
  return {
    type: USER_REQUEST_OTP_PASSWORD_RESET,
    pathParameters: {},
    config: {
      method: "POST",
      url: `${API_URL}/user/invite/accept`,
      data: {
        inviteId,
        email,
        password,
        passwordConfirm,
      },
    },
  };
}

export function userInviteCheck(uuid: string): IAxiosAction {
  return {
    type: USER_REQUEST_INVITE_CHK,
    pathParameters: {},
    config: {
      method: "GET",
      url: `${API_URL}/user/invite/valid?id=${uuid}`,
    },
  };
}

export function userInviteResend({ id }: { id: string }): IAxiosAction {
  return {
    type: USER_REQUEST_INVITE_RESEND,
    pathParameters: {},
    config: {
      method: "POST",
      url: `${API_URL}/user/invite/resend`,
      data: { id },
    },
  };
}

export function userOtpCreate(email: string): IAxiosAction {
  return {
    type: USER_REQUEST_OTP_CREATE,
    pathParameters: {},
    config: {
      method: "POST",
      url: `${API_URL}/user/otp`,
      data: {
        email,
      },
    },
  };
}

export function userOtpValidate(
  uid: string,
  timestamp: string,
  token: string
): IAxiosAction {
  return {
    type: USER_REQUEST_OTP_VALIDATE,
    pathParameters: {},
    redirectOnSuccess: Routes.otpPassword,
    preserveQuery: true,
    config: {
      method: "POST",
      url: `${API_URL}/user/otp/validate`,
      data: {
        uid,
        timestamp,
        token,
      },
    },
  };
}

export function userOtpPasswordReset(data: {
  uid: string | null;
  timestamp: string | null;
  token: string | null;
  password?: string;
  passwordConfirm?: string;
}): IAxiosAction {
  return {
    type: USER_REQUEST_OTP_PASSWORD_RESET,
    pathParameters: {},
    config: {
      method: "POST",
      url: `${API_URL}/user/otp/password`,
      data,
    },
  };
}

export function userPasswordReset(
  userId: string,
  oldPassword: string,
  newPassword: string
): IAxiosAction {
  return {
    type: USER_REQUEST_PASSWORD_RESET,
    pathParameters: {
      userId,
    },
    config: {
      method: "PUT",
      url: `${API_URL}/user/${userId}/password`,
      data: {
        oldPassword,
        newPassword,
      },
    },
  };
}

export function userUpdate(
  userId: string,
  firstName: string,
  lastName: string,
  phone: string,
  jobTitle: string,
  encounterSubscrSetting: string
): IAxiosAction {
  return {
    type: USER_REQUEST_UPDATE,
    pathParameters: {
      userId,
    },
    config: {
      method: "PUT",
      url: `${API_URL}/user/${userId}`,
      data: {
        firstName,
        lastName,
        phone,
        jobTitle,
        encounterSubscrSetting,
      },
    },
  };
}

export function userOrgAdd(uid: string, organization: string): IAxiosAction {
  return {
    type: USER_REQUEST_ORG_ADD,
    pathParameters: {},
    config: {
      method: "PUT",
      url: `${API_URL}/user/${uid}/organization`,
      data: { organization },
    },
  };
}

export function userOrgRemove(userId: string, orgId: string): IAxiosAction {
  return {
    type: USER_REQUEST_ORG_REMOVE,
    pathParameters: {
      userId,
      orgId,
    },
    config: {
      method: "DELETE",
      url: `${API_URL}/user/${userId}/organization/${orgId}`,
    },
  };
}

export function userSetActiveOrg(
  userId: string,
  organization: string
): IAxiosAction {
  return {
    type: USER_REQUEST_SET_ACTIVE_ORG,
    pathParameters: {
      userId,
    },
    config: {
      method: "POST",
      url: `${API_URL}/user/${userId}/organization`,
      data: { organization },
    },
  };
}

export function userSetRefreshing(payload: boolean) {
  return {
    type: USER_REFRESHING,
    payload,
  };
}
