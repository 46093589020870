import { Table, TableCell, TableRow } from "src/components/shared/table";

import { Button } from "src/components/shared/button";
import { IEncounter } from "src/interfaces/encounter";
import { LayoutResultPdf } from "src/components/layouts/layout-result-pdf/LayoutResultPdf";
import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";

interface ResultProps extends IEncounter {
  heading: string;
  description?: string;
  result: any;
  htmlCell?: boolean;
  signedBy?: string;
}

export function buildResultTable(props: ResultProps) {
  const { heading, description, result, htmlCell, signedBy } = props;
  const id = result.ID ?? Math.random();

  const tableRows = Object.keys(result)
    .filter(
      (label) => label !== "Results" && label !== "Notes" && label !== "ID"
    )
    .map((label) => {
      return (
        <TableRow key={`${label}-${id}`}>
          {htmlCell ? (
            <>
              <TableCell className="p-4 col-span-full">
                <div dangerouslySetInnerHTML={{ __html: result[label] }}></div>
              </TableCell>
            </>
          ) : (
            <>
              <TableCell className="p-4">{label}</TableCell>
              <TableCell className="p-4">
                {label === "Date"
                  ? moment(result[label]).format("MM/DD/YYYY")
                  : result[label]}
              </TableCell>
            </>
          )}
        </TableRow>
      );
    });

  const ResultDocument = () => {
    return (
      <LayoutResultPdf
        {...props}
        result={result}
        signedBy={signedBy as string}
      />
    );
  };
  return (
    <div className="">
      <Table>
        {heading && (
          <TableRow key={`heading-${id}`}>
            <TableCell>
              <div className="mb-1 text-lg leading-6 font-medium text-gray-900 px-4">
                {heading}
              </div>
              {description && (
                <div className="text-sm leading-5 font-normal text-gray-500">
                  {description}
                </div>
              )}
            </TableCell>
          </TableRow>
        )}
        {tableRows}
        <>
          {signedBy && (
            <TableRow key={`signedBy`}>
              <TableCell className="p-4 col-span-full">
                {`Electronically Signed By: ${signedBy}`}
              </TableCell>
            </TableRow>
          )}
        </>
      </Table>
      <div key={`heading-download`} className={"w-full"}>
        <div className="flex justify-end	w-full p-4 w-full">
          <Button
            type="button"
            icon="download"
            primary
            onClick={async () => {
              const doc = <ResultDocument />;
              const asPdf = pdf(doc); // {} is important, throws without an argument
              asPdf.updateContainer(doc);
              const blob = await asPdf.toBlob();
              saveAs(blob, `${heading}.pdf`);
            }}
          >
            Download PDF
          </Button>
        </div>
      </div>
    </div>
  );
}

export function Result(props: ResultProps) {
  const { result, heading, description } = props;

  if (!result.ID) {
    return null;
  }

  const mainTable = buildResultTable({
    ...props,
    heading,
    result,
    description,
  });

  // Build child results table.
  const childResults = result.Results || {};
  const childResultsObject = Object.keys(childResults)
    .filter((label: string) => (childResults[label].Value ? 1 : 0))
    .reduce((accum: any, label: string) => {
      accum[label] = childResults[label].Value;
      return accum;
    }, {});

  let childResultsTable = null;
  if (Object.keys(childResultsObject).length) {
    childResultsTable = buildResultTable({
      ...props,
      heading: "Results",
      result: childResultsObject,
      signedBy: `${result.Author}`,
    });
  }

  // Build child notes table.
  const childNotes = result.Notes || {};
  const childNotesObject = Object.keys(childNotes)
    .filter((label: string) => (childNotes[label].Note ? 1 : 0))
    .reduce((accum: any, label: string) => {
      accum[label] = childNotes[label].Note;
      return accum;
    }, {});

  let childNotesTable = null;
  if (Object.keys(childNotesObject).length) {
    childNotesTable = buildResultTable({
      ...props,
      heading: "Notes",
      result: childNotesObject,
      htmlCell: true,
      signedBy: `${result.Author}`,
    });
  }

  return (
    <>
      <div className="mb-10">{mainTable}</div>
      <div className="mb-10">{childResultsTable}</div>
      <div className="mb-10">{childNotesTable}</div>
    </>
  );
}
