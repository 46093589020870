import {
  ENCOUNTERS_REQUEST_GET_RECENT,
  ENCOUNTERS_REQUEST_GET_RESULT
} from 'src/actions/encounters';

const defaultState = {
  total: 0,
  pages: 0,
  currentPage: 0,
  searchInput: '',
  from: '',
  to: '',
  orderBy: 'name',
  direction: 'ASC',
  encounters: [],
  encounterResult: {}
};

const encounterResults = (state = defaultState, action: any) => {
  switch (action.type) {
    case ENCOUNTERS_REQUEST_GET_RECENT:
      if (action.response) {
        const getRecentEncountersResponse = action.response.data;
        return {
          ...state,
          total: getRecentEncountersResponse.total,
          pages: getRecentEncountersResponse.pages,
          currentPage: getRecentEncountersResponse.currentPage,
          searchInput: getRecentEncountersResponse.searchInput,
          from: getRecentEncountersResponse.from,
          to: getRecentEncountersResponse.to,
          orderBy: getRecentEncountersResponse.orderBy,
          direction: getRecentEncountersResponse.direction,
          organization: getRecentEncountersResponse.organization,
          encounters: getRecentEncountersResponse.result,
        }
      }
      return state;

    case ENCOUNTERS_REQUEST_GET_RESULT:
      if (action.response) {
        return {
          ...state,
          encounterResult: action.response.data
        }
      }
      return state;

    default:
      return state;
  }
};

export default encounterResults;
